import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./offerbanner.scss";



const OfferBenner = () => {

  const homePageBannerSection = useSelector(
		({ homepage }) => homepage.bannerAndTestimonialsData.home_page_sections_list
	);
		
	const [singleBanner1SectionSlug, setSingleBanner1SectionSlug] = useState("home-page-single-banner1-section");

	const signleBanner1Section =
	homePageBannerSection?.find((section) => section.section_slug === singleBanner1SectionSlug);

	useEffect(() => {
		homePageBannerSection?.length &&
		setSingleBanner1SectionSlug(singleBanner1SectionSlug);
	}, [homePageBannerSection]);
  
  return (
    <>
      {signleBanner1Section && signleBanner1Section?.image &&
      <div className="offer-banner-theme2">
        <div className="container">
          <div className="offer-img-wrap">
            <div className="offer-img">
              {signleBanner1Section?.target_url_for_image_clickable?(
                <a href={signleBanner1Section.target_url_for_image_clickable}  >
                  <img src={signleBanner1Section.image} alt="offer banner image" className="img-fluid" />
                </a>
                ):(
                  <img src={signleBanner1Section.image} alt="offer banner image" className="img-fluid" />
                )}
            </div>
          </div>
        </div>
      </div>
    }
    </>
  );
}

export default OfferBenner;

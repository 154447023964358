import Proimage1 from "../../../assets/images/weekly-ad/pro-image-1.png";
import Proimage2 from "../../../assets/images/weekly-ad/pro-image-2.png";
import Proimage3 from "../../../assets/images/weekly-ad/pro-image-3.png";
import Proimage5 from "../../../assets/images/weekly-ad/pro-image-5.png";
import Proimage6 from "../../../assets/images/weekly-ad/pro-image-6.png";
import Proimage7 from "../../../assets/images/weekly-ad/pro-image-7.png";
import Proimage50 from "../../../assets/images/weekly-ad/center-img-1.png";
import Proimage51 from "../../../assets/images/weekly-ad/center-img-2.png";
import Proimage52 from "../../../assets/images/weekly-ad/center-img-3.png";
import Proimage53 from "../../../assets/images/weekly-ad/center-img-4.png";
const weeklyadData = [
    {
        id: 1,
        "proimage": Proimage1,
        title: "Organic Apples",
        price: "2.49$ per lb"
    },
    {
        id: 2,
        "proimage": Proimage2,
        title: "Johnsonville Fresh Brats",
        price: "3.99$ per bunch"
    },
    {
        id: 3,
        "proimage": Proimage3,
        title: "Whole Wheat Bread",
        price: "1.99$ per loaf"
    },
    {
        id: 5,
        "proimage": Proimage5,
        title: "Greek Yogurt",
        price: "4.99$ per 32 oz"
    },
    {
        id: 6,
        "proimage": Proimage6,
        title: "Organic Eggs",
        price: "3.49$ per dozen"
    },
    {
        id: 7,
        "proimage": Proimage7,
        title: "Organic Eggs",
        price: "3.99$ per dozen"
    },
    {
        id: 50,
        "proimage": Proimage50,
        title: "Organic Eggs",
        price: "4.49$ per dozen"
    },
    {
        id: 51,
        "proimage": Proimage51,
        title: "Organic Eggs",
        price: "4.99$ per dozen"
    },
    {
        id: 52,
        "proimage": Proimage52,
        title: "Organic Eggs",
        price: "5.49$ per dozen"
    },
    {
        id: 53,
        "proimage": Proimage53,
        title: "Organic Eggs",
        price: "5.99$ per dozen"
    },
];
export default weeklyadData;

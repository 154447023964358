import React from "react";
import ServiceSectionTheme2 from "./ServiceSectionTheme2";
import { useSelector } from "react-redux";

import NewsLetterTheme2 from "./NewsLetterTheme2";
import FooterLinksTheme2 from "./FooterLinksTheme2";
import CopyRightSectionTheme2 from "./CopyRightSectionTheme2";
const FooterTheme2 = () => {
  const commonDetailsDataLoading = useSelector(({ common }) => common);

  return (
    <>
      {commonDetailsDataLoading.commonDetailsData.header_section.is_show_subscribe_section ==="0" ? (
        ""
      ) : (
        <NewsLetterTheme2 />
      )}

      <ServiceSectionTheme2 />

      <FooterLinksTheme2 />

      <CopyRightSectionTheme2 />
    </>
  );
};

export default FooterTheme2;

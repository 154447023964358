import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateNodeFuctionalText,
  updateNodeFunctionalLevel,
  resetFilterSideBar,
  saveCurrentDepartment
} from "redux/actions";

const OtherDepartmentListitemTheme2 = ({
  isShowOtherDepartmentTree,
  otherDepartmentTree,
  redirectBaseUrl = "departments",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickRedirect = (slug) => {
    dispatch(updateNodeFuctionalText(""));
    dispatch(updateNodeFunctionalLevel(""));
    dispatch(resetFilterSideBar());
    navigate(`/${redirectBaseUrl}/${slug}`);
    dispatch(saveCurrentDepartment(slug))
    const element = document.getElementById("style-5");
    element.scrollTop = 0;

  };

  return isShowOtherDepartmentTree ? (
    <div>
      {otherDepartmentTree &&
        otherDepartmentTree.map((department) => {
          return (
            <div
              className="department-item"
              key={department.category_ids}
              onClick={(e) => handleClickRedirect(department.slug)}
            >
              {department.name}
            </div>
          );
        })}
    </div>
  ) : (
    ""
  );
};

export default OtherDepartmentListitemTheme2;

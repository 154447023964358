import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageValue } from "config/helper";
import CouponCardTheme2 from "components/CouponCardTheme2";
import { getcouponcartlist } from "redux/actions";
import "./special-coupon-list.scss";

const SpecialCouponsList = ({ couponListsData }) => {

    const dispatch = useDispatch();
    const clientId = getLocalStorageValue("RSAclient-id");
    const userToken = getLocalStorageValue("user-token");
    const memberNumber = getLocalStorageValue("member-number");
    const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
    const { currentStoreID } = useSelector(({ store }) => store);
    const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

    const [renderCouponlist, setRenderCouponlist] = useState(false);
    const couponCartlist = () => {
        setRenderCouponlist(!renderCouponlist)
    }

    useEffect(() => {
        const body = {
            RSAClientId: clientId,
            ClientStoreId: storeId,
            AppName: "shop",
            member_number: memberNumber,
            user_token: userToken,
            Version: "1",
            DeviceType: "web",
        };
        isUserLoggedIn &&
            dispatch(getcouponcartlist(body))
    }, [renderCouponlist]); // eslint-disable-line

    return (
        <>
            <h2>Special Coupons </h2>
            <div className="coupon-list-item">
                {couponListsData && couponListsData.slice(0, 5).map((coupon) => {
                    return <CouponCardTheme2 coupon={coupon} handleCouponcartList={couponCartlist} />
                })}
            </div>
        </>
    );
}

export default SpecialCouponsList;
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./descover-section.scss";

function DescoverSection() {
  const commonDetailsData = useSelector(
    ({ common }) => common.commonDetailsData
  );

  const homePageViewDealSection = useSelector(
    ({ homepage }) => homepage.bannerAndTestimonialsData.home_page_sections_list
  );

  const [viewDealSectionSlug, setViewDealSectionSlug] = useState(
    "home-page-view-deal-section"
  );

  const viewDealSection = homePageViewDealSection?.find(
    (section) => section.section_slug === viewDealSectionSlug
  );

  useEffect(() => {
    homePageViewDealSection?.length &&
      setViewDealSectionSlug(viewDealSectionSlug);
  }, [homePageViewDealSection]);

  return (
    <>
      {viewDealSection?.short_description && (
        <>
          {(commonDetailsData?.ecom_section?.allow_ecommerce === "1" ||
            commonDetailsData?.ecom_section?.allow_ecommerce === "0") &&
          viewDealSection?.allow_section === "3" ? (
            <div className="discover-section-theme2">
              <div className="container">
                <div className="discover">
                  <h2>{viewDealSection?.short_description}</h2>
                  {viewDealSection?.button_text && (
                    <a
                      href={
                        commonDetailsData?.ecom_section?.allow_ecommerce === "1"
                          ? viewDealSection?.target_url_for_image_clickable
                          : viewDealSection?.nonecom_target_url_for_image_clickable
                      }>
                      {viewDealSection?.button_text}{" "}
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24">
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            d="M2 12h20m-9-9l9 9l-9 9"
                          />
                        </svg>
                      </span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ) : commonDetailsData?.ecom_section?.allow_ecommerce === "1" &&
            viewDealSection?.allow_section === "1" ? (
            <div className="discover-section-theme2">
              <div className="container">
                <div className="discover">
                  <h2>{viewDealSection?.short_description}</h2>
                  {viewDealSection?.button_text &&
                    viewDealSection?.target_url_for_image_clickable && (
                      <a href={viewDealSection?.target_url_for_image_clickable}>
                        {viewDealSection?.button_text}{" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24">
                            <path
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              d="M2 12h20m-9-9l9 9l-9 9"
                            />
                          </svg>
                        </span>
                      </a>
                    )}
                </div>
              </div>
            </div>
          ) : commonDetailsData?.ecom_section?.allow_ecommerce === "0" &&
            viewDealSection?.allow_section === "2" ? (
            <div className="discover-section-theme2">
              <div className="container">
                <div className="discover">
                  <h2>{viewDealSection?.short_description}</h2>
                  {viewDealSection?.button_text &&
                    viewDealSection?.nonecom_target_url_for_image_clickable && (
                      <a
                        href={
                          viewDealSection?.nonecom_target_url_for_image_clickable
                        }>
                        {viewDealSection?.button_text}{" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24">
                            <path
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              d="M2 12h20m-9-9l9 9l-9 9"
                            />
                          </svg>
                        </span>
                      </a>
                    )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

export default DescoverSection;

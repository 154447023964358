import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  userLogin,
  fetchCustomerForgotPasswordSuccess,
  clearLoginMessage,
} from "redux/actions/user";
import { useForm } from "react-hook-form";
import "./login-page.scss";
import Breadcrumb from "components/Common/Breadcrumb";
import { getLocalStorageValue } from "config/helper";

const LoginPage = () => {
  const recaptchaRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientId = getLocalStorageValue("RSAclient-id");
  const prePageUrl = getLocalStorageValue("pre_page_url") || "";

  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const loginError = useSelector(({ user }) => user.loginError);

  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  // Changed label from PIN to Password -1630 - 07/02/2023 - Madhu
  // RequireField - Asterisk formatting - 21/02/2023 - Navina
  const {
    is_numeric_password,
    your_recaptcha_site_key,
    complex_password_max_length,
    complex_password_min_length,
    numeric_password_length,
  } = headerSectionData;

  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    dispatch(fetchCustomerForgotPasswordSuccess(""));
  }, [dispatch]);

  const handleLoginSuccess = () => {
    prePageUrl === "/signup" || "/forgot-password"
      ? navigate("/")
      : navigate(-1);
  };
  const submitUserLogin = (data) => {
    const { email, password } = data;
    const body = {
      RSAClientId: clientId,
      email_id: email,
      password: password,
    };
    // ticket no:1171  ,captcha required message is added . committed on 2/22/2021 - Navina
    if (recaptchaValue) {
      setCaptchaError("");
      dispatch(userLogin(body, handleLoginSuccess));
    } else {
      setCaptchaError("The captcha field is required");
    }
  };

  const onChangeReCaptcha = (value) => {
    setRecaptchaValue(value);
    setCaptchaError("");
  };

  const breadcrumbList = [{ lable: "Sign In", link: "" }];

  useEffect(() => {
    return () => {
      dispatch(clearLoginMessage());
    };
  }, [dispatch]);

  return (
    <>
      {/* <Breadcrumb breadcrumbList={breadcrumbList} /> */}
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={`Sign In`} />

      <div className="login-page-wrapper">
        <div className="container">
          <div className="auth-grid-alignment">
            <div className="auth-grid">
              <div className="auth-grid-items">
                <div className="logo-center-wrapper">
                  <img
                    src={headerSectionData.rsa_client_logo}
                    alt={headerSectionData.image_alt_text}
                  />
                </div>
                <h1>
                  Great to <br />
                  <span>see you back</span>
                </h1>
              </div>
              <div className="auth-grid-items">
                <h2>Sign In</h2>
                <p>
                  Don't have an account?{" "}
                  <span>
                    <Link to="/signup">Create here</Link>
                  </span>
                </p>
                <form name="login" onSubmit={handleSubmit(submitUserLogin)}>
                  <div className="form-control">
                    <label>Email Address *</label>
                    <input
                      name="email"
                      id="email"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <span>Please enter email.</span>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <span>
                        The email address entered is invalid, please check the
                        formatting (e.g. email@domain.com)
                      </span>
                    )}
                  </div>
                  {/*  Changed label from PIN to Password -1630 - 07/02/2023 - Madhu */}
                  {is_numeric_password === "1" ? (
                    <div className="form-control">
                      <label>Pin *</label>
                      <input
                        name="password"
                        id="password"
                        type="password"
                        maxLength={
                          is_numeric_password === "1"
                            ? numeric_password_length
                            : complex_password_max_length
                        }
                        minLength={
                          is_numeric_password === "1"
                            ? numeric_password_length
                            : complex_password_min_length
                        }
                        ref={register({
                          required: true,
                          minLength: 4,
                          // maxLength: +numeric_password_length,
                          pattern: /^[0-9]+$/, // eslint-disable-line
                        })}
                      />

                      {errors.password &&
                        errors.password.type === "required" && (
                          <span>Please enter Pin.</span>
                        )}
                      {/* {errors.password &&
                        errors.password.type === "minLength" && (
                          <span>
                            Pin should be atleast 4 digits long.
                          </span>
                        )}
                      {errors.password &&
                        errors.password.type === "maxLength" && (
                          <span>
                            Pin too long.
                          </span>
                        )} */}
                      {errors.password &&
                        errors.password.type === "pattern" && (
                          <span>Pin must contain only number.</span>
                        )}

                      <Link to="/forgot-password">Forgot Pin ?</Link>
                    </div>
                  ) : (
                    <div className="form-control">
                      <label> Password *</label>
                      <input
                        name="password"
                        id="password"
                        type="password"
                        maxLength={
                          is_numeric_password === "1"
                            ? numeric_password_length
                            : complex_password_max_length
                        }
                        minLength={
                          is_numeric_password === "1"
                            ? numeric_password_length
                            : 4
                        }
                        ref={register({
                          required: true,
                          minLength: 4,
                          // maxLength: +complex_password_max_length,
                        })}
                      />
                      {errors.password &&
                        errors.password.type === "required" && (
                          <span>Please enter Password.</span>
                        )}
                      {/* {errors.password && errors.password.type === "minLength" && (
                        <span>
                          enter atleast 4 characters.
                        </span>
                      )}
                      {errors.password && errors.password.type === "maxLength" && (
                        <span>
                          Password must only {complex_password_min_length} -{" "}
                          {complex_password_max_length} characters.
                        </span>
                      )} */}

                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                  )}
                  {loginError ? (
                    <span className="login-error">{loginError}</span>
                  ) : (
                    ""
                  )}
                  <div className="re-captcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={your_recaptcha_site_key}
                      onChange={onChangeReCaptcha}
                    />
                    <span style={{ color: "red" }}>{captchaError}</span>
                  </div>
                  <div className="sign-in-button">
                    <button type="submit">Sign In</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;

import Proimage4 from "../../../assets/images/weekly-ad/pro-image-4.png";
import Proimage10 from "../../../assets/images/weekly-ad/verticale-image-1.png";
import Proimage11 from "../../../assets/images/weekly-ad/verticale-image-2.png";
import Proimage12 from "../../../assets/images/weekly-ad/verticale-image-3.png";
import Proimage13 from "../../../assets/images/weekly-ad/verticale-image-4.png";
const sideProadData = [
    {
        id: 4,
        "proimage": Proimage4,
        title: "Kempls All American or Lemon Bar Ice Cream Sandwiches",
        price: "4.99$ per 32 oz"
    },
    {
        "id": 10,
        "proimage": Proimage10,
        "title": "Greek Yogurt",
        "price": "4.99$ per 32 oz"
    },
    {
        "id": 11,
        "proimage": Proimage11,
        "title": "Granola Bar",
        "price": "1.99$ per bar"
    },
    {
        "id": 12,
        "proimage": Proimage12,
        "title": "Fresh Strawberries",
        "price": "3.49$ per pound"
    },
    {
        "id": 13,
        "proimage": Proimage13,
        "title": "Whole Wheat Bread",
        "price": "2.99$ per loaf"
    }
];
export default sideProadData;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { updateCustomerPassword, clearChangePasswordMessage } from "redux/actions";
import { getLocalStorageValue, setLocalStorageValue } from "config/helper";

import "./change-pin.scss";

const ChangePinTheme2 = () => {
  const dispatch = useDispatch();
  
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const { register, handleSubmit, watch, errors } = useForm();

  const { updatePasswordStatus, updatePasswordMessage, updatePasswordLoading } =
    useSelector(({ customer }) => customer);

  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const {
    is_numeric_password,
    numeric_password_length,
    complex_password_max_length,
    complex_password_min_length,
  } = headerSectionData;

  // Changed label from PIN to Password -1630 - 07/02/2023 - Madhu
  const passwordOrPin = is_numeric_password === "1" ? "Pin" : "Password";

  const handleSignOut = () => {
    const store = getLocalStorageValue("selected-store-id");
    localStorage.clear();
    setLocalStorageValue('selected-store-id', store);
    window.location.href="/login" ;
  };

  const handleUpdatePin = (data, e) => {
    const { old_password, new_password, conf_password } = data;
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      old_password: old_password,
      new_password: new_password,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };

    new_password === conf_password &&
      dispatch(updateCustomerPassword(body)).then(() => {
        e.target.reset();
      });
  };

  if(updatePasswordStatus === 'success') {
    handleSignOut();
  }

  useEffect(() => {
    return () => {
      dispatch(clearChangePasswordMessage());
    };
  }, [dispatch]);

  return (
    <div className="change-pin-section-theme2">
      <h1>Change {passwordOrPin}</h1>
      <div className="change-pin-box">
        <form name="feedback" onSubmit={handleSubmit(handleUpdatePin)}>
          <div className="form-control">
            <label>Old {passwordOrPin}*</label>
            <input
              type="password"
              name="old_password"
              minLength={
                is_numeric_password === "1" ? numeric_password_length : 4
              }
              maxLength={
                is_numeric_password === "1"
                  ? numeric_password_length
                  : complex_password_max_length
              }
              ref={register({
                required: true,
                // pattern: /^([0-9]?)([a-z]?)([A-Z]?){4,12}$/,
              })}
            />
            {errors.old_password && errors.old_password.type === "required" && (
              <span>Please enter Old {passwordOrPin}.</span>
            )}
            {/* {errors.old_password && errors.old_password.type === "pattern" && (
              <span>Please enter valid Old Password.</span>
            )} */}
          </div>

          {is_numeric_password === "1" ? (
            <div className="form-control">
              <label>New Pin*</label>
              <input
                name="new_password"
                id="new_password"
                type="password"
                minLength={numeric_password_length}
                maxLength={numeric_password_length}
                ref={register({
                  required: true,
                  minLength: +numeric_password_length,
                  maxLength: +numeric_password_length,
                  pattern: /^[0-9]+$/, // eslint-disable-line
                })}
              />

              {errors.new_password &&
                errors.new_password.type === "required" && (
                  <span>Please enter Pin.</span>
                )}
              {errors.new_password &&
                errors.new_password.type === "minLength" && (
                  <span>Pin must only {numeric_password_length} digits.</span>
                )}
              {errors.new_password &&
                errors.new_password.type === "maxLength" && (
                  <span>Pin must only {numeric_password_length} digits.</span>
                )}
              {errors.new_password &&
                errors.new_password.type === "pattern" && (
                  <span>Pin must contain only number.</span>
                )}
            </div>
          ) : (
            <div className="form-control">
              <label>New Password*</label>
              <input
                name="new_password"
                id="new_password"
                type="password"
                minLength={complex_password_min_length}
                maxLength={complex_password_max_length}
                ref={register({
                  required: true,
                  minLength: +complex_password_min_length,
                  maxLength: +complex_password_max_length,
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/, // eslint-disable-line
                })}
              />
              {errors.new_password &&
                errors.new_password.type === "required" && (
                  <span>Please enter Password.</span>
                )}
              {errors.new_password && errors.new_password.type === "minLength" && (
                <span>
                  Password must only {complex_password_min_length} -{" "}
                  {complex_password_max_length} digits.
                </span>
              )}
              {errors.new_password && errors.new_password.type === "maxLength" && (
                <span>
                  Password must only {complex_password_min_length} -{" "}
                  {complex_password_max_length} digits.
                </span>
              )}
              {errors.new_password &&
                errors.new_password.type === "pattern" && (
                  <span>
                    Password must contain at least one uppercase letter, one
                    number and one special character.
                  </span>
                )}
            </div>
          )}

          <div className="form-control">
            <label>Confirm {passwordOrPin}*</label>
            <input
              type="password"
              name="conf_password"
              minLength={
                is_numeric_password === "1"
                  ? numeric_password_length
                  : complex_password_min_length
              }
              maxLength={
                is_numeric_password === "1"
                  ? numeric_password_length
                  : complex_password_max_length
              }
              ref={register({
                required: true,
              })}
            />

            {errors.conf_password &&
              errors.conf_password.type === "required" && (
                <span>Please re-enter {passwordOrPin}.</span>
              )}

            {errors &&
              watch("new_password") &&
              watch("conf_password") &&
              watch("new_password") !== watch("conf_password") && (
                <span className="error">
                  New and confirm {passwordOrPin} does not match
                </span>
              )}
          </div>
          {updatePasswordStatus ? (
            <span
              className={
                updatePasswordStatus === "success"
                  ? "feedback-success"
                  : "feedback-error"
              }
            >
              {updatePasswordMessage}
            </span>
          ) : (
            ""
          )}

          <div className="button-right-side">
            {/* <div>
              <button>Cancel</button>
            </div> */}
            <div>
              <button type="submit">
                {updatePasswordLoading ? "Saving..." : "Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ChangePinTheme2;

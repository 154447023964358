// actions created for b2b form [24-06-2024] by kiran konda
import {
  CONTACT_FORM,
  CONTACT_FORM_SUCCESS,
  CONTACT_FORM_FAILURE
} from "redux/constants/actionTypes";

import axios from "config/axios";

export const conatctDetails = (payload) => {
  return {
    type: CONTACT_FORM,
    payload: payload
  };
};
export const conatctDetailsSuccess = (payload) => {
  return {
    type: CONTACT_FORM_SUCCESS,
    payload: payload
  };
};
export const conatctDetailsFailure = (payload) => {
  return {
    type: CONTACT_FORM_FAILURE,
    payload: payload
  };
};

export const conatctFormDetails = (body) => async (dispatch) => {
  dispatch(conatctDetails());
  axios
    .post("/AogContactForm", body)
    .then((res) => {
      dispatch(conatctDetailsSuccess(res?.data));
    })
    .catch((error) => {
      dispatch(conatctDetailsFailure({ error: error?.data?.message }));
    });
};

import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CampaignProduct from "./CampaignProduct/CampaignProduct";
import "./campaign-section.scss";
import ProductCardTheme2 from "components/ProductCardTheme2";

const CampaignSection = () => {
  const offersWithCampaignProductData = useSelector(
    ({ homepage }) => homepage.offersWithCampaignProductData
  );

  return (
    <>
      {offersWithCampaignProductData?.map((campaign) => {
        return (
          <>
            {campaign.campaign_products?.length > 0 && (
              <div className="campaign-of-week-theme2">
                <div className="container">
                  <div className="container-fluid">
                  <div className="compaign-product main-product">
                    <div className="heading">
                      <h2>{campaign?.campaign_name}</h2>
                      <Link
                        to={{
                          pathname: campaign?.cammpagin_slug,
                        }}
                        state={{ coupondata: "coupon" }}
                      >
                        View All{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="currentColor"
                            d="M8.6 3.4L14.2 9H2v2h12.2l-5.6 5.6L10 18l8-8l-8-8z"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                    <div className="product-row">
                      <CampaignProduct campaign={campaign} />
                      {campaign.campaign_products &&
                        campaign.campaign_products?.length > 0 && (
                          <div className="product-col-sm-8">
                            <div className="product-row deal-all-wrapper">
                              {campaign.campaign_products
                                .slice(0, 8)
                                .map((product) => {
                                  return (
                                    <>
                                      <ProductCardTheme2
                                        product={product}
                                        key={product.id}
                                      />
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
    </>
  );
};

export default CampaignSection;

// actions created for b2b form [24-06-2024] by kiran konda
import {
  CONSTANTCONTACT_FORM,
  CONSTANTCONTACT_FORM_SUCCESS,
  CONSTANTCONTACT_FORM_FAILURE
} from "redux/constants/actionTypes";

import axios from "config/axios";

export const constantContactFormDetails = (payload) => {
  return {
    type: CONSTANTCONTACT_FORM,
    payload: payload
  };
};
export const constantContactFormDetailsSuccess = (payload) => {
  return {
    type: CONSTANTCONTACT_FORM_SUCCESS,
    payload: payload
  };
};
export const constantContactFormDetailsFailure = (payload) => {
  return {
    type: CONSTANTCONTACT_FORM_FAILURE,
    payload: payload
  };
};

export const constantcontactDetail = (body) => async (dispatch) => {
  dispatch(constantContactFormDetails());
  axios
    .post("/AogConstantContactForm", body)
    .then((res) => {
      dispatch(constantContactFormDetailsSuccess(res?.data));
    })
    .catch((error) => {
      dispatch(
        constantContactFormDetailsFailure({ error: error?.data?.message })
      );
    });
};

import Proimage81 from "../../../assets/images/weekly-ad/bottom-pro-1.png";
import Proimage82 from "../../../assets/images/weekly-ad/bottom-pro-2.png";
import Proimage83 from "../../../assets/images/weekly-ad/bottom-pro-3.png";
const SaleProadData = [
    {
        "id": 81,
        "proimage": Proimage81,
        "title": "Fancy Widget",
        "price": "$19.99"
    },
    {
        "id": 82,
        "proimage": Proimage82,
        "title": "Super Gizmo",
        "price": "$29.85"
    },
    {
        "id": 83,
        "proimage": Proimage83,
        "title": "Mega Doodad",
        "price": "$39.99"
    }
];
export default SaleProadData;

import React from "react";
import Slider from "react-slick/lib/slider";
import { useSelector } from "react-redux";
import "./department-slider.scss";
import { Link } from "react-router-dom";
import { NextArrow, PrevArrow } from "components/Common/Arrows";

const DepartmentSlider = () => {
  const shopByDepartmentData = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section
  );

  const couponDepartmentmenu = useSelector(
    ({ couponsDepartment }) => couponsDepartment.coupondepartmentData
  );

  const settings = {
    dots: false,
    infinite:
      shopByDepartmentData?.allow_ecommerce === "1"
        ? (shopByDepartmentData.sub_department &&
          shopByDepartmentData.sub_department.length) >= 6
        : couponDepartmentmenu.length >= 6,
    speed: 500,
    slidesToShow: 6,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      {shopByDepartmentData && shopByDepartmentData?.allow_ecommerce === "1" ? (
        <section className="department-slider">
          <div className="container">
            <div className="page-title">
              <h1>
                {shopByDepartmentData.name ? shopByDepartmentData.name : ""}
              </h1>
            </div>
            <Slider {...settings}>
              {shopByDepartmentData.sub_department &&
                shopByDepartmentData.sub_department.map((el) => {
                  return (
                    <Link to={el.is_mealkit > 0 ? `/catering-meal/${el.slug}` : `/departments/${el.slug}`} 
                    key={el.category_ids}>
                      <div>
                        <div className="card-alignment">
                          <div className="card">
                            <div className="card-image">
                              <img src={el.medium_image} alt={el.slug} />
                            </div>
                            <div className="card-details">
                              <p>{el.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </Slider>
          </div>
        </section>
      ) : (
        couponDepartmentmenu &&
        !!couponDepartmentmenu.length && (
          <section className="department-slider">
            <div className="container">
              <div className="page-title">
                <h1>Departments</h1>
              </div>
              <Slider {...settings}>
                {couponDepartmentmenu.map((el) => {
                  return (
                    <Link
                      to={`/coupon-department/${el.department_name_slug}`}
                      key={el.department_id}
                    >
                      <div>
                        <div className="card-alignment">
                          <div className="card">
                            <div className="card-image">
                              <img
                                src={el.department_image_url}
                                alt={el.department_name_slug}
                              />
                            </div>
                            <div className="card-details">
                              <p>{el.department_name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </Slider>
            </div>
          </section>
        )
      )}
    </>
  );
};
export default DepartmentSlider;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import CouponListingPageSidebar from "components/CouponListingPage/Sidebar";
import CouponList from "components/CouponList";
import CouponListingPageAction from "components/CouponListingPage/Actions";
import SpecialCouponsList from "./SpecialCouponsList";
import {
  getHotOffersData,
  getCouponListDetails,
  getCouponCategoriesDetails,
  getBannerAdsData,
} from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import "./coupon.scss";
import CouponImageCard from "components/CouponImageCard/CouponImageCard";
import CouponImage1 from "../../assets/images/coupon-banner-1.jpg";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";

const Coupon = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const { theme_type } = useSelector(({ common }) => common.commonDetailsData.header_section || {});
  const breadcrumbList = [{ lable: "Coupons", link: "", searchTag: "" }];
  const [categoryId, setCategoryId] = useState("0");
  const { bannerAdsData, bannerAdsDataLoading } = useSelector(({ bannerAds }) => bannerAds);
  const hotOffersData = useSelector(({ hotOffers }) => hotOffers.hotOffersData);
  const couponListsData = useSelector(({ coupon }) => coupon.couponData);
  const couponDataLoading = useSelector(({ coupon }) => coupon.couponDataLoading);
  const couponCategoriesData = useSelector(({ coupon }) => coupon.couponCategoriesData);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [filteredBanners, setFilteredBanners] = useState([]);

  const fetchData = useCallback(() => {
    const commonBody = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };

    dispatch(getHotOffersData(commonBody));
    dispatch(getCouponListDetails({ ...commonBody, product_category_id: categoryId }));
    dispatch(getCouponCategoriesDetails(commonBody));
    dispatch(getBannerAdsData({ ...commonBody, category_ids: "", search: "", funtional_text: "", placement: "run-of-site-banner-x", ros:"Coupons" }));
  }, [clientId, storeId, isUserLoggedIn, memberNumber, userToken, categoryId, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const checkViewportWidth = useCallback(() => {
    setIsMobile(window.innerWidth < 769);
  }, []);

  useEffect(() => {
    checkViewportWidth();
    window.addEventListener('resize', checkViewportWidth);
    return () => {
      window.removeEventListener('resize', checkViewportWidth);
    };
  }, [checkViewportWidth]);

  useEffect(() => {
    setFilteredBanners(bannerAdsData?.ads?.filter(ad => ad.slotId === "Horizontal Banners")[0]?.banners ?? []);
  }, [isMobile, bannerAdsData]);

  const handleCouponlist = useCallback((category_id) => {
    setCategoryId(category_id);
  }, []);

  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={`Coupons`} />
      <CouponImageCard />
      {!couponDataLoading && !bannerAdsDataLoading && filteredBanners[0] && (
        <AdsSpaceSectionMainTheme2 adsDetails={filteredBanners[0]} isMobile={isMobile} />
      )}
      <div className="container">
        {theme_type === "2" && !couponDataLoading && (
          <div className="listing-page-wrapper-special-coupon-items-theme2">
            <SpecialCouponsList couponListsData={couponListsData} />
          </div>
        )}
        <div className="coupon-listing-page-wrapper">
          <div className="listing-page-wrapper-items">
            <CouponListingPageSidebar onChange={handleCouponlist} bannerAd={bannerAdsData?.ads?.filter(ad => ad.slotId === "Vertical Banners")[0]?.banners ?? []} />
          </div>
          <div className="listing-page-wrapper-items">
            <CouponListingPageAction
              onChange={handleCouponlist}
              totalCuponCount={couponListsData?.length}
            />
            {!couponDataLoading && (
              <CouponList couponListsData={couponListsData} />
            )}
          </div>
        </div>
      </div>
      <CouponImageCard imageSource={CouponImage1} />
    </>
  );
};

export default Coupon;

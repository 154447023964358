import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DealsOfferProduct from "./DealsOfferProduct/DealsOfferProduct";
import "./deal-of-week.scss";
import ProductCardTheme2 from "components/ProductCardTheme2";
import { Link } from "react-router-dom";

const DealOfWeek = () => {
  const offerDealOfTheWeekProductData = useSelector(
    ({ homepage }) => homepage.offersWithProductData
  );

  const [dealOfTheWeekSectionSlug, setDealOfTheWeekSectionSlug] =
    useState("deal-of-the-week");

  const dealOfTheWeekOfferProductList =
    offerDealOfTheWeekProductData?.find(
      (offer) => offer.section_slug === dealOfTheWeekSectionSlug
    )?.products_list || [];

  const dealOfTheWeekOfferProductListTitle =
    offerDealOfTheWeekProductData?.find(
      (offer) => offer.section_slug === dealOfTheWeekSectionSlug
    )?.title || "";

  useEffect(() => {
    offerDealOfTheWeekProductData?.length &&
      setDealOfTheWeekSectionSlug(dealOfTheWeekSectionSlug);
  }, [offerDealOfTheWeekProductData]);

  return (
    <>
      {dealOfTheWeekOfferProductList &&
        dealOfTheWeekOfferProductList.length > 0 && (
          <div className="deal-of-week-theme2">
            <div className="container">
              <div className="container-fluid">
                <div className="deals-product">
                  <div className="heading">
                    <h2>{dealOfTheWeekOfferProductListTitle}</h2>
                    <Link
                      to={{
                        pathname: "/search/products/",
                      }}
                      state={{ coupondata: "coupon" }}
                    >
                      View All{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill="currentColor"
                          d="M8.6 3.4L14.2 9H2v2h12.2l-5.6 5.6L10 18l8-8l-8-8z"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="product-row">
                  <DealsOfferProduct
                    product={dealOfTheWeekOfferProductList[0]}
                  />
                  {dealOfTheWeekOfferProductList &&
                    dealOfTheWeekOfferProductList.length > 1 && (
                      <div className="product-col-sm-8">
                        <div className="product-row deal-all-wrapper">
                          {dealOfTheWeekOfferProductList
                            .slice(1, 7)
                            .map((product) => {
                              return (
                                <>
                                  <ProductCardTheme2
                                    product={product}
                                    key={product.id}
                                  />
                                </>
                              );
                            })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default DealOfWeek;

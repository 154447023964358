import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageValue } from "config/helper";
import CouponCardTheme2 from "components/CouponCardTheme2";
import DealsOfWeekFirstCoupon from "./DealOfWeekFirstCoupon";
import { getcouponcartlist } from "redux/actions";
import "./deals-of-week-coupon.scss";

const DealsOfWeekCoupon = ({
  dealOfTheWeekCouponList,
  dealOfTheWeekCouponTitle,
}) => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const [renderCouponlist, setRenderCouponlist] = useState(false);
  const couponCartlist = () => {
    setRenderCouponlist(!renderCouponlist);
  };

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: "shop",
      member_number: memberNumber,
      user_token: userToken,
      Version: "1",
      DeviceType: "web",
    };
    isUserLoggedIn && dispatch(getcouponcartlist(body));
  }, [renderCouponlist]); // eslint-disable-line

  return (
    <>
      <div className="deal-of-week-coupon">
        <div className="container">
          <div className="container-fluid">
            <div className="deals-coupon">
              <div className="heading">
                <h2>{dealOfTheWeekCouponTitle}</h2>
                <a>
                  View All{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="M8.6 3.4L14.2 9H2v2h12.2l-5.6 5.6L10 18l8-8l-8-8z"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="product-row">
              <div className="deal-coupon coupon-product-col-sm-4">
                <DealsOfWeekFirstCoupon
                  coupon={dealOfTheWeekCouponList[0]}
                  handleCouponcartList={couponCartlist}
                />
              </div>
              <div className="deal-coupon coupon-product-col-sm-8">
                <div className="coupon-product-row coupon-deal-all-wrapper">
                  {dealOfTheWeekCouponList &&
                    dealOfTheWeekCouponList.slice(1, 7).map((coupon, index) => {
                      return (
                        <CouponCardTheme2
                          coupon={coupon}
                          handleCouponcartList={couponCartlist}
                          key={index}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealsOfWeekCoupon;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contactServicesList, getStoreDetailsHistory } from "redux/actions";
import { Link } from "react-router-dom";
import CityDropdown from "../CityDropdown";
import { groupBy } from "lodash";
import "./location.scss";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { getLocalStorageValue } from 'config/helper';

const Location = ({ storeList }) => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  // const defaultCity = storeList.slice(0, 1).map((item) => {
  //   return item.City;
  // });
  const selectedStore =
    storeList &&
    storeList.find(
      (store) =>
        store.ClientStoreId === getLocalStorageValue("selected-store-id")
    );
  const [selectedCity, setSelectedCity] = useState(
    selectedStore.ClientStoreName
  );
  const storeDataCityWise = groupBy(storeList, "StateName");
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const [storeId, setstoreId] = useState(
    currentStoreID || getLocalStorageValue("selected-store-id")
  );
  const {
    ecom_section: { allow_ecommerce = "" },
  } = useSelector(({ common }) => common.commonDetailsData);
  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(contactServicesList(body));
  }, [dispatch, storeId]); // eslint-disable-line

  const { contactserviceData: servicesList, weeklyAdsButtonUrl } = useSelector(
    ({ feedback }) => feedback
  );
  const handleSelectCity = (store_id, optionName) => {
    setSelectedCity(optionName);
    setstoreId(store_id);
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +store_id,
    };
    dispatch(getStoreDetailsHistory(body));
  };
  const storeSingleData = storeList.find(
    (storeListData) => storeListData.ClientStoreName === selectedCity
  );
  const formatPhoneNumber = (str) => {
    let cleaned = ("" + str).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };
  const mapStyles = {
    height: "600px",
    width: "100%",
  };
  const info = {
    name: storeSingleData.ClientStoreName,
    state: storeSingleData.StateName,
    location: {
      lat: parseFloat(storeSingleData.Latitude),
      lng: parseFloat(storeSingleData.Longitude),
    },
  };
  const [selected, setSelected] = useState({});
  const onSelect = (item) => {
    setSelected(item);
  };
  return (
    <>
      <div className="container">
        <div className="tabs">
          <CityDropdown
            lable=""
            placeholder="Select Store"
            value={selectedCity}
            defaultValue=" "
            onChange={handleSelectCity}
            optionData={storeDataCityWise}
          />
        </div>
      </div>
      {storeSingleData ? (
        <div className="container">
          <div className="location-section">
            <div className="grid">
              <div className="grid-items">
                <h2>{storeSingleData.City}</h2>
                <p>{storeSingleData.AddressLine1}</p>
                <p>
                  {storeSingleData.AddressLine2}, {storeSingleData.ZipCode}
                </p>
                <a
                  href={`tel:${storeSingleData.StorePhoneNumber}`}
                  rel="noreferrer"
                >
                  Phone: {formatPhoneNumber(storeSingleData.StorePhoneNumber)}
                </a>
                <a
                  href={`mailto:${storeSingleData.StoreEmail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Email: {storeSingleData.StoreEmail}
                </a>
                <div className="time-text">
                  <h3>HOURS: {storeSingleData.StoreTimings}</h3>
                </div>
                <div className="store-service-section">
                  <div className="store-service-header">
                    {servicesList.length > 0
                      ? servicesList.map((store) => {
                        return (
                          <>
                            {store.data.length > 0 ? (
                              <>
                                <p>{store.service_section_title}</p>
                                <div style={{ columns: "2 auto" }}>
                                  {store.data.map((sub) => {
                                    return sub.is_link === "1" ? (
                                      <div className="store-service-link">
                                        <a
                                          href={`${sub.slug}`}
                                          target="_self"
                                          rel="noreferrer"
                                        >
                                          {sub.name}
                                        </a>
                                      </div>
                                    ) : (
                                      <div className="store-service-link">
                                        {sub.slug ? (
                                          <Link
                                            to={`/${sub.slug}`}
                                            target="_self"
                                            rel="noreferrer"
                                          >
                                            {sub.name}
                                          </Link>
                                        ) : (
                                          <span>{sub.name}</span>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            ) : ("")
                            }
                          </>
                        );
                      })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="grid-items">
                <div className="map-image">
                  <LoadScript
                    googleMapsApiKey={
                      process.env.REACT_APP_REACT_GOOGLE_MAP_KEY
                    }
                  >
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={13}
                      center={info.location}
                    >
                      <Marker
                        //key={props.store.name}
                        position={info.location}
                        onClick={() => onSelect(info)}
                      />
                      {selected.name && selected.state && (
                        <InfoWindow
                          position={selected.location}
                          clickable={true}
                          onCloseClick={() => setSelected({})}
                        >
                          <p>{selected.name}</p>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  </LoadScript>
                </div>
                <div className="store-loc-details">
                  {weeklyAdsButtonUrl && (
                    <Link to={`/${weeklyAdsButtonUrl}`}>
                      <button className="store-loc-details__button">
                        View Weekly Ads
                      </button>
                    </Link>
                  )}
                  {allow_ecommerce === "1" ? (
                    <Link
                      to={`/search/products/*`}
                      style={{ display: "inline" }}
                    >
                      <button className="store-loc-details__button">
                        Shop Online
                      </button>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Location;

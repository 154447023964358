import {
  FETCH_REWARD_DATA,
  FETCH_REWARD_DATA_SUCCESS,
  FETCH_REWARD_DATA_FAILURE,
  FETCH_REDEEM_LIST,
  FETCH_REDEEM_LIST_SUCCESS,
  FETCH_REDEEM_LIST_FAILURE,
  SET_REDEEM_OPTION,
  SET_REDEEM_OPTION_SUCCESS,
  SET_REDEEM_OPTION_FAILURE,
  FETCH_REWARD_V2_DATA,
  FETCH_REWARD_V2_DATA_SUCCESS,
  FETCH_REWARD_V2_DATA_FAILURE,
  FETCH_UPC_TIER_PRODUCTS_DATA,
  FETCH_UPC_TIER_PRODUCTS_DATA_SUCCESS,
  FETCH_UPC_TIER_PRODUCTS_DATA_FAILURE,
  FETCH_QUALIFIED_REWARD_TIERS_V2_DATA,
  FETCH_QUALIFIED_REWARD_TIERS_V2_DATA_SUCCESS,
  FETCH_QUALIFIED_REWARD_TIERS_V2_DATA_FAILURE,
  FETCH_POINTS_BASED_REWARD_DATA,
  FETCH_POINTS_BASED_REWARD_DATA_SUCCESS,
  FETCH_POINTS_BASED_REWARD_DATA_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  rewardData: [],
  rewardDataLoading: true,
  rewardDataError: "",
  redeemList: [],
  redeemListLoading: true,
  redeemListError: "",
  setRedeemSuccessMessage: "",
  setRedeemLoading: false,
  setRedeemError: "",

  rewardV2Data: [],
  rewardV2DataLoading: true,
  rewardV2DataError: "",

  UpcTierProductsData: [],
  UpcTierProductsDataLoading: true,
  UpcTierProductsDataError: "",

  QualifiedRewardTiersV2Data: [],
  QualifiedRewardTiersV2DataLoading: true,
  QualifiedRewardTiersV2DataError: "",

  PointsBasedRewardData: [],
  PointsBasedRewardDataLoading: true,
  PointsBasedRewardDataError: "",
};

const rewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REWARD_DATA:
      return {
        ...state,
        rewardDataLoading: true,
      };
    case FETCH_REWARD_DATA_SUCCESS:
      return {
        ...state,
        rewardDataLoading: false,
        rewardData: action.payload,
        setRedeemSuccessMessage: "",
      };
    case FETCH_REWARD_DATA_FAILURE:
      return {
        ...state,
        rewardDataError: action.payload.error,
      };

    case FETCH_REDEEM_LIST:
      return {
        ...state,
        redeemListLoading: true,
      };
    case FETCH_REDEEM_LIST_SUCCESS:
      return {
        ...state,
        redeemListLoading: false,
        redeemList: action.payload,
      };
    case FETCH_REDEEM_LIST_FAILURE:
      return {
        ...state,
        redeemListError: action.payload.error,
      };

    case SET_REDEEM_OPTION:
      return {
        ...state,
        setRedeemLoading: true,
      };
    case SET_REDEEM_OPTION_SUCCESS:
      return {
        ...state,
        setRedeemLoading: false,
        setRedeemSuccessMessage: action.payload,
      };
    case SET_REDEEM_OPTION_FAILURE:
      return {
        ...state,
        setRedeemError: action.payload.error,
      };

    case FETCH_REWARD_V2_DATA:
      return { ...state, rewardV2DataLoading: true };
    case FETCH_REWARD_V2_DATA_SUCCESS:
      return {
        ...state,
        rewardV2DataLoading: false,
        rewardV2Data: action.payload,
      };
    case FETCH_REWARD_V2_DATA_FAILURE:
      return {
        ...state,
        rewardV2DataLoading: false,
        rewardV2DataError: action.payload.error,
      };
    case FETCH_UPC_TIER_PRODUCTS_DATA:
      return { ...state, UpcTierProductsDataLoading: true };
    case FETCH_UPC_TIER_PRODUCTS_DATA_SUCCESS:
      return {
        ...state,
        UpcTierProductsDataLoading: false,
        UpcTierProductsData: action.payload,
      };
    case FETCH_UPC_TIER_PRODUCTS_DATA_FAILURE:
      return {
        ...state,
        UpcTierProductsDataLoading: false,
        UpcTierProductsDataError: action.payload.error,
      };
    case FETCH_QUALIFIED_REWARD_TIERS_V2_DATA:
      return { ...state, QualifiedRewardTiersV2DataLoading: true };
    case FETCH_QUALIFIED_REWARD_TIERS_V2_DATA_SUCCESS:
      return {
        ...state,
        QualifiedRewardTiersV2DataLoading: false,
        QualifiedRewardTiersV2Data: action.payload,
      };
    case FETCH_QUALIFIED_REWARD_TIERS_V2_DATA_FAILURE:
      return {
        ...state,
        QualifiedRewardTiersV2DataLoading: false,
        QualifiedRewardTiersV2DataError: action.payload.error,
      };
    case FETCH_POINTS_BASED_REWARD_DATA:
      return { ...state, PointsBasedRewardDataLoading: true };
    case FETCH_POINTS_BASED_REWARD_DATA_SUCCESS:
      return {
        ...state,
        PointsBasedRewardDataLoading: false,
        PointsBasedRewardData: action.payload,
      };
    case FETCH_POINTS_BASED_REWARD_DATA_FAILURE:
      return {
        ...state,
        PointsBasedRewardDataLoading: false,
        PointsBasedRewardDataError: action.payload.error,
      };
    default:
      return state;
  }
};

export default rewardReducer;

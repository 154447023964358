import React from "react";
import Slider from "react-slick/lib/slider";
import { useSelector } from "react-redux";
import "./department-slider-theme2.scss";
import { Link } from "react-router-dom";
import { NextArrow, PrevArrow } from "components/Common/Arrows";

const DepartmentSliderTheme2 = () => {
  const shopByDepartmentData = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section
  );

  const couponDepartmentmenu = useSelector(
    ({ couponsDepartment }) => couponsDepartment.coupondepartmentData
  );

  return (
    <>
      {shopByDepartmentData && shopByDepartmentData?.allow_ecommerce === "1" ? (
        <section className="department-slider-theme2">
          <div className="container">
            {shopByDepartmentData?.sub_department?.length > 0 && (
              <div className="page-title-link">
                <div className="page-title">
                  <h2>
                    {shopByDepartmentData.name ? shopByDepartmentData.name : ""}
                  </h2>
                </div>
                <Link to="/search/products/">
                  View All{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="M8.6 3.4L14.2 9H2v2h12.2l-5.6 5.6L10 18l8-8l-8-8z"
                    />
                  </svg>
                </Link>
              </div>
            )}

            <div className="department-slider-theme2">
              {shopByDepartmentData.sub_department &&
                shopByDepartmentData.sub_department.map((el) => {
                  return (
                    <Link
                      to={
                        el.is_mealkit > 0
                          ? `/catering-meal/${el.slug}`
                          : `/departments/${el.slug}`
                      }
                      key={el.category_ids}
                    >
                      <div>
                        <div className="card-alignment">
                          <div className="card">
                            <div className="card-image">
                              <img src={el.medium_image} alt={el.slug} />
                            </div>
                            <div className="card-details">
                              <p className="theme-2-font">{el.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </section>
      ) : (
        couponDepartmentmenu &&
        !!couponDepartmentmenu.length && (
          <section className="department-slider-theme2">
            <div className="container">
              <div className="page-title">
                <h1>Departments</h1>
              </div>
              <div className="department-slider-theme2">
                {couponDepartmentmenu.map((el) => {
                  return (
                    <Link
                      to={
                        el.new_page_slug !== ""
                          ? `/custom-pages/${el.new_page_slug}`
                          : `/coupon-department/${el.department_name_slug}`
                      }
                      key={el.department_id}
                    >
                      <div>
                        <div className="card-alignment">
                          <div className="card">
                            <div className="card-image">
                              <img
                                src={el.department_image_url}
                                alt={el.department_name_slug}
                              />
                            </div>
                            <div className="card-details">
                              <p>{el.department_name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </section>
        )
      )}
    </>
  );
};
export default DepartmentSliderTheme2;

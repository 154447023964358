import React from "react";
import "./center-card.scss";

const CenterImage = ({ section }) => {
  return (
    <>
      <div className="container content">
        <div className="flex-container">
          <h1>{section ? section.highlight_title : ""}</h1>
        </div>
        {/* <br></br> */}
        {
            section.image !== "" ?(
            <>
                <div className="flex-container">
              {section.click_on_image_redirect_url ? (
                <a href={section.click_on_image_redirect_url} target="_blank" rel="noreferrer">
                  <img
                    src={section ? section.image : ""}
                    alt="AboutImage"
                    className="center-align-image"
                  />
                </a>
              ) : (
                <img
                  src={section ? section.image : ""}
                  alt="AboutImage"
                  className="center-align-image"
                />
              )}
            </div>
            <br></br>
            </>
            ) : ""
          }
        <p
          dangerouslySetInnerHTML={{
            __html: section ? section.content : "",
          }}
        >
          { }
        </p>
      </div>
    </>
  );
};

export default CenterImage;

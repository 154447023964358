import React from "react";
import CheckoutDetails from "./CheckoutDetails";
import CheckoutInfo from "./CheckoutInfo";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import "./checkout.scss";

const Checkout = () => {
  const breadcrumbList = [{ lable: "Checkout", link: "" }];
  return (
    <>
           <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={`Checkout`} />

      <CheckoutDetails />

      <CheckoutInfo />
    </>
  );
};
export default Checkout;

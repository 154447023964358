import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SearchProducts from "./SearchProducts";
import StoreLocation from "./StoreLocation";
import Wishlist from "./Wishlist";
import ShoppingCart from "./ShoppingCart";
import MyAccount from "./MyAccount";
import MobileSidebar from "./MobileSidebar";

import "./main-header.scss";

const MainHeader = () => {
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const allow_ecommerce = useSelector(({ common }) => common.commonDetailsData.ecom_section.allow_ecommerce);
  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  return (
    <>
      <div className="main-header">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              {headerSectionData && headerSectionData.rsa_client_logo ? (
                <NavLink to="/">
                  <img
                    src={headerSectionData.rsa_client_logo}
                    alt={headerSectionData.image_alt_text}
                  />
                </NavLink>
              ) : (
                ""
              )}
            </div>
            <div className="grid-items">
              <div className="main-header-middle-grid">
                <div className="main-header-middle-grid-items">
                  <SearchProducts />
                </div>
                <div className="main-header-middle-grid-items">
                  <StoreLocation />
                </div>
              </div>
            </div>
            <div
              className={"grid-items-flex-end grid-items"
              }
            >
              <NavLink to="/list-items">
                <Wishlist />
              </NavLink>
              {isUserLoggedIn ? (
                <>
                  {allow_ecommerce === "1" ? (<ShoppingCart />) : ("")}
                  <MyAccount />
                </>
              ) : (
                ""
              )}

              <div
                className="mobile-menu"
                onClick={() => setMobileSidebar(!mobileSidebar)}
              >
                <i className="fa-solid fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileSidebar
        isMobileSidebar={mobileSidebar}
        onClose={() => setMobileSidebar(false)}
      />
    </>
  );
};

export default MainHeader;

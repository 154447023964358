import {
  UPDATE_CUSTOMER_PROFILE_DETAILS,
  UPDATE_CUSTOMER_PROFILE_SUCCESS,
  UPDATE_CUSTOMER_PROFILE_FAILURE,
  UPDATE_CUSTOMER_PIN_DETAILS,
  UPDATE_CUSTOMER_PIN_SUCCESS,
  UPDATE_CUSTOMER_PIN_FAILURE,
  FETCH_ORDERS_DATA,
  FETCH_ORDERS_DATA_SUCCESS,
  FETCH_ORDERS_DATA_FAILURE,
  FETCH_WISHLIST_DATA,
  FETCH_WISHLIST_DATA_SUCCESS,
  FETCH_WISHLIST_DATA_FAILURE,
  FETCH_CUSTOMER_PROFILE_DETAILS,
  FETCH_CUSTOMER_PROFILE_SUCCESS,
  FETCH_CUSTOMER_PROFILE_FAILURE,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_CATERING_MEALS_ORDERS_DATA,
  FETCH_CATERING_MEALS_ORDERS_DATA_SUCCESS,
  FETCH_CATERING_MEALS_ORDERS_DATA_FAILURE,
  CANCEL_ORDER_BY_CUSTOMER,
  CANCEL_ORDER_BY_CUSTOMER_SUCCESS,
  CANCEL_ORDER_BY_CUSTOMER_FAILURE,
  FETCH_EBT_CARD_INFORMATION,
  FETCH_EBT_CARD_INFORMATION_SUCCESS,
  FETCH_EBT_CARD_INFORMATION_FAILURE,
  CLEAR_MESSAGE_FOR_UPDATE_PROFILE,
  CLEAR_MESSAGE_FOR_CHANGE_PASSWORD,
} from "redux/constants/actionTypes";

let initialState = {
  updateProfileLoading: false,
  updateProfileStatus: "",
  updateProfileMessage: "",
  updateProfileData: {},

  updatePasswordLoading: false,
  updatePasswordStatus: "",
  updatePasswordMessage: "",

  orderlistData: [],
  orderlistDataLoading: false,
  orderlistDataError: "",
  OrderDetailsListLimit: 0,
  OrderDetailsTotalRecord: 0,

  cateringAndMealsOrderList: [],
  cateringAndMealsOrderLoading: false,
  cateringAndMealsOrderError: "",
  cateringAndMealsOrderListLimit: 5,
  cateringAndMealsOrderTotalRecord: 0,

  wishlistData: {},
  wishlistDataLoading: false,
  wishlistDataError: "",

  customerlistData: null,
  customerlistDataLoading: false,
  customerlistDataError: "",

  orderdetailsData: {},
  orderdetailsDataLoading: false,
  orderdetailsDataError: "",

  cancelOrderLoading: false,
  cancelOrderError: "",
  cancelOrderDetail: null,

  fetchEbtCardInformationLoading: false,
  ebtCardInformation: null,
  fetchEbtCardInformationError: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_PROFILE_DETAILS:
      return {
        ...state,

        updateProfileLoading: true,
        updateProfileStatus: "",
      };
    case UPDATE_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileStatus: action.payload.status,
        updateProfileMessage: action.payload.message,
        customerlistData: action.payload.data,
      };
    case UPDATE_CUSTOMER_PROFILE_FAILURE:
      return {
        ...state,
        updateProfileStatus: action.payload.error,
      };

    case CLEAR_MESSAGE_FOR_UPDATE_PROFILE:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileStatus: "",
        updateProfileMessage: "",
      };
    case UPDATE_CUSTOMER_PIN_DETAILS:
      return {
        ...state,

        updatePasswordLoading: true,
        updatePasswordStatus: "",
      };
    case UPDATE_CUSTOMER_PIN_SUCCESS:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordStatus: action.payload.status,
        updatePasswordMessage: action.payload.message,
      };
    case UPDATE_CUSTOMER_PIN_FAILURE:
      return {
        ...state,
        updatePasswordStatus: action.payload.error,
      };
    case CLEAR_MESSAGE_FOR_CHANGE_PASSWORD:
      return {
        ...state,

        updatePasswordLoading: false,
        updatePasswordStatus: "",
      };
    case FETCH_ORDERS_DATA:
      return {
        ...state,
        orderlistDataLoading: true,
        orderlistData: [],
      };
    case FETCH_ORDERS_DATA_SUCCESS:
      return {
        ...state,
        orderlistDataLoading: false,
        orderlistData: action.payload.data,
        OrderDetailsListLimit: action.payload.limit,
        OrderDetailsTotalRecord: action.payload.totalRecord,
        OrderStatusList: action.payload.order_status_list,
      };
    case FETCH_ORDERS_DATA_FAILURE:
      return {
        ...state,
        orderlistDataError: action.payload.error,
      };

    case FETCH_WISHLIST_DATA:
      return {
        ...state,
        wishlistDataLoading: true,
      };
    case FETCH_WISHLIST_DATA_SUCCESS:
      return {
        ...state,
        wishlistDataLoading: false,
        wishlistData: action.payload,
      };
    case FETCH_WISHLIST_DATA_FAILURE:
      return {
        ...state,
        wishlistDataError: action.payload.error,
      };

    case FETCH_CUSTOMER_PROFILE_DETAILS:
      return {
        ...state,
        customerlistDataLoading: true,
      };
    case FETCH_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        customerlistDataLoading: false,
        customerlistData: action.payload,
      };
    case FETCH_CUSTOMER_PROFILE_FAILURE:
      return {
        ...state,
        customerlistDataError: action.payload.error,
      };

    case FETCH_ORDER_DETAILS:
      return {
        ...state,
        orderdetailsDataLoading: true,
      };
    case FETCH_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderdetailsDataLoading: false,
        orderdetailsData: action.payload,
      };
    case FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        orderdetailsDataError: action.payload.error,
      };
    //catering and mealkit order data
    case FETCH_CATERING_MEALS_ORDERS_DATA:
      return {
        ...state,
        cateringAndMealsOrderLoading: true,
      };
    case FETCH_CATERING_MEALS_ORDERS_DATA_SUCCESS:
      return {
        ...state,
        cateringAndMealsOrderLoading: false,
        cateringAndMealsOrderListLimit: action.payload.limit,
        cateringAndMealsOrderTotalRecord: action.payload.totalRecord,
        cateringAndMealsOrderList: action.payload.data,
      };
    case FETCH_CATERING_MEALS_ORDERS_DATA_FAILURE:
      return {
        ...state,
        cateringAndMealsOrderLoading: false,
        cateringAndMealsOrderError: action.payload.error,
      };
    //cancel order detail
    case CANCEL_ORDER_BY_CUSTOMER:
      return {
        ...state,
        cancelOrderLoading: true,
      };
    case CANCEL_ORDER_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        cancelOrderLoading: false,
        cancelOrderDetail: action.payload,
      };
    case CANCEL_ORDER_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        cancelOrderLoading: false,
        cancelOrderError: action.payload.error,
      };

    case FETCH_EBT_CARD_INFORMATION:
      return {
        ...state,
        fetchEbtCardInformationLoading: true,
      };
    case FETCH_EBT_CARD_INFORMATION_SUCCESS:
      return {
        ...state,
        fetchEbtCardInformationLoading: false,
        ebtCardInformation: action.payload,
      };
    case FETCH_EBT_CARD_INFORMATION_FAILURE:
      return {
        ...state,
        fetchEbtCardInformationLoading: false,
        fetchEbtCardInformationError: action.payload.error,
      };

    default:
      return state;
  }
};

export default customerReducer;

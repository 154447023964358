import { useSelector } from "react-redux";
import "./copy-right-section.scss";

const CopyRightSection = () => {
  const copyRightSectionData = useSelector(
    ({ common }) => common.commonDetailsData.footer_section
  );

  return (
    <div className="copyright-section">
      <div className="page-container">
        <div className="powered-by-all-align-content">
          <p>
            {copyRightSectionData && copyRightSectionData.copyright_title
              ? copyRightSectionData.copyright_title
              : ""}
          </p>
          <div className="powered-by-align">
            <span>Powered by</span>
            {copyRightSectionData && copyRightSectionData.powered_by ? (
              <img src={copyRightSectionData.powered_by} alt="PowerdByImage" />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CopyRightSection;

import {
  CONTACT_FORM,
  CONTACT_FORM_SUCCESS,
  CONTACT_FORM_FAILURE
} from "redux/constants/actionTypes";

let initialState = {
  contactFormData: [],
  contactFormDataLoading: false,
  contactFormError: ""
};

const contactFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_FORM:
      return {
        ...state,
        contactFormDataLoading: true
      };
    case CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactFormDataLoading: false,
        contactFormData: action.payload
      };
    case CONTACT_FORM_FAILURE:
      return {
        ...state,
        contactFormError: action.payload.error
      };
    default:
      return state;
  }
};

export default contactFormReducer;

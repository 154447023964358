import React, { useEffect, useState } from "react";
import "./b2b.scss";
import { useSelector, useDispatch } from "react-redux";
import { getLocalStorageValue } from "config/helper";
import { b2bDetails } from "../../redux/actions/b2b";

function BusinessAccountInfomation() {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const { storeListData, currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const memberNumber = getLocalStorageValue("member-number");
  const userToken = getLocalStorageValue("user-token");

  // Getting redux state Values
  const { b2bData, b2bDataLoading, b2bDataError } = useSelector(
    (b2bFromData) => b2bFromData?.b2bReducer
  );

  // form validations added [21-06-2024] by kirankonda
  // useState for capturing the values of the Input fields
  const [formData, setFormData] = useState({
    business: "",
    title: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
    email: "",
    selected_store_location: ""
  });

  // request body for API
  const reqbody = {
    RSAClientId: clientId,
    ClientStoreId: storeId,
    AppName: isUserLoggedIn ? "shop" : "",
    member_number: isUserLoggedIn ? memberNumber : "",
    user_token: isUserLoggedIn ? userToken : "",
    Version: isUserLoggedIn ? "1" : "",
    DeviceType: isUserLoggedIn ? "web" : "",
    business_name: formData.business,
    title_or_position: formData.title,
    email: formData.email,
    first_name: formData.firstname,
    last_name: formData.lastname,
    phone: formData.phonenumber,
    selected_store_location: formData.selected_store_location
  };
  // useState for showing error message
  const [errorMessage, setErrorMesaage] = useState({
    business: "",
    title: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
    email: "",
    selected_store_location: ""
  });
  // use state for to know user entered in input
  const [touched, settouched] = useState({
    business: false,
    email: false
  });
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const handleFormChange = (e) => {
    // capturing input values
    const { name, value } = e.target;

    if (name === "phonenumber") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormData({
        ...formData,
        [name]: numericValue
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    // validatins on onChange
    if (touched[name] && !value) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "This is a required field."
      });
    } else if (name === "email" && !emailRegex.test(formData[name])) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "Please Enter Valid Email ."
      });
    } else {
      setErrorMesaage({ ...errorMessage, [name]: "" });
    }
  };

  // Email Validation
  const handleEmailChange = (e) => {
    const emailValue = formData.email;
    const isValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      emailValue
    );

    if (emailValue === "") {
      setErrorMesaage({
        ...errorMessage,
        email: "This is a required field."
      });
    } else {
      if (!isValidEmail) {
        setErrorMesaage({
          ...errorMessage,
          email: "Please Enter Valid Email ."
        });
      } else {
        setErrorMesaage({
          ...errorMessage,
          email: ""
        });
      }
    }
  };

  // Catching Errors in input touch
  const handleBlur = (e) => {
    const { name } = e.target;
    settouched({
      ...touched,
      [name]: true
    });
    if (!formData[name]) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "This is a required field."
      });
    }
    if (name === "email" && !emailRegex.test(formData[name])) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "Please Enter Valid Email ."
      });
    }
  };

  // checking errors in submit button and also form submitting
  const handleFormSubmit = (e) => {
    const requiredFormData = {
      business: formData.business,
      email: formData.email
    };
    e.preventDefault();
    const newErrorMessages = {};

    Object.keys(requiredFormData).forEach((key) => {
      if (!requiredFormData[key]) {
        newErrorMessages[key] = "This is a required field.";
      }
      if (key === "email" && !emailRegex.test(requiredFormData["email"])) {
        newErrorMessages[key] = "Please Enter Valid Email .";
      }
    });
    if (formData.phonenumber !== "") {
      if (formData.phonenumber.length < 10) {
        newErrorMessages["phonenumber"] = "Please Enter Valid Phonenumber";
      }
    }
    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMesaage(newErrorMessages);
    } else if (!emailRegex.test(formData.email)) {
      setErrorMesaage({
        ...errorMessage,
        email: "Please Enter Valid Email ."
      });
    } else {
      dispatch(b2bDetails(reqbody));
    }
  };

  // rest form data if form successfully submitted
  useEffect(() => {
    if (b2bData?.status === "success") {
      setFormData({
        business: "",
        title: "",
        firstname: "",
        lastname: "",
        phonenumber: "",
        email: "",
        selected_store_location: ""
      });
    }
  }, [b2bData]);

  return (
    <div className="b2b-mainSection">
      <div className="mission-container">
        <h2>BUSINESS ACCOUNT INFORMATION</h2>
      </div>

      <div className="nf-form-layout">
        <form onSubmit={handleFormSubmit}>
          <div>
            <div className="nf-form-content ">
              <div className="nf-row">
                <div className="nf-cell" style={{ width: "100%" }}>
                  <div
                    id="nf-field-77-container"
                    className="nf-field-container html-container  label-below ">
                    <div className="nf-field">
                      <div
                        id="nf-field-77-wrap"
                        className="field-wrap html-wrap"
                        data-field-id="77">
                        <div className="nf-field-element">
                          <h3>Company Information</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell" style={{ width: "50%" }}>
                  <div
                    id="nf-field-78-container"
                    className="nf-field-container textbox-container  label-below ">
                    <div className="nf-field">
                      <div
                        id="nf-field-78-wrap"
                        className="field-wrap textbox-wrap nf-fail nf-error"
                        data-field-id="78">
                        <div className="nf-field-label">
                          <label
                            for="nf-field-78"
                            id="nf-label-field-78"
                            className="">
                            Business / Organization{" "}
                            <span className="ninja-forms-req-symbol">*</span>
                          </label>
                        </div>
                        <div className="nf-field-element">
                          <input
                            type="text"
                            className="ninja-forms-field nf-element"
                            id="business"
                            name="business"
                            value={formData?.business}
                            onChange={handleFormChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      {errorMessage.business && (
                        <p style={{ color: "red" }}>{errorMessage.business}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="nf-cell" style={{ width: "50%" }}>
                  <div
                    id="nf-field-79-container"
                    className="nf-field-container textbox-container  label-below ">
                    <div className="nf-field">
                      <div
                        id="nf-field-79-wrap"
                        className="field-wrap textbox-wrap"
                        data-field-id="79">
                        <div className="nf-field-label">
                          <label
                            for="nf-field-79"
                            id="nf-label-field-79"
                            className="">
                            Title / Position
                          </label>
                        </div>
                        <div className="nf-field-element">
                          <input
                            type="text"
                            className="ninja-forms-field nf-element"
                            id="Title"
                            name="title"
                            value={formData?.title}
                            onChange={handleFormChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell" style={{ width: "100%" }}>
                  <div
                    id="nf-field-80-container"
                    className="nf-field-container html-container  label-below ">
                    <div className="nf-field">
                      <div
                        id="nf-field-80-wrap"
                        className="field-wrap html-wrap"
                        data-field-id="80">
                        <div className="nf-field-element">
                          <h3>Account Information</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell" style={{ width: "50%" }}>
                  <div
                    id="nf-field-81-container"
                    className="nf-field-container textbox-container  label-below ">
                    <div className="nf-field">
                      <div
                        id="nf-field-81-wrap"
                        className="field-wrap textbox-wrap"
                        data-field-id="81">
                        <div className="nf-field-label">
                          <label
                            for="nf-field-81"
                            id="nf-label-field-81"
                            className="">
                            First Name
                          </label>
                        </div>
                        <div className="nf-field-element">
                          <input
                            type="text"
                            className="ninja-forms-field nf-element"
                            id="firstname"
                            name="firstname"
                            value={formData?.firstname}
                            onChange={handleFormChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nf-cell" style={{ width: "50%" }}>
                  <div
                    id="nf-field-81-container"
                    className="nf-field-container textbox-container  label-below ">
                    <div className="nf-field">
                      <div
                        id="nf-field-81-wrap"
                        className="field-wrap textbox-wrap"
                        data-field-id="81">
                        <div className="nf-field-label">
                          <label
                            for="nf-field-81"
                            id="nf-label-field-81"
                            className="">
                            Last Name
                          </label>
                        </div>
                        <div className="nf-field-element">
                          <input
                            type="text"
                            className="ninja-forms-field nf-element"
                            id="lastname"
                            name="lastname"
                            value={formData?.lastname}
                            onChange={handleFormChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nf-row">
                <div className="nf-cell" style={{ width: "50%" }}>
                  <div
                    id="nf-field-83-container"
                    className="nf-field-container textbox-container  label-below ">
                    <div className="nf-field">
                      <div
                        id="nf-field-83-wrap"
                        className="field-wrap textbox-wrap"
                        data-field-id="83">
                        <div className="nf-field-element">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-83"
                              id="nf-label-field-83"
                              className="">
                              Cell Phone Number
                            </label>
                          </div>
                          <input
                            type="text"
                            className="ninja-forms-field nf-element"
                            id="phonenumber"
                            name="phonenumber"
                            value={formData?.phonenumber}
                            onChange={handleFormChange}
                            maxlength="10"
                          />
                          {errorMessage.phonenumber && (
                            <p style={{ color: "#e80000" }}>
                              {errorMessage.phonenumber}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nf-cell" style={{ width: "50%" }}>
                  <div
                    id="nf-field-84-container"
                    className="nf-field-container email-container  label-below ">
                    <div className="nf-field">
                      <div
                        id="nf-field-84-wrap"
                        className="field-wrap email-wrap nf-fail nf-error"
                        data-field-id="84">
                        <div className="nf-field-element">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-84"
                              id="nf-label-field-84"
                              className="">
                              Email Address{" "}
                              <span className="ninja-forms-req-symbol">*</span>
                            </label>
                          </div>
                          <input
                            type="test"
                            className="ninja-forms-field nf-element"
                            id="email"
                            name="email"
                            value={formData?.email}
                            onChange={handleFormChange}
                            onBlur={handleBlur}
                            onKeyUp={handleEmailChange}
                            onKeyDown={handleEmailChange}
                          />
                        </div>
                      </div>
                      {errorMessage.email && (
                        <p style={{ color: "#e80000" }}>{errorMessage.email}</p>
                      )}
                    </div>
                    <div className="nf-after-field"></div>
                  </div>
                </div>
              </div>
              <div className="nf-row-store">
                <div className="nf-cell" style={{ width: "100%" }}>
                  <div
                    id="nf-field-85-container"
                    className="nf-field-container listselect-container  label-below  list-container">
                    <div className="nf-field">
                      <div
                        id="nf-field-85-wrap"
                        className="field-wrap listselect-wrap list-wrap list-select-wrap"
                        data-field-id="85">
                        <div className="nf-field-element">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-85"
                              id="nf-label-field-85"
                              className="">
                              Store Location
                            </label>
                          </div>
                        </div>

                        <select
                          id="dropdown"
                          value={formData?.selected_store_location}
                          onChange={handleFormChange}
                          name="selected_store_location"
                          className="ninja-forms-field nf-element">
                          <option>Choose Store</option>
                          {storeListData.map((option) => (
                            <option value={option.ClientStoreName}>
                              {option.ClientStoreName}
                            </option>
                          ))}
                        </select>
                        <div for="nf-field-85"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: "10px" }} className="nf-row-submit">
                {b2bData?.message && (
                  <p className="success-msg" style={{ color: "green" }}>
                    {b2bData?.message}
                  </p>
                )}
                {b2bDataError && <p style={{ color: "red" }}>{b2bDataError}</p>}
              </div>
              <div className="nf-row-submit">
                <div className="nf-cell" style={{ width: "100%" }}>
                  <div
                    id="nf-field-86-container"
                    className="nf-field-container submit-container  label-below  textbox-container">
                    <div className="nf-field">
                      <div
                        id="nf-field-86-wrap"
                        className="field-wrap submit-wrap textbox-wrap"
                        data-field-id="86">
                        <div className="nf-field-label"></div>
                        <div className="nf-field-element">
                          <input
                            style={{
                              cursor: b2bDataLoading ? "not-allowed" : "pointer"
                            }}
                            id="submit"
                            className="ninja-forms-field nf-element-button "
                            type="submit"
                            disabled={b2bDataLoading}
                            value={b2bDataLoading ? "Submitting..." : "Submit"}
                          />
                        </div>
                        <div className="nf-error-wrap"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BusinessAccountInfomation;

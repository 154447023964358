import React from "react";
import { Link } from "react-router-dom";
import "./coupon-add-space-section.scss";
const AdsSpaceSection = ({ adImage }) => {
  const { horizontal_ads_image = "", horizontal_ads_url = "" } = adImage;

  return (
    <>
      {horizontal_ads_image ? (
        <div>
          <div className="ads-space-section-weeklyAdd">
            {/* <Link to={`/${horizontal_ads_url}`}>
              <img src={horizontal_ads_image} alt="ads" />
            </Link> */}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AdsSpaceSection;

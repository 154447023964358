import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpotlightBanner from "components/SpotlightBanner";
import DepartmentSlider from "./DepartmentSlider";
import OfferSection from "./OfferSection";
import PreviousBuysSection from "./PreviousBuysSection";
import AddSection from "./AddSection";
import { useDispatch, useSelector } from "react-redux";
import EcomDisabledOfferSection from "./EcomDisabledOfferSection";
import DownloadOurApp from "./DownloadOurApp";
import SingleBanner from "components/SingleBanner";
import PromotionSection from "./PromotionSection";
import { getLocalStorageValue, setLocalStorageValue } from "config/helper";
import { getOffersWithProductData } from "redux/actions/homepage";
import OfferBanner from "./OfferBanner/OfferBanner";
import MultiBanner from "./MultiBanner/MultiBanner";
import TwoBannerSection from "./TwoBannerSection/TwoBannerSection";
import DealOfWeek from "./DealOfWeek";
import DepartmentSliderTheme2 from "./DepartmentSliderTheme2/index";
import FeaturedProducts from "./FeaturedProducts";
import WhatOnSaleProducts from "./WhatOnSaleProducts";
import DescoverSection from "./DescoverSection/DescoverSection";
import EcomDisabledOfferSectionTheme2 from "./EcomDisabledOfferSectionTheme2";
import CampaignSection from "./CampaignSection";
import FocusDepartment from "routes/FocusDepartment";
import { getBannerAdsData } from "redux/actions";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";
import MultiBannerAd from "./MultiBannerAd/MultiBannerAd";
import BillBoardBanner from "components/BillboardBanner";
import BannerSection from "./BannerSection/BannerSection";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const clientId = getLocalStorageValue("RSAclient-id");
  const memberNumber = getLocalStorageValue("member-number");
  const isLocalStorage = getLocalStorageValue("is_clear_localstorage");
  const { isUserLoggedIn } = useSelector(({ user }) => user);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [filteredBanners, setFilteredBanners] = useState([]);
  const { theme_type, is_clear_localstorage } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );
  const { offersWithProductDataLoading } = useSelector(({ homepage }) => homepage);
  const { bannerAdsData, bannerAdsDataLoading } = useSelector(({ bannerAds }) => bannerAds);
  const { allow_ecommerce = "", has_access_home_page } = useSelector(({ common }) => {
    const { ecom_section, header_section } = common.commonDetailsData;
    return {
      allow_ecommerce: ecom_section?.allow_ecommerce,
      has_access_home_page: header_section?.has_access_home_page,
    };
  });

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getOffersWithProductData(body));
    dispatch(getBannerAdsData({
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: isUserLoggedIn ? memberNumber : "",
      category_ids: "",
      search: "",
      funtional_text: "",
      placement: "homepage-banner-x",
    }));
  }, [storeId, clientId, isUserLoggedIn, memberNumber, userToken, dispatch]);

  useEffect(() => {
    if (is_clear_localstorage !== isLocalStorage && isLocalStorage !== null) {
      localStorage.clear();
      setLocalStorageValue("is_clear_localstorage", is_clear_localstorage);
      window.location.reload();
    } else {
      setLocalStorageValue("is_clear_localstorage", is_clear_localstorage);
    }
  }, [isLocalStorage, is_clear_localstorage]);

  useEffect(() => {
    if (has_access_home_page === "0") {
      navigate(allow_ecommerce === "1" ? "/search/products" : "/coupons");
    }
  }, [allow_ecommerce, has_access_home_page, navigate]);

  const renderAdsSpaceSection = (index) => {

    const ad = filteredBanners.filter(banner => banner.position === index)[0];
    return ad && <AdsSpaceSectionMainTheme2 adsDetails={ad} isMobile={isMobile ?? false} />;
  };

  const checkViewportWidth = useCallback(() => {
    setIsMobile(window.innerWidth < 769);
  }, []);

  useEffect(() => {
    checkViewportWidth();
    window.addEventListener('resize', checkViewportWidth);
    return () => {
      window.removeEventListener('resize', checkViewportWidth);
    };
  }, [checkViewportWidth]);

  useEffect(() => {
    setFilteredBanners(bannerAdsData?.ads?.filter(ad => ad.slotId === "Horizontal Banners")[0]?.banners ?? []);
  }, [isMobile, bannerAdsData]);

  return (
    <>
      <SpotlightBanner />
      <SingleBanner />
      {!offersWithProductDataLoading && !bannerAdsDataLoading && bannerAdsData?.ads?.find(ad => ad.slotId === "Horizontal Banners")?.banners && (
        <BillBoardBanner isMobile={isMobile} adsDetails={bannerAdsData.ads
          .find(ad => ad.slotId === "Horizontal Banners")
          .banners
          .filter(banner => banner.position === 2 || banner.position === 3) ?? []} />
      )}
      {!offersWithProductDataLoading && !bannerAdsDataLoading && bannerAdsData?.ads?.find(ad => ad.slotId === "Vertical Banners")?.banners && (
        <MultiBannerAd data={bannerAdsData.ads.find(ad => ad.slotId === "Vertical Banners").banners} />
      )}
      <DownloadOurApp />
      <FocusDepartment />
      {theme_type === "1" && <DepartmentSlider />}
      <PromotionSection />
      {theme_type === "2" ? (
        allow_ecommerce === "1" ? (
          <>
            <WhatOnSaleProducts />
            <DescoverSection />
            <FeaturedProducts />
            <OfferBanner />
            <DealOfWeek />
            <TwoBannerSection />
            <CampaignSection />
            <MultiBanner />
            {!offersWithProductDataLoading && renderAdsSpaceSection(1)}
            <DepartmentSliderTheme2 />
            {!offersWithProductDataLoading && renderAdsSpaceSection(4)}

          </>
        ) : (
          <EcomDisabledOfferSectionTheme2 />
        )
      ) : (
        allow_ecommerce === "1" ? <OfferSection /> : <EcomDisabledOfferSection />
      )}
      <BannerSection />
      <AddSection />
      {isUserLoggedIn && <PreviousBuysSection />}
    </>
  );
};

export default HomePage;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import {
    addToCart,
} from "redux/actions";
import { getLocalStorageValue } from 'config/helper';


const SingleWishlistTheme2 = ({ wishlist }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clientId = getLocalStorageValue("RSAclient-id");
    const userToken = getLocalStorageValue("user-token");
    const memberNumber = getLocalStorageValue("member-number");
    const { currentStoreID } = useSelector(({ store }) => store);
    const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

    const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
    const { productCartList, addProductToCartListLoading } = useSelector(
        ({ cartWishlist }) => cartWishlist
    );
    const [isAddingProductToCart, setAddingProductToCart] = useState(false);


    const productId = wishlist.product_id;
    const addedProcduct =
        productCartList &&
        productCartList.find((product) => product.product_id === productId);


    const handleAddToCart = () => {
        if (!isUserLoggedIn) {
            navigate("/login");
        }

        const body = {
            RSAClientId: clientId,
            ClientStoreId: +storeId || 1,
            product_id: wishlist.product_id,
            qty: wishlist.qty,
            product_note: "",
            custom_options_arr: "",
            AppName: isUserLoggedIn ? "shop" : "",
            member_number: isUserLoggedIn ? memberNumber : "",
            user_token: isUserLoggedIn ? userToken : "",
            Version: isUserLoggedIn ? "1" : "",
            DeviceType: isUserLoggedIn ? "web" : ""
        };

        setAddingProductToCart(true);
        isUserLoggedIn &&
            dispatch(addToCart(body)).then(() => {
                setAddingProductToCart(false);
            });
    };

    return (
        wishlist.in_cart === '0' ?
            <tr key={wishlist.id}>
                <td>
                    <img
                        src={wishlist.image_medium}
                        style={{ height: "110px", width: "110px" }}
                        alt=""
                    />
                </td>
                <td>{wishlist.name}</td>
                <td>
                    <button onClick={handleAddToCart} disabled={addedProcduct}>
                        {isUserLoggedIn
                            ? addProductToCartListLoading && isAddingProductToCart
                                ? "Adding..."
                                : addedProcduct
                                    ? "Added"
                                    : "Add To Cart"
                            : "Sign in to Add"}</button>
                </td>
            </tr>
            : ''

    )
}

export default SingleWishlistTheme2;

import {
  FETCH_COUPONS_LIST_DATA,
  FETCH_COUPONS_LIST_DATA_SUCCESS,
  FETCH_COUPONS_LIST_DATA_FAILURE,
  FETCH_COUPONS_CATEGORIES_DATA,
  FETCH_COUPONS_CATEGORIES_SUCCESS,
  FETCH_COUPONS_CATEGORIES_FAILURE,
  UPDATE_COUPONS_CATEGORIES_LIST,
  FETCH_AVAILABLE_COUPONS,
  FETCH_AVAILABLE_COUPONS_SUCCESS,
  FETCH_AVAILABLE_COUPONS_FAILURE,
  APPLY_AVAILABLE_COUPONS,
  APPLY_AVAILABLE_COUPONS_SUCCESS,
  APPLY_AVAILABLE_COUPONS_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  couponData: {},
  couponDataLoading: true,
  couponDataError: "",

  couponCategoriesData: [],
  couponCategoriesDataLoading: true,
  couponCategoriesDataError: "",

  categoryCheckedList: [],
  availableCoupons: null,
  availableCouponsLoading: false,
  availableCouponsError: "",

  appliedAvailableCoupons: {},
  appliedAvailableCouponsLoading: false,
  appliedAvailableCouponsError: "",
};

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUPONS_LIST_DATA:
      return {
        ...state,
        couponDataLoading: true,
      };
    case FETCH_COUPONS_LIST_DATA_SUCCESS:
      return {
        ...state,
        couponDataLoading: false,
        couponData: action.payload,
      };
    case FETCH_COUPONS_LIST_DATA_FAILURE:
      return {
        ...state,
        couponDataError: action.payload.error,
      };

    case UPDATE_COUPONS_CATEGORIES_LIST:
      let upcategoryCheckedList = state.categoryCheckedList;

      if (upcategoryCheckedList.includes(action.payload)) {
        upcategoryCheckedList = upcategoryCheckedList.filter(
          (iftType) => iftType !== action.payload
        );
      } else {
        upcategoryCheckedList.push(action.payload);
      }

      return {
        ...state,
        categoryCheckedList: upcategoryCheckedList,
      };

    case FETCH_COUPONS_CATEGORIES_DATA:
      return {
        ...state,
        couponCategoriesDataLoading: true,
      };
    case FETCH_COUPONS_CATEGORIES_SUCCESS:
      return {
        ...state,
        couponCategoriesDataLoading: false,
        couponCategoriesData: action.payload,
      };
    case FETCH_COUPONS_CATEGORIES_FAILURE:
      return {
        ...state,
        couponCategoriesDataError: action.payload.error,
      };

    // available coupons
    case FETCH_AVAILABLE_COUPONS:
      return {
        ...state,
        availableCouponsLoading: true,
      };
    case FETCH_AVAILABLE_COUPONS_SUCCESS:
      return {
        ...state,
        availableCouponsLoading: false,
        availableCoupons: action.payload,
      };
    case FETCH_AVAILABLE_COUPONS_FAILURE:
      return {
        ...state,
        availableCouponsError: action.payload.error,
      };

    // Applied available coupons
    case APPLY_AVAILABLE_COUPONS:
      return {
        ...state,
        appliedAvailableCouponsLoading: true,
      };
    case APPLY_AVAILABLE_COUPONS_SUCCESS:
      return {
        ...state,
        appliedAvailableCouponsLoading: false,
        appliedAvailableCoupons: action.payload,
      };
    case APPLY_AVAILABLE_COUPONS_FAILURE:
      return {
        ...state,
        appliedAvailableCouponsError: action.payload.error,
      };

    default:
      return state;
  }
};

export default couponReducer;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  updateNodeFuctionalText,
  updateNodeFunctionalLevel,
  resetFilterSideBar,
} from "redux/actions";

const Item = (props) => {
  const {
    node,
    onClick,
    isChildNodesOpenDefault,
    category_ids,
    slug,
    text,
    product_count,
    isRootItem,
    funtional_level,
    function_names,
    redirectBaseUrl,
    querySearchKey,
    length,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isChildNodesOpen, setIsChildNodesOpen] = useState(false);
  let setSearchParams = useSearchParams()[1];

  const { nodeFunctionalText, nodeFunctionalLevel } = useSelector(
    ({ product }) => product
  );

  useEffect(() => {
    setIsChildNodesOpen(isChildNodesOpenDefault);
  }, [isChildNodesOpenDefault]);

  const handleClickRedirect = () => {
    navigate(`/${redirectBaseUrl}/${slug}`);
    dispatch(resetFilterSideBar());
  };

  const handleNodeChange = (
    e,
    text,
    funtional_level,
    function_names,
    category_ids
  ) => {
    dispatch(updateNodeFuctionalText(text));
    dispatch(updateNodeFunctionalLevel(funtional_level));
    if (querySearchKey === "category_ids") {
      setSearchParams({ category_ids: category_ids });
    } else {
      setSearchParams({ function_names: function_names });
    }
  };

  return (
    <div>
      <div
        className={
          isChildNodesOpenDefault ||
          (nodeFunctionalText === text &&
            nodeFunctionalLevel === funtional_level)
            ? "department-item active"
            : "department-item"
        }
        key={category_ids}
      >
        <div
          className="title-alignment"
          onClick={
            isRootItem
              ? handleClickRedirect
              : (e) => {
                  handleNodeChange(
                    e,
                    text,
                    funtional_level,
                    function_names,
                    category_ids
                  );
                  setIsChildNodesOpen(true);
                }
          }
        >
          <div>
            {text}
          </div>
        </div>
        {length == 1 && (
          <div
            onClick={() => {
              navigate(`/search/products/`);
            }}
          >
            Clear
          </div>
        )}
        {/* {node && node.length !== 0 && (
          //Onclick function added here for Icon - Madhu - 06-02-2023 - Ticket #1581
          <div
            className="add-icon"
            onClick={
              isRootItem
                ? () => {
                    handleClickRedirect();
                    setIsChildNodesOpen(!isChildNodesOpen);
                  }
                : (e) => {
                    handleNodeChange(
                      e,
                      text,
                      funtional_level,
                      function_names,
                      category_ids
                    );
                    setIsChildNodesOpen(!isChildNodesOpen);
                  }
            }
            // onClick={(e) => {

            //   setIsChildNodesOpen(!isChildNodesOpen);
            //   e.stopPropagation();
            // }}
          >
            <i
              className={
                isChildNodesOpen ? "fa-solid fa-minus" : "fa-solid fa-plus"
              }
            ></i>
          </div>
        )} */}
      </div>

      {/* <div className="child-department-list">
        {isChildNodesOpen &&
          node &&
          node.map((item, index) => (
            <Item
              key={index}
              {...item}
              onClick={onClick}
              isRootItem={false}
              querySearchKey={querySearchKey}
            />
          ))}
      </div> */}
    </div>
  );
};

const DepartmentListItemTheme2 = React.memo(
  ({ departmentTree, redirectBaseUrl, querySearchKey }) => {
    const [departmentTreeList, setDepartmentTree] = useState(departmentTree);

    const { departmentSlug } = useParams();

    useEffect(() => {
      setDepartmentTree(departmentTree);
    }, [departmentTree]);

    return (
      <div>
        {departmentTreeList
          ? departmentTreeList.map((item, index) => (
              <Item
                key={index}
                isRootItem={true}
                isChildNodesOpenDefault={departmentSlug === item.slug}
                {...item}
                redirectBaseUrl={redirectBaseUrl}
                querySearchKey={querySearchKey}
                length={departmentTreeList.length}
              />
            ))
          : ""}
      </div>
    );
  }
);

export default DepartmentListItemTheme2;

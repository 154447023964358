import React from "react";
import ProductImage from "../../../../assets/images/preview-product.png";
import "./reward-product-item.scss";

const RewardProductItem = ({ rewardProductItem }) => {
  return (
    <>
      <div className="details-reward-box">
        <div className="details-reward-img">
          <img src={rewardProductItem?.ImageUrl} alt="product_image" />
        </div>
        <div className="details-reward-content-wrapper">
          <div className="details-reward-title-btn">
            <div className="details-reward-title">
              {rewardProductItem?.Title} - {rewardProductItem?.TierTitle}
            </div>
            <div className="details-reward-point-btn">
              <span>{rewardProductItem?.UPC}</span>
            </div>
          </div>
          <div className="details-reward-range-bar">
            <div className="details-reward-point">
              <span>{rewardProductItem?.DeductPointsText}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardProductItem;

import React from "react";
import SaleProadData from "../Data/SaleProadData";
function SaleProAd({ onProductClick }) {
    return (
        <>
            <div className="sale-pro-ad-container">
                {SaleProadData.map(item => (
                    <div className="weekly-bottom-ad" key={item.id} onClick={() => onProductClick(item)}>
                        <div className="items">
                            <div className="ad-image">
                                <img src={item.proimage} alt="product image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
export default SaleProAd;

import {
  B2B_FORM,
  B2B_FORM_SUCCESS,
  B2B_FORM_FAILURE
} from "redux/constants/actionTypes";

let initialState = {
  b2bData: [],
  b2bDataLoading: false,
  b2bDataError: ""
};

const b2bReducer = (state = initialState, action) => {
  switch (action.type) {
    case B2B_FORM:
      return {
        ...state,
        b2bDataLoading: true
      };
    case B2B_FORM_SUCCESS:
      return {
        ...state,
        b2bDataLoading: false,
        b2bData: action.payload
      };
    case B2B_FORM_FAILURE:
      return {
        ...state,
        b2bDataLoading: false,
        b2bDataError: action.payload.error
      };
    default:
      return state;
  }
};

export default b2bReducer;

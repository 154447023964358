import React from "react";

const DropdownListItemTheme2 = ({
  onSelectOption,
  optionName,
  onClose,
  selectedOptionValue,
  optionData,
}) => {
  const subMenuList = optionData[optionName];
  return (
    <div className="submenu-list">
      <p className="disable-lable">{optionName}</p>
      {subMenuList &&
        subMenuList.map((store) => {
          return (
            <li
              key={store.ClientStoreId}
              className={
                selectedOptionValue === store.ClientStoreId
                  ? "submenu-item active"
                  : "submenu-item"
              }
              onClick={() => {
                onSelectOption(store.ClientStoreId, store.ClientStoreName);
                onClose();
              }}
            >
              {store.ClientStoreName}
            </li>
          );
        })}
    </div>
  );
};

export default DropdownListItemTheme2;

import {
  CONSTANTCONTACT_FORM,
  CONSTANTCONTACT_FORM_SUCCESS,
  CONSTANTCONTACT_FORM_FAILURE
} from "redux/constants/actionTypes";

let initialState = {
  constantContactData: [],
  constantContactLoading: false,
  constantContactError: ""
};

const constantContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTCONTACT_FORM:
      return {
        ...state,
        constantContactLoading: true
      };
    case CONSTANTCONTACT_FORM_SUCCESS:
      return {
        ...state,
        constantContactLoading: false,
        constantContactData: action.payload
      };
    case CONSTANTCONTACT_FORM_FAILURE:
      return {
        ...state,
        constantContactLoading: false,
        constantContactError: action.payload.error
      };
    default:
      return state;
  }
};

export default constantContactReducer;

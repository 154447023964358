import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "config/axios";
import { getLocalStorageValue } from 'config/helper';
import "./newsletter-theme-2.scss";

const NewsletterTheme2 = () => {
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  const [isShowSuccessMessage, setIsShowSuccessMessage] = useState("");

  const hideSuccessMessage = (e) => {
    setTimeout(() => {
      setIsShowSuccessMessage("");
    }, 3000);
  };

  const subscribeSectionData = useSelector(
    ({ common }) => common.commonDetailsData.subscribe_section
  );

  const { facebook_url, twitter_url, instagram_url, youtube_url } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const { register, handleSubmit, errors } = useForm();

  const handleSubmitSubscribeForm = (data,e) => {
    const { email } = data;
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      email_id: email,
    };
    axios.post("/AogAddSubscribe", body).then((response) => {
      const data = response.data;
      setIsShowSuccessMessage(data.message);
      e.target.reset();
      hideSuccessMessage();
    });
  };

  return (
    <>
      <section className="newsletter-section-theme2">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              <h1>{subscribeSectionData.subscribe_title}</h1>
              <p>{subscribeSectionData.subscribe_sub_title}</p>
            </div>
            <div className="grid-items">
              <form
                name="register"
                onSubmit={handleSubmit(handleSubmitSubscribeForm)}
              >
                <div className="subscribe">
                  <input
                    name="email"
                    id="email"
                    placeholder="Your email address"
                    ref={register({
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className="error-text">Please enter email.</span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span className="error-text">
                      The email address entered is invalid, please check the
                      formatting (e.g. email@domain.com)
                    </span>
                  )}

                  {isShowSuccessMessage && (
                    <span className="success-text">{isShowSuccessMessage}</span>
                  )}

                  <div className="button-align">
                    <button>Subscribe</button>
                  </div>
                </div>
              </form>
            </div>
            {(facebook_url || twitter_url || instagram_url || youtube_url) && (
              <div className="grid-items">
                <span>Follow us</span>
                <div className="social-align">
                  {facebook_url && (
                    <div>
                      <a href={facebook_url} rel="noreferrer" target="_blank">
                        <div>
                          <i className="fa-brands fa-facebook-f"></i>
                        </div>
                      </a>
                    </div>
                  )}
                  {twitter_url && (
                    <div>
                      <a href={twitter_url} rel="noreferrer" target="_blank">
                        <div>
                        <i className="fa-brands fa-x-twitter"></i>
                        </div>
                      </a>
                    </div>
                  )}
                  {instagram_url && (
                    <div>
                      <a href={instagram_url} rel="noreferrer" target="_blank">
                        <div>
                          <i className="fa fa-instagram"></i>
                        </div>
                      </a>
                    </div>
                  )}
                  {youtube_url && (
                    <div>
                      <a href={youtube_url} rel="noreferrer" target="_blank">
                        <div>
                          <i className="fa fa-youtube-play"></i>
                        </div>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsletterTheme2;

import {
  CCPA_FORM,
  CCPA_FORM_SUCCESS,
  CCPA_FORM_FAILURE
} from "redux/constants/actionTypes";

let initialState = {
  ccpaData: [],
  ccpaDataLoading: false,
  ccpaDataError: ""
};

const ccpaReducer = (state = initialState, action) => {
  switch (action.type) {
    case CCPA_FORM:
      return {
        ...state,
        ccpaDataLoading: true
      };
    case CCPA_FORM_SUCCESS:
      return {
        ...state,
        ccpaDataLoading: false,
        ccpaData: action.payload
      };
    case CCPA_FORM_FAILURE:
      return {
        ...state,
        ccpaDataLoading: false,
        ccpaDataError: action.payload.error
      };
    default:
      return state;
  }
};

export default ccpaReducer;


import React from "react";
import SideProadData from "../Data/SideProadData";
function SideProAd({ onProductClick }) {
    return (
        <>
            <div className="side-pro-ad-container">
                {SideProadData.map(item => (
                    <div className="weekly-right-ad" key={item.id}>
                        <div className="items" onClick={() => onProductClick(item)}>
                            <div className="ad-image">
                                <img src={item.proimage} alt="product image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
export default SideProAd;

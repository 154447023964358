import React, { useEffect, useState } from "react";
import "./PrivacyRequestorm.scss";
import { ccpaDetails } from "../../redux/actions/ccpa";
import { useSelector, useDispatch } from "react-redux";
import { getLocalStorageValue } from "config/helper";

function PrivacyRequestorm() {
  // Form validations on [21-06-2024] by kiran konda

  const dispatch = useDispatch();
  const { storeListData, currentStoreID } = useSelector(({ store }) => store);
  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const memberNumber = getLocalStorageValue("member-number");
  const userToken = getLocalStorageValue("user-token");

  const { ccpaData, ccpaDataLoading, ccpaDataError } = useSelector(
    (ccpaFormData) => ccpaFormData?.ccpaReducer
  );

  // useStatet for Form Values
  const [formData, setFormData] = useState({
    requestor_type: "",
    type_of_request: "",
    requestor_first_name: "",
    requestor_last_name: "",
    requestor_address: "",
    requestor_email: "",
    consument_first_name: "",
    consument_last_name: "",
    consument_address: "",
    consument_phone: "",
    consument_email: "",
    relationship_with_us: "",
    comments_or_question: "",
    privacyCheckbox: false
  });

  // request body for API
  const reqbody = {
    RSAClientId: clientId,
    ClientStoreId: storeId,
    AppName: isUserLoggedIn ? "shop" : "",
    member_number: isUserLoggedIn ? memberNumber : "",
    user_token: isUserLoggedIn ? userToken : "",
    Version: isUserLoggedIn ? "1" : "",
    DeviceType: isUserLoggedIn ? "web" : "",

    requestor_type: formData.requestor_type,
    type_of_request: formData.type_of_request,
    requestor_first_name: formData.requestor_first_name,
    requestor_last_name: formData.requestor_last_name,
    requestor_address: formData.requestor_address,
    requestor_email: formData.requestor_email,
    consument_first_name: formData.consument_first_name,
    consument_last_name: formData.consument_last_name,
    consument_address: formData.consument_address,
    consument_phone: formData.consument_phone,

    consument_email: formData.consument_email,
    relationship_with_us: formData.relationship_with_us,
    comments_or_question: formData.comments_or_question
  };
  // useState for capturing input event

  const [touched, setTouched] = useState({
    requestor_type: false,
    type_of_request: false,
    requestor_first_name: false,
    requestor_last_name: false,
    requestor_address: false,
    requestor_email: false,
    consument_first_name: false,
    consument_last_name: false,
    consument_address: false,
    consument_phone: false,
    consument_email: false,
    relationship_with_us: false,
    privacyCheckbox: false
  });
  // useState for error messages
  const [errorMessage, setErrorMesaage] = useState({
    requestor_type: "",
    type_of_request: "",
    requestor_first_name: "",
    requestor_last_name: "",
    requestor_address: "",
    requestor_email: "",
    consument_first_name: "",
    consument_last_name: "",
    consument_address: "",
    consument_phone: "",
    consument_email: "",
    relationship_with_us: "",
    privacyCheckbox: ""
  });

  // onChange Function
  const handleFormChange = (e) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const { name, value } = e.target;
    if (name === "privacyCheckbox") {
      if (formData?.privacyCheckbox === false) {
        setFormData({
          ...formData,
          privacyCheckbox: true
        });
      } else {
        setFormData({
          ...formData,
          [name]: false
        });
      }
    } else if (name === "consument_phone") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormData({
        ...formData,
        [name]: numericValue
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
    if (touched[name] && !value) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "This is a required field."
      });
    } else if (name === "requestor_email" && !emailRegex.test(formData[name])) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "Please Enter Valid Email ."
      });
    } else if (name === "consument_email" && !emailRegex.test(formData[name])) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "Please Enter Valid Email ."
      });
    } else if (name === "consument_phone" && formData[name].length < 10) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "Please Enter Valid Phone Number ."
      });
    } else {
      setErrorMesaage({
        ...errorMessage,
        [name]: ""
      });
    }
  };

  // onBluur function to capture input event
  const onBlurHandle = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
    if (!formData[name]) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "This is a required field."
      });
    }
  };

  // submit function

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const requiredForm = {
      requestor_type: formData?.requestor_type,
      type_of_request: formData?.type_of_request,
      requestor_first_name: formData?.requestor_first_name,
      requestor_last_name: formData?.requestor_last_name,
      requestor_address: formData?.requestor_address,
      requestor_email: formData?.requestor_email,
      consument_first_name: formData?.consument_first_name,
      consument_last_name: formData?.consument_last_name,
      consument_address: formData?.consument_address,
      consument_phone: formData?.consument_phone,
      consument_email: formData?.consument_email,
      relationship_with_us: formData?.relationship_with_us,
      privacyCheckbox: formData?.privacyCheckbox
    };
    const newErrorMessages = {};
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    Object.keys(requiredForm).forEach((key) => {
      if (!requiredForm[key]) {
        newErrorMessages[key] = "This is a required field.";
      }
      if (
        key === "consument_email" &&
        !emailRegex.test(requiredForm["consument_email"])
      ) {
        newErrorMessages[key] = "Please Enter Valid Email .";
      }
      if (
        key === "requestor_email" &&
        !emailRegex.test(requiredForm["requestor_email"])
      ) {
        newErrorMessages[key] = "Please Enter Valid Email .";
      }
      if (key === "consument_phone" && formData.consument_phone.length < 10) {
        newErrorMessages[key] = "Please Enter Valid Phone Number .";
      }
    });
    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMesaage(newErrorMessages);
    } else {
      dispatch(ccpaDetails(reqbody));
    }
  };

  useEffect(() => {
    if (ccpaData?.status === "success") {
      setFormData({
        requestor_type: "",
        type_of_request: "",
        requestor_first_name: "",
        requestor_last_name: "",
        requestor_address: "",
        requestor_email: "",
        consument_first_name: "",
        consument_last_name: "",
        consument_address: "",
        consument_phone: "",
        consument_email: "",
        relationship_with_us: "",
        comments_or_question: "",
        privacyCheckbox: false
      });
    }
  }, [ccpaData?.status]);

  // email handler
  const requestorEmailChange = (e) => {
    const emailValue = formData.requestor_email;
    const isValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      emailValue
    );

    if (emailValue === "") {
      setErrorMesaage({
        ...errorMessage,
        requestor_email: "This is a required field."
      });
    } else {
      if (!isValidEmail) {
        setErrorMesaage({
          ...errorMessage,
          requestor_email: "Please Enter Valid Email ."
        });
      } else {
        setErrorMesaage({
          ...errorMessage,
          requestor_email: ""
        });
      }
    }
  };
  const consumerEmailChange = (e) => {
    const emailValue = formData.consument_email;
    const isValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      emailValue
    );

    if (emailValue === "") {
      setErrorMesaage({
        ...errorMessage,
        consument_email: "This is a required field."
      });
    } else {
      if (!isValidEmail) {
        setErrorMesaage({
          ...errorMessage,
          consument_email: "Please Enter Valid Email ."
        });
      } else {
        setErrorMesaage({
          ...errorMessage,
          consument_email: ""
        });
      }
    }
  };

  const phoneNumberChange = () => {
    if (formData.consument_phone.length < 10) {
      setErrorMesaage({
        ...errorMessage,
        consument_phone: "Please Enter Valid Phone Number ."
      });
    } else {
      setErrorMesaage({
        ...errorMessage,
        consument_phone: ""
      });
    }
  };
  return (
    <div className="mainSection" style={{ display: "block" }}>
      <div className="mission-container">
        <h2>Privacy Request Form</h2>
        <p>
          Please use the form below for all California Consumer Privacy Act
          (CCPA) requests.
        </p>
      </div>

      <div className="form-div">
        <form onSubmit={handleFormSubmit}>
          <div>
            <div className="nf-before-form-content">
              <div className="nf-form-fields-required">
                Fields marked with an{" "}
                <span className="ninja-forms-req-symbol">*</span> are required
              </div>
            </div>
            <div className="nf-form-content ">
              <div className="nf-row">
                <div className="nf-cell">
                  <div
                    id="nf-field-67-container"
                    className="nf-field-container listradio-container  label-above  list-container">
                    <div className="nf-before-field"></div>
                    <div className="nf-field">
                      <div
                        id="nf-field-67-wrap"
                        className="field-wrap listradio-wrap list-wrap list-radio-wrap"
                        data-field-id="67">
                        <div className="nf-field-label">
                          <span id="nf-label-field" className="nf-label-span ">
                            Requestor Type{" "}
                            <span className="ninja-forms-req-symbol">*</span>
                          </span>
                        </div>

                        <div className="nf-field-element">
                          <ul aria-describedby="nf-error-67">
                            <li>
                              <input
                                type="radio"
                                id="nf-field-67-0"
                                name="requestor_type"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="Consumer"
                                checked={formData.requestor_type === "Consumer"}
                              />
                              <label
                                for="nf-field-67-0"
                                id="nf-label-class-field"
                                className="">
                                Consumer
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                id="nf-field-67-1"
                                name="requestor_type"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="Authorized Agent"
                                checked={
                                  formData.requestor_type === "Authorized Agent"
                                }
                              />
                              <label
                                for="nf-field-67-1"
                                id="nf-label-class-field"
                                className="">
                                Authorized Agent
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                id="nf-field-67-2"
                                name="requestor_type"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="ParentLegal Guardian"
                                checked={
                                  formData.requestor_type ===
                                  "ParentLegal Guardian"
                                }
                              />
                              <label
                                for="nf-field-67-2"
                                id="nf-label-class-field"
                                className="">
                                Parent/Legal Guardian
                              </label>
                            </li>
                          </ul>
                          {errorMessage.requestor_type && (
                            <p className="err-msg">
                              {errorMessage.requestor_type}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell">
                  <div
                    id="nf-field-68-container"
                    className="nf-field-container listradio-container  label-above  list-container">
                    <div className="nf-before-field"></div>
                    <div className="nf-field">
                      <div
                        id="nf-field-68-wrap"
                        className="field-wrap listradio-wrap list-wrap list-radio-wrap"
                        data-field-id="68">
                        <div className="nf-field-label">
                          <span id="nf-label-field" className="nf-label-span ">
                            Type of Request{" "}
                            <span className="ninja-forms-req-symbol">*</span>
                          </span>
                        </div>

                        <div className="nf-field-element">
                          <ul aria-describedby="nf-error-68">
                            <li>
                              <input
                                type="radio"
                                id="nf-field-68-0"
                                name="type_of_request"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="Know Categories"
                                checked={
                                  formData.type_of_request === "Know Categories"
                                }
                              />
                              <label
                                for="nf-field-68-0"
                                id="nf-label-class-field-68-0"
                                className="">
                                Know categories of personal information
                                collected, sold, shared, or disclosed
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                id="nf-field-68-1"
                                name="type_of_request"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="Obtain Copy"
                                checked={
                                  formData.type_of_request === "Obtain Copy"
                                }
                              />
                              <label
                                for="nf-field-68-1"
                                id="nf-label-class-field-68-1"
                                className="">
                                Obtain a copy of personal information
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                id="nf-field-68-2"
                                name="type_of_request"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="Delete Categories"
                                checked={
                                  formData.type_of_request ===
                                  "Delete Categories"
                                }
                              />
                              <label
                                for="nf-field-68-2"
                                id="nf-label-class-field-68-2"
                                className="">
                                Delete categories personal information
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                id="nf-field-68-3"
                                name="type_of_request"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="Opt out of info sale"
                                checked={
                                  formData.type_of_request ===
                                  "Opt out of info sale"
                                }
                              />
                              <label
                                for="nf-field-68-3"
                                id="nf-label-class-field-68-3"
                                className="">
                                Opt-out of the sale of your personal information
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                id="nf-field-68-4"
                                name="type_of_request"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="Opt out advetising"
                                checked={
                                  formData.type_of_request ===
                                  "Opt out advetising"
                                }
                              />
                              <label
                                for="nf-field-68-4"
                                id="nf-label-class-field-68-4"
                                className="">
                                Opt-out of the sharing of your personal
                                information for behavioral advertising{" "}
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                id="nf-field-68-5"
                                name="type_of_request"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="Limit use"
                                checked={
                                  formData.type_of_request === "Limit use"
                                }
                              />
                              <label
                                for="nf-field-68-5"
                                id="nf-label-class-field-68-5"
                                className="">
                                Limit the use/disclosure of your sensitive
                                personal information
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                id="nf-field-68-6"
                                name="type_of_request"
                                onChange={handleFormChange}
                                onBlur={onBlurHandle}
                                className="ninja-forms-field nf-element "
                                value="Correct inaccuracies"
                                checked={
                                  formData.type_of_request ===
                                  "Correct inaccuracies"
                                }
                              />
                              <label
                                for="nf-field-68-6"
                                id="nf-label-class-field-68-6"
                                className="">
                                Correct inaccurate personal information
                              </label>
                            </li>
                          </ul>
                          {errorMessage.type_of_request && (
                            <p className="err-msg">
                              {errorMessage.type_of_request}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell">
                  <div
                    id="nf-field-69-container"
                    className="nf-field-container html-container  label-above ">
                    <div className="nf-before-field"></div>
                    <div className="nf-field">
                      <div
                        id="nf-field-69-wrap"
                        className="field-wrap html-wrap"
                        data-field-id="69">
                        <div className="nf-field-label"></div>

                        <div className="nf-field-element">
                          <h2>REQUESTOR’S INFORMATION</h2>
                          <p>
                            Please enter information regarding the person who is
                            making this request so that we may communicate with
                            you regarding the request.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell">
                  <div
                    id="nf-field-52-container"
                    className="nf-field-container textbox-container  label-above desc-none ">
                    <div className="nf-before-field"></div>
                    <div className="nf-field">
                      <div
                        id="nf-field-52-wrap"
                        className="field-wrap textbox-wrap"
                        data-field-id="52">
                        <div className="nf-field-label">
                          <label
                            for="nf-field-52"
                            id="nf-label-field"
                            className="">
                            First Name{" "}
                            <span className="ninja-forms-req-symbol">*</span>
                          </label>
                        </div>

                        <div className="nf-field-element">
                          <input
                            type="text"
                            className="ninja-forms-field nf-element"
                            id="requestor_first_name"
                            name="requestor_first_name"
                            value={formData.requestor_first_name}
                            onChange={handleFormChange}
                            onBlur={onBlurHandle}
                          />
                          {errorMessage.requestor_first_name && (
                            <p className="err-msg">
                              {errorMessage.requestor_first_name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell">
                  <div
                    id="nf-field-53-container"
                    className="nf-field-container textbox-container  label-above desc-none ">
                    <div className="nf-before-field"></div>
                    <div className="nf-field">
                      <div
                        id="nf-field-53-wrap"
                        className="field-wrap textbox-wrap"
                        data-field-id="53">
                        <div className="nf-field-label">
                          <label
                            for="nf-field-53"
                            id="nf-label-field"
                            className="">
                            Last Name{" "}
                            <span className="ninja-forms-req-symbol">*</span>
                          </label>
                        </div>

                        <div className="nf-field-element">
                          <input
                            type="text"
                            className="ninja-forms-field nf-element"
                            id="requestor_last_name"
                            name="requestor_last_name"
                            value={formData.requestor_last_name}
                            onChange={handleFormChange}
                            onBlur={onBlurHandle}
                          />
                          {errorMessage.requestor_last_name && (
                            <p className="err-msg">
                              {errorMessage.requestor_last_name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell">
                  <div
                    id="nf-field-66-container"
                    className="nf-field-container address-container  label-above ">
                    <div className="nf-before-field"></div>
                    <div className="nf-field">
                      <div
                        id="nf-field-66-wrap"
                        className="field-wrap address-wrap"
                        data-field-id="66">
                        <div className="nf-field-label">
                          <label
                            for="nf-field-66"
                            id="nf-label-field-66"
                            className="">
                            Address{" "}
                            <span className="ninja-forms-req-symbol">*</span>
                          </label>
                        </div>

                        <div className="nf-field-element">
                          <input
                            type="text"
                            className="ninja-forms-field nf-element"
                            id="requestor_address"
                            name="requestor_address"
                            value={formData.requestor_address}
                            onChange={handleFormChange}
                            onBlur={onBlurHandle}
                          />
                          {errorMessage.requestor_address && (
                            <p className="err-msg">
                              {errorMessage.requestor_address}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell">
                  <div
                    id="nf-field-54-container"
                    className="nf-field-container email-container  label-above desc-none ">
                    <div className="nf-before-field"></div>
                    <div className="nf-field">
                      <div
                        id="nf-field-54-wrap"
                        className="field-wrap email-wrap"
                        data-field-id="54">
                        <div className="nf-field-label">
                          <label
                            for="nf-field-54"
                            id="nf-label-field"
                            className="">
                            Email{" "}
                            <span className="ninja-forms-req-symbol">*</span>
                          </label>
                        </div>

                        <div className="nf-field-element">
                          <input
                            type="email"
                            className="ninja-forms-field nf-element"
                            id="requestor_email"
                            name="requestor_email"
                            value={formData.requestor_email}
                            onChange={handleFormChange}
                            onBlur={onBlurHandle}
                            onKeyUp={requestorEmailChange}
                            onKeyDown={requestorEmailChange}
                          />
                          {errorMessage.requestor_email && (
                            <p className="err-msg">
                              {errorMessage.requestor_email}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell">
                  <div
                    id="nf-field-70-container"
                    className="nf-field-container html-container  label-above ">
                    <div className="nf-before-field"></div>
                    <div className="nf-field">
                      <div
                        id="nf-field-70-wrap"
                        className="field-wrap html-wrap"
                        data-field-id="70">
                        <div className="nf-field-label"></div>

                        <div className="nf-field-element">
                          <h2>CONSUMER INFORMATION</h2>
                          <p>
                            Please enter information regarding the person on
                            whose behalf request is being made below so their
                            identity can be verified.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nf-row">
                <div className="nf-cell" style={{ width: "100%" }}>
                  <div className="nf-before-field"></div>
                  <div className="nf-field">
                    <div
                      id="nf-field-71-wrap"
                      className="field-wrap textbox-wrap"
                      data-field-id="71">
                      <div className="nf-field-label">
                        <label
                          for="nf-field-71"
                          id="nf-label-field-71"
                          className="">
                          First Name{" "}
                          <span className="ninja-forms-req-symbol">*</span>
                        </label>
                      </div>

                      <div className="nf-field-element">
                        <input
                          type="text"
                          className="ninja-forms-field nf-element"
                          id="consument_first_name"
                          name="consument_first_name"
                          value={formData.consument_first_name}
                          onChange={handleFormChange}
                          onBlur={onBlurHandle}
                        />
                        {errorMessage.consument_first_name && (
                          <p className="err-msg">
                            {errorMessage.consument_first_name}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nf-row">
              <div className="nf-cell" style={{ width: "100%" }}>
                <div
                  id="nf-field-72-container"
                  className="nf-field-container textbox-container  label-above desc-none ">
                  <div className="nf-before-field"></div>
                  <div className="nf-field">
                    <div
                      id="nf-field-72-wrap"
                      className="field-wrap textbox-wrap"
                      data-field-id="72">
                      <div className="nf-field-label">
                        <label
                          for="nf-field-72"
                          id="nf-label-field-72"
                          className="">
                          Last Name{" "}
                          <span className="ninja-forms-req-symbol">*</span>
                        </label>
                      </div>

                      <div className="nf-field-element">
                        <input
                          type="text"
                          className="ninja-forms-field nf-element"
                          id="consument_last_name"
                          name="consument_last_name"
                          value={formData.consument_last_name}
                          onChange={handleFormChange}
                          onBlur={onBlurHandle}
                        />
                        {errorMessage.consument_last_name && (
                          <p className="err-msg">
                            {errorMessage.consument_last_name}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nf-row">
              <div className="nf-cell" style={{ width: "100%" }}>
                <div
                  id="nf-field-73-container"
                  className="nf-field-container address-container  label-above ">
                  <div className="nf-before-field"></div>
                  <div className="nf-field">
                    <div
                      id="nf-field-73-wrap"
                      className="field-wrap address-wrap"
                      data-field-id="73">
                      <div className="nf-field-label">
                        <label
                          for="nf-field-73"
                          id="nf-label-field-73"
                          className="">
                          Address{" "}
                          <span className="ninja-forms-req-symbol">*</span>
                        </label>
                      </div>

                      <div className="nf-field-element">
                        <input
                          type="text"
                          className="ninja-forms-field nf-element"
                          id="consument_address"
                          name="consument_address"
                          value={formData.consument_address}
                          onChange={handleFormChange}
                          onBlur={onBlurHandle}
                        />
                        {errorMessage.consument_address && (
                          <p className="err-msg">
                            {errorMessage.consument_address}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nf-row">
              <div className="nf-cell" style={{ width: "100%" }}>
                <div
                  id="nf-field-74-container"
                  className="nf-field-container phone-container  label-above  textbox-container">
                  <div className="nf-before-field"></div>
                  <div className="nf-field">
                    <div
                      id="nf-field-74-wrap"
                      className="field-wrap phone-wrap textbox-wrap"
                      data-field-id="74">
                      <div className="nf-field-label">
                        <label
                          for="nf-field-74"
                          id="nf-label-field-74"
                          className="">
                          Phone{" "}
                          <span className="ninja-forms-req-symbol">*</span>
                        </label>
                      </div>

                      <div className="nf-field-element">
                        <input
                          type="text"
                          className="ninja-forms-field nf-element"
                          id="consument_phone"
                          name="consument_phone"
                          value={formData.consument_phone}
                          onChange={handleFormChange}
                          onBlur={onBlurHandle}
                          onKeyDown={phoneNumberChange}
                          onKeyUp={phoneNumberChange}
                          maxlength="10"
                        />
                        {errorMessage.consument_phone && (
                          <p className="err-msg">
                            {errorMessage.consument_phone}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nf-row">
              <div className="nf-cell" style={{ width: "100%" }}>
                <div
                  id="nf-field-75-container"
                  className="nf-field-container email-container  label-above desc-none ">
                  <div className="nf-before-field"></div>
                  <div className="nf-field">
                    <div
                      id="nf-field-75-wrap"
                      className="field-wrap email-wrap"
                      data-field-id="75">
                      <div className="nf-field-label">
                        <label
                          for="nf-field-75"
                          id="nf-label-field-75"
                          className="">
                          Email{" "}
                          <span className="ninja-forms-req-symbol">*</span>
                        </label>
                      </div>

                      <div className="nf-field-element">
                        <input
                          type="text"
                          className="ninja-forms-field nf-element"
                          id="consument_email"
                          name="consument_email"
                          value={formData.consument_email}
                          onChange={handleFormChange}
                          onBlur={onBlurHandle}
                          onKeyUp={consumerEmailChange}
                          onKeyDown={consumerEmailChange}
                        />
                        {errorMessage.consument_email && (
                          <p className="err-msg">
                            {errorMessage.consument_email}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nf-row">
              <div className="nf-cell" style={{ width: "100%" }}>
                <div
                  id="nf-field-76-container"
                  className="nf-field-container listradio-container  label-above  list-container">
                  <div className="nf-before-field"></div>
                  <div className="nf-field">
                    <div
                      id="nf-field-76-wrap"
                      className="field-wrap listradio-wrap list-wrap list-radio-wrap"
                      data-field-id="76">
                      <div className="nf-field-label">
                        <span id="nf-label-field-76" className="nf-label-span ">
                          Relationship with Us
                          <span className="ninja-forms-req-symbol">*</span>
                        </span>
                      </div>

                      <div className="nf-field-element">
                        <ul aria-describedby="nf-error-76">
                          <li>
                            <input
                              type="radio"
                              id="nf-field-76-0"
                              name="relationship_with_us"
                              className="ninja-forms-field nf-element "
                              value="Employee/Job Applicant"
                              onChange={handleFormChange}
                              onBlur={onBlurHandle}
                              checked={
                                formData.relationship_with_us ===
                                "Employee/Job Applicant"
                              }
                            />
                            <label
                              for="nf-field-76-0"
                              id="nf-label-class-field-76-0"
                              className="">
                              Employee/Job Applicant
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              id="nf-field-76-1"
                              name="relationship_with_us"
                              className="ninja-forms-field nf-element "
                              value="Former EmployeeJob Applicant"
                              onChange={handleFormChange}
                              onBlur={onBlurHandle}
                              checked={
                                formData.relationship_with_us ===
                                "Former EmployeeJob Applicant"
                              }
                            />
                            <label
                              for="nf-field-76-1"
                              id="nf-label-class-field-76-1"
                              className="">
                              Former Employee/Job Applicant
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              id="nf-field-76-2"
                              name="relationship_with_us"
                              className="ninja-forms-field nf-element "
                              value="Customer"
                              onChange={handleFormChange}
                              onBlur={onBlurHandle}
                              checked={
                                formData.relationship_with_us === "Customer"
                              }
                            />
                            <label
                              for="nf-field-76-2"
                              id="nf-label-class-field-76-2"
                              className="">
                              Customer
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              id="nf-field-76-3"
                              name="relationship_with_us"
                              className="ninja-forms-field nf-element "
                              value="Other"
                              onChange={handleFormChange}
                              onBlur={onBlurHandle}
                              ecked={formData.relationship_with_us === "Other"}
                            />
                            <label
                              for="nf-field-76-3"
                              id="nf-label-class-field-76-3"
                              className="">
                              Other
                            </label>
                          </li>
                        </ul>
                        {errorMessage.relationship_with_us && (
                          <p className="err-msg">
                            {errorMessage.relationship_with_us}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nf-row">
              <div className="nf-cell" style={{ width: "100%" }}>
                <div
                  id="nf-field-57-container"
                  className="nf-field-container textarea-container  label-above desc-none ">
                  <div className="nf-before-field"></div>
                  <div className="nf-field">
                    <div
                      id="nf-field-57-wrap"
                      className="field-wrap textarea-wrap"
                      data-field-id="57">
                      <div className="nf-field-label">
                        <label
                          for="nf-field-57"
                          id="nf-label-field"
                          className="">
                          Comments / Questions
                        </label>
                      </div>

                      <div className="nf-field-element">
                        <textarea
                          id="comments_or_question"
                          name="comments_or_question"
                          value={formData.comments_or_question}
                          onChange={handleFormChange}
                          className="ninja-forms-field nf-element-textarea"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nf-row">
              <div className="nf-cell" style={{ width: "100%" }}>
                <div
                  id="nf-field-58-container"
                  className="nf-field-container hidden-container  label-above ">
                  <div className="nf-before-field"></div>
                  <div className="nf-field">
                    <div
                      id="nf-field-58-wrap"
                      className="field-wrap hidden-wrap"
                      data-field-id="58">
                      <div className="nf-field-label"></div>
                      <div className="nf-field-element">
                        <input
                          type="hidden"
                          id="privacyCheckbox"
                          name="privacyCheckbox"
                          className="ninja-forms-field nf-element"
                          value={formData.privacyCheckbox}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="consent-checkbox" style={{ width: "100%" }}>
              <div className="notification-desc">
                <input
                  id="consent-checkbox"
                  type="checkbox"
                  className="checkboxalim"
                  name="privacyCheckbox"
                  value={formData?.privacyCheckbox}
                  checked={formData?.privacyCheckbox}
                  onChange={handleFormChange}
                  onBlur={onBlurHandle}
                />
                <label for="consent-checkbox">
                  <div className="desc">
                    <span style={{ color: "red" }}>*</span> I consent to the
                    collection of the above data as described in the
                  </div>
                </label>
                <a
                  href="/custom-pages/privacy-policy"
                  target="_blank"
                  rel="noreferrer">
                  privacy policy
                </a>
              </div>
              {errorMessage.privacyCheckbox && (
                <p className="err-msg">{errorMessage.privacyCheckbox}</p>
              )}
            </div>

            <div
              style={{ marginBottom: "10px" }}
              className="mission-container-Error">
              <div>
                {ccpaData?.message && (
                  <p
                    className="success-msg"
                    style={{
                      color: ccpaData?.status === "success" ? "green" : "red"
                    }}>
                    {ccpaData?.message}
                  </p>
                )}
                {ccpaDataError && (
                  <p style={{ color: "red" }}>{ccpaDataError}</p>
                )}
              </div>
            </div>
            <div className="nf-field-element">
              <input
                style={{
                  cursor: ccpaDataLoading ? "not-allowed" : "pointer"
                }}
                id="nf-field-61"
                className="ninja-forms-field nf-element-submit "
                type="submit"
                disabled={ccpaDataLoading}
                value={ccpaDataLoading ? "Submitting..." : "Submit"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PrivacyRequestorm;

import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function ProgressBarReward({ lmRewardData }) {
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  return (
    <div className="reward-main-wrap">
      <div className="reward-box">
        <div className="reward-img">
          <img src={lmRewardData?.image_url} alt="product_image" />
        </div>
        <div className="reward-content-wrapper">
          <div className="reward-subtitle">{lmRewardData?.RewardTitle}</div>
          <div className="reward-title-btn">
            <div className="reward-title">{lmRewardData?.title}</div>
            <div className="reward-btn">
              <Link
                to={isUserLoggedIn ? "/rewardsdetails" : "/login"}
                state={{ lmRewardId: lmRewardData?.lm_reward_id }}
              >
                view details
              </Link>
            </div>
          </div>
          <div className="reward-range-bar">
            <div className="progress-bar">
              <div className="progress-bar-line"></div>
            </div>
            <div className="reward-range">
              <span>$0</span>
              <span>$400</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBarReward;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CloseIcon from "assets/icons/close.svg";
import { removeSearchTags } from "redux/actions";

import "./breadcrumb-detailed.scss";

const BreadcrumbDetailed = ({ breadcrumbList, title }) => {
  const dispatch = useDispatch();
  const { searchText } = useSelector(({ search }) => search);

  const onRemoveSearchTags = (tag) => {
    dispatch(removeSearchTags(tag));
  };

  return (
    <div>
      <div className="breadcrumb-detailed-section">
        <div className="breadcrumb-container">
          <div className="text-alignment">
            <div className="breadcrumb-section">
              {title && <h1>{(title = "")} </h1>}

              <div className="breadcrumb-alignment">
                <ul>
                  <li>
                    <i className="fa-solid fa-house"></i>
                    <Link to="/">Home</Link>
                  </li>
                  {breadcrumbList.map((breadcrumb, index) => {
                    return (
                      <React.Fragment key={index}>
                        <li>
                          <i className="fa-solid fa-chevron-right"></i>
                        </li>
                        <li>
                          <Link to={`${breadcrumb.link}`}>
                            {breadcrumb.lable}
                          </Link>
                        </li>
                      </React.Fragment>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="selected-child">
              {searchText &&
                searchText.split(" ").map((tag, index) => {
                  return (
                    <button key={index}>
                      <img
                        onClick={() => onRemoveSearchTags(tag)}
                        src={CloseIcon}
                        alt="CloseIcon"
                      />
                      <span>{tag}</span>
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BreadcrumbDetailed;

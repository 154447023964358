import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ListItems from "./ListItems";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import AddItemDropdown from "./AddItemDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getListDetails,
  addOrAddAllProductToCart,
  productMarkUncompleted,
  getCartList,
  productMarkCompleteAll,
} from "redux/actions";
import "./customlist-items.scss";
// import { List } from "reactstrap";
import { getLocalStorageValue } from 'config/helper';
import loader from "assets/images/loader.gif";

const ItemList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");

  const { currentStoreID } = useSelector(({ store }) => store);

  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const { customerListDetails, customerListName, ListLoading } = useSelector(
    ({ customlist }) => customlist
  );
  const {
    ecom_section: { allow_ecommerce = "" },
  } = useSelector(({ common }) => common.commonDetailsData);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      list_id: id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      PageNo: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getListDetails(body));
  }, [
    dispatch,
    clientId,
    memberNumber,
    userToken,
    isUserLoggedIn,
    storeId,
    id,
  ]);

  const breadcrumbList = [{ lable: "List Items", link: "" }];

  // const handleClickClearCart = () => {
  //   setShowClearCartConfirmModal(true);
  // const body = {
  //   RSAClientId: clientId,
  //   ClientStoreId: +storeId || 1,
  //   cart_id: customerListDetails[0] && customerListDetails[0].cart_id,
  //   member_number: memberNumber,
  //   user_token: userToken,
  //   AppName: "shop",
  //   DeviceType: "web",
  //   Version: "1",
  // };

  // dispatch(clearCartList(body));
  // };

  const unCompletedItem =
    customerListDetails && customerListDetails.length
      ? customerListDetails.filter((product) => product.is_complete === "0")
      : [];
  const completedItem =
    customerListDetails && customerListDetails.length
      ? customerListDetails.filter((product) => product.is_complete === "1")
      : [];

  const handleAddAllItemToCart = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      AppName: isUserLoggedIn ? "shop" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      list_id: id,
      items_data: unCompletedItem.map((product) => {
        return { product_id: product.product_id, qty: product.qty };
      }),
    };
    isUserLoggedIn &&
      dispatch(addOrAddAllProductToCart(body)).then((res) => {
        const body = {
          RSAClientId: clientId,
          ClientStoreId: +storeId || 1,
          AppName: "shop",
          member_number: memberNumber,
          user_token: userToken,
          Version: "1",
          DeviceType: "web",
        };
        isUserLoggedIn && dispatch(getCartList(body));
      });
  };

  const handleClickUncompleteAll = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      list_id: id,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      AppName: isUserLoggedIn ? "shop" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      items_data: completedItem.map((product) => {
        return { product_id: product.product_id };
      }),
    };
    dispatch(productMarkUncompleted(body));
  };

  const handleClickCompleteAll = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      list_id: id,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      AppName: isUserLoggedIn ? "shop" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      items_data: unCompletedItem.map((product) => {
        return { product_id: product.product_id };
      }),
    };
    dispatch(productMarkCompleteAll(body));
  };

  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={`List Items`} />

      <div className="container">
        <div className="custom-list-cart-section-align">
          <div className="cart-title">
            <h1>{customerListName && customerListName}</h1>
          </div>
          <div className="page-grid-align">
            {!ListLoading && customerListName ? (
              <div>
                <p>
                  There are {customerListDetails && customerListDetails.length}
                  {!customerListDetails && "0"} products in your list
                </p>
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ width: '100px', height: '50px' }} src={loader} />
                <span>Loading, please wait....</span>
              </div>
            )}
            <div className="action-buttons">
              <AddItemDropdown />

              {unCompletedItem && unCompletedItem.length === 0 ? (
                <div className="add-list-button">
                  <button onClick={handleClickUncompleteAll}>
                    <span>Uncomplete All</span>
                  </button>
                </div>
              ) : (
                <div className="add-list-button">
                  <button onClick={handleClickCompleteAll}>
                    <span>Complete all items</span>
                  </button>
                </div>
              )}
              {allow_ecommerce === "1" && unCompletedItem.length > 0 && (
                <div className="add-list-button">
                  <button onClick={handleAddAllItemToCart}>
                    <span>Add all Items to cart</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="custom-list-cart-section">
          <div className="cart-grid">
            <div className="cart-grid-items">
              <ListItems list_id={id} customerListDetails={unCompletedItem} />
            </div>
          </div>
        </div>
      </div>
      <div className="container your-cart-section-align">
        <div className="sub-cart-title">
          <h1>Completed Items</h1>
        </div>
      </div>
      <div className="container">
        <div className="custom-list-cart-section">
          <div className="cart-grid">
            <div className="cart-grid-items">
              <ListItems
                list_id={id}
                isCompletedGrid={true}
                customerListDetails={completedItem}
              />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default ItemList;

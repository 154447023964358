import React, { useEffect, useState } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import CustomProvideSection from "./CustomProviderSection";
import { getCustomPage } from "redux/actions";
import { useParams } from "react-router-dom";
import { getLocalStorageValue } from 'config/helper';
import loader from "assets/images/loader.gif";
const CustomPages = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const clientId = getLocalStorageValue("RSAclient-id");
  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      slug: slug
    };
    dispatch(getCustomPage(body));
  }, [slug, dispatch]); // eslint-disable-line

  const pageData = useSelector(({ customPage }) => customPage);

  const breadcrumbList = [{ lable: pageData.customPageData.title, link: "", searchTag: "" }];

  return (
    <div>
      {(!pageData.fetchCustomPageLoading && Object.keys(pageData.customPageData).length > 0) ? (
        <>
          <BreadcrumbDetailed
            breadcrumbList={breadcrumbList}
            title={pageData.customPageData.title} //Need to pass title through api res
          />
          <CustomProvideSection pageData={pageData.customPageData} />
        </>
      ) : (<>
        <div style={{ display: 'flex', alignItems: 'center' ,  justifyContent: "center" }}>
          <img style={{ width: '100px', height: '50px' }} src={loader} />
          <span style={{ marginLeft: "10px" }}>Loading, please wait....</span>
        </div>
      </>)}
    </div>
  );
};
export default CustomPages;

import React from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import WeeklyAdsDigitalPage from "./WeeklyAdsDigitalPage";

const WeeklyAdDigital = () => {
  const breadcrumbList = [
    { lable: "Weekly Ads Digital", link: "", searchTag: "" },
  ];

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={"Weekly Ads Digital"}
      />
      <WeeklyAdsDigitalPage />
    </>
  );
};
export default WeeklyAdDigital;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import ImageCard from "routes/home/CouponListSlider/ImageCard";
import { getLocalStorageValue } from "config/helper";
import "./whats-onsale-coupon.scss";
import { Link } from "react-router-dom";

const WhatsOnSaleCoupon = ({ whatsOnSaleCouponList, whatsOnsaleTitle }) => {
  const { storeListData } = useSelector(({ store }) => store);
  const selectedStore =
    storeListData &&
    storeListData.find(
      (store) =>
        store.ClientStoreId === getLocalStorageValue("selected-store-id")
    );

  const { ClientStoreName = "", ClientStoreId = "" } = selectedStore || "";

  return (
    <>
      <div className="new-sale-product new-sale-product-theme2">
        <div className="container">
          <div className="container-fluid">
            <div className="deals-product">
              <div className="heading">
                <h2>{whatsOnsaleTitle}</h2>
                <Link to={`/weekly-ads/${ClientStoreId}/${ClientStoreName}`}>
                  View All
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="M8.6 3.4L14.2 9H2v2h12.2l-5.6 5.6L10 18l8-8l-8-8z"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="product-row deal-all-wrapper">
              <div className="new-sale-all-wrapper">
                {whatsOnSaleCouponList &&
                  whatsOnSaleCouponList.slice(0, 5).map((coupon) => {
                    return (
                      <ImageCard
                        image={coupon}
                        key={coupon.page_number}
                        clientStoreName={ClientStoreName}
                        clientStoreId={ClientStoreId}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsOnSaleCoupon;

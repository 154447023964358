import React, { useState } from "react";
import { useRef } from "react";
import DropdownListItemTheme2 from "./DropdownListItemTheme2";
import downIcon from "assets/icons/down-black.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import "./city-dropdown-theme2.scss";


const CityDropdownTheme2 = ({
  onChange,
  icon,
  value,
  lable,
  defaultValue,
  placeholder,
  optionData,
}) => {
  const refDropdown = useRef();
  const [isShowDropdownOption, setIsShowDropdownOption] = useState(false);

  useOnClickOutside(refDropdown, () => setIsShowDropdownOption(false));

  const [selectedOptionValue, setselectedOptionValue] = useState(defaultValue);

  const handleChange = (value, title) => {
    setselectedOptionValue(value);
    onChange(value, title);
  };

  const cityKeys = Object.keys(optionData);

  return (
    <div className="city-item-theme2">
      <div className="show-items" ref={refDropdown}>
        <button onClick={() => setIsShowDropdownOption(!isShowDropdownOption)}>
          {icon && <img src={icon} alt="Icon" />}
          {placeholder && !value ? (
            placeholder
          ) : (
            <span>{`${lable} ${value}`}</span>
          )}

          <img src={downIcon} alt="downIcon" />
        </button>

        <div
          className={
            isShowDropdownOption
              ? "dropdown-align dropdown-show"
              : "dropdown-align dropdown-hidden"
          }
        >
          <div className="list-menu">
            <ul>
              {cityKeys.map((dropdownOption, index) => {
                return (
                  <DropdownListItemTheme2
                    optionName={dropdownOption}
                    // optionValue={dropdownOption.value}
                    onSelectOption={handleChange}
                    key={index}
                    onClose={() => setIsShowDropdownOption(false)}
                    selectedOptionValue={selectedOptionValue}
                    optionData={optionData}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityDropdownTheme2;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick/lib/slider";
import ProductCardTheme2 from "components/ProductCardTheme2";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import "./featured-products.scss";

const FeaturedProducts = () => {
  const offersFeaturedProductData = useSelector(
    ({ homepage }) => homepage.offersWithProductData
  );

  const [featuredProductSlug, setFeaturedProductSlug] =
    useState("featured-products");

  const featuredProductList =
    offersFeaturedProductData?.find(
      (offer) => offer.section_slug === featuredProductSlug
    )?.products_list || [];

  const featuredProducttitle =
    offersFeaturedProductData?.find(
      (offer) => offer.section_slug === featuredProductSlug
    )?.title || "";

  useEffect(() => {
    offersFeaturedProductData?.length &&
      setFeaturedProductSlug(featuredProductSlug);
  }, [offersFeaturedProductData]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: Math.min(featureproductData?.products_list.length, 6),
    slidesToShow: 5,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          // infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          // infinite: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          // infinite: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          // infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="featured-product-theme2">
        <div className="container">
          <div className="container-fluid">
            {featuredProductList?.length > 0 && (
              <div className="deals-product">
                <div className="heading">
                  <h2>{featuredProducttitle}</h2>
                  {/* <Link
                  to={{ pathname: "/search/products" }}
                  state={{ coupondata: "featured" }}
                >
                  View All{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="M8.6 3.4L14.2 9H2v2h12.2l-5.6 5.6L10 18l8-8l-8-8z"
                    />
                  </svg>
                </Link> */}
                </div>
              </div>
            )}

            <div className="product-row deal-all-wrapper">
              <div className="features-all-wrapper">
                <Slider {...settings}>
                  {featuredProductList.slice(0, 6).map((product) => {
                    return (
                      <ProductCardTheme2 product={product} key={product.id} />
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedProducts;

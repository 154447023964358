import React from "react";
import Maintanence from "./../../assets/gif/Maintanence.gif";

function Maintenance() {
  return (
    <div>
      <img
        src={Maintanence}
        alt="under Maintanence"
        style={{ width: "100vw", height: "99.2vh" }}
      />
    </div>
  );
}

export default Maintenance;

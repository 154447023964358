import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import ProductImage from "../../../assets/images/preview-product.png";
import "./reward-view-item.scss";
import RewardProductItem from "./RewardProductItem";
import { useDispatch, useSelector } from "react-redux";
import { getGetUPCTierProductsDetails } from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import { useLocation } from "react-router-dom";

const RewardViewItem = () => {
  const breadcrumbList = [
    { lable: "Reward Details View", link: "", searchTag: "" },
  ];
  const dispatch = useDispatch();
  const location = useLocation();

  const { LM_Reward_ID, LM_Reward_Tier_ID, eachRewardTier } = location.state;

  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const memberNumber = getLocalStorageValue("member-number");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");
  const { RewardTierProducts } = useSelector(
    ({ reward }) => reward?.UpcTierProductsData
  );

  useEffect(() => {
    let body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      member_number: isUserLoggedIn ? memberNumber : "",
      lm_reward_id: LM_Reward_ID,
      LMRewardTierID: LM_Reward_Tier_ID,
      user_token: isUserLoggedIn ? userToken : "",
      AppName: isUserLoggedIn ? "shop" : "",
    };
    dispatch(getGetUPCTierProductsDetails(body));
  }, [
    LM_Reward_ID,
    LM_Reward_Tier_ID,
    clientId,
    dispatch,
    isUserLoggedIn,
    memberNumber,
    storeId,
  ]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={`Reward Details View`}
      />
      <div className="reward-view-main-wrap">
        <div className="container">
          <div className="reward-view-box">
            <div className="reward-view-img">
              <img src={eachRewardTier?.TierImageUrl} alt="product_image" />
            </div>
            <div className="details">
              <div className="reward-view-title">
                {eachRewardTier?.TierTitle}{" "}
              </div>
              <div className="view-reward-point">
                <span>{eachRewardTier?.PointsRequiredString}</span>
              </div>
              {/* <div class="reward-btn redeem-btn"><a href="/rewardsdetails">Redeem now</a></div> */}
            </div>
          </div>
        </div>
        <div className="details-reward-main-wrap">
          <div className="container">
            <div className="details-reward-items">
              <div className="reward-items-wrap">
                <div class="heading">
                  <h2>Qualified Items</h2>
                </div>
                <div className="items">
                  <Slider {...settings}>
                    {RewardTierProducts?.map((rewardProductItem) => {
                      return (
                        <RewardProductItem
                          rewardProductItem={rewardProductItem}
                        />
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardViewItem;

import React from "react";
import CampaignProductCard from "components/CampaignProductCard";

import '../CampaignProductListSliderTheme2/CampaignProductCardTheme2/campaign-product-theme2.scss';

const Campaigner = ({ departmentproductData, index }) => {
  return (
    <section className="campaign-product-list-theme2 container" key={index}>
      {departmentproductData.map((productItem) => {
        return (
          <>
            <div className="campaign-department-title" key={productItem.order_by}>
              <h1>{productItem.name}</h1>
            </div>
            <div className="campaign-card-list">
              {productItem.items.map((product) => {
                return <CampaignProductCard product={product} addButtonText={"Add to Cart"} key={product.product_id} />
              })}
            </div>
          </>
        )
      })}
    </section>

  );
};
export default Campaigner;

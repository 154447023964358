import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getLocalStorageValue } from 'config/helper';

import { resetSearch } from "redux/actions";
import "./details-theme2.scss";
import { getStoreDetailsHistory } from "redux/actions";

const DetailsTheme2 = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const { storDetailsData } = useSelector(({ storehistory }) => storehistory);
  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
    };
    dispatch(getStoreDetailsHistory(body));
  }, [dispatch, storeId]); // eslint-disable-line

  return (
    <>
      <div className="container">
        {storDetailsData &&
          storDetailsData.length > 0 &&
          storDetailsData.map((storeDetail, index) => {
            return (
              <div className="grid-flex-detail" key={index}>
                <div
                  className="col col-image location-img-responsive"
                  style={{ backgroundImage: `url(${storeDetail.image})` }}
                ></div>
                <div
                  className={`col col-text-detail ${index % 2 === 1 ? "col-left" : ""
                    }`}
                >
                  <div className="Align-item">
                    <h1>{storeDetail.history_title}</h1>
                    <p>{storeDetail.short_description}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
export default DetailsTheme2;

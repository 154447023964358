import React, { useEffect } from "react";
import ProductImage from "../../assets/images/preview-product.png";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import "./reward-point-base.scss";
import { useDispatch, useSelector } from "react-redux";
import LoyaltyReward from "./loyaltyreward";
import FreeReward from "./freereward";
import RewardBanner from "./rewardbanner";
import { getRewardV2Details } from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import { Link } from "react-router-dom";
import ProgressBarReward from "./progressbarreward";
import SpendReward from "routes/SpendReward";

const RewardPointBase = () => {
  const breadcrumbList = [{ lable: "Rewards", link: "", searchTag: "" }];
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const { rewardV2Data } = useSelector(({ reward }) => reward);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");

  useEffect(() => {
    let body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      user_token: userToken,
    };
    dispatch(getRewardV2Details(body));
  }, [clientId, dispatch, storeId]);

  // console.log("rewardV2Data", rewardV2Data);

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={"Rewards"} //Need to pass title through api res
      />
      <div className="reward-banner-wrap">
        <div className="container">
          <div className="reward-banner-content">
            <div className="reward-left-area">
              {rewardV2Data?.lm_rewards?.map((lmRewardData, i) => {
                if (lmRewardData?.reward_type_id === 1) {
                  return <FreeReward lmRewardData={lmRewardData} />;
                } else {
                  if (lmRewardData?.is_points_based) {
                    return <LoyaltyReward lmRewardData={lmRewardData} />;
                  } else {
                    return <SpendReward lmRewardData={lmRewardData} />;
                  }
                }
              })}
            </div>

            <div className="reward-right-area">{/* <RewardBanner /> */}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardPointBase;

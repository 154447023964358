import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "components/Common/Dropdown";
import ReactHtmlParser from "components/Common/ReactHtmlParser";
import {
  getCartAmountDetails,
  getSavePlaceOrder,
  checkoutOrderPreferredContactType,
  checkoutOrderPreferredContact,
  getAvailableCoupons,
  getAppliedAvailableCoupons,
  getSavePlaceOrderCateringAndMeals,
  removeEbtPayment,
  getOrderDetail,
  updateExternalDeliveryId,
  getCateringAndMealkitCartAmountDetails,
  checkoutOrderDoorDashPreferredContactLess,
} from "redux/actions";
import { Link, useNavigate, useParams } from "react-router-dom";
import PaymentModal from "./PaymentModal";
import PaymentMethod from "./PaymentMethod";
import EBTPaymentGateway from "./EBTPaymentGateway";
import "./checkout-information.scss";
import { getLocalStorageValue } from "config/helper";

const formatDate = (date, format) => {
  const map = {
    mm: ("0" + (date.getMonth() + 1)).slice(-2),
    dd: ("0" + date.getDate()).slice(-2),
    yy: date.getFullYear().toString().slice(-2),
    yyyy: date.getFullYear(),
    hh: ("0" + date.getHours()).slice(-2),
    m: ("0" + date.getMinutes()).slice(-2),
    s: ("0" + date.getSeconds()).slice(-2),
  };
  return format.replace(/mm|dd|yy|yyy|hh|m|s/gi, (matched) => map[matched]);
};
const CheckoutInformation = () => {
  const dispatch = useDispatch();
  const { checkoutType } = useParams();
  const navigate = useNavigate();
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const mobileNumber = getLocalStorageValue("mobile-number");
  const clientId = getLocalStorageValue("RSAclient-id");
  const lastNewOrderId = getLocalStorageValue("last_new_order_id");

  const { currentStoreID } = useSelector(({ store }) => store);
  const { mobile_number = "" } = useSelector(
    ({ customer }) => customer.customerlistData || {}
  );
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const [selectDropDownedOption, setSelectDropDownedOption] =
    useState("Call me");

  const [selectDoorDashContactLessDropDownedOption, setSelectDoorDashContactLessDropDownedOption] =
    useState("Hand it to me");

  const [contactInput, setContactInput] = useState("");
  const [isShowPaymentPopup, setPaymentPopup] = useState(false);
  const [isShowEbtPaymentPopup, setShowEbtPaymentPopup] = useState(false);
  const [ebtPaymentResponse, setEbtPaymentResponse] = useState(null);
  const [paymentRequestBody, setPaymentRequestBody] = useState(null);
  const [isShowCouponsDropdown, setShowCouponsDropdown] = useState(true);
  const [isSelectCoupon, setSelectCoupon] = useState(false);
  const [isCurbsidePickup, setCurbsidePickup] = useState(false);
  const [curbsidePickupComment, setCurbsidePickupComment] = useState("");
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [storeConfirmation, setStoreConfirmation] = useState(false);
  const [isShowCouponSelectWarning, setShowCouponSelectWarning] =
    useState(false);
  const [dropOffInstructions, setDropOffInstructions] = useState("");
  const [tipAmount, setTipAmount] = useState(0);
  const [tpdAllowed, setTpdAllowed] = useState(false);
  const {
    cartAmountDetails,
    checkoutPaymentMethod,
    checkoutStoreType,
    checkoutPickupType,
    checkoutInstruction,
    checkoutContactType,
    checkoutContact,
    customerAddress,
    savePlaceOrderLoading,
    savePlaceOrderError,
    ebtPaymentPaidList,
    cancelPaidEbtEntryLoading,
    isFollowedEbtPayment,
    ebtPaymentResponse: ebtPaidPaymentResponse,
    checkoutTimeSlot: {
      shipping_day = "",
      shipping_date = "",
      shipping_time = "",
    },
    externalDeliveryId,
    cartAmountDetailsLoading,

    cateringAndMealkitCartAmountDetailsLoading,
    cateringAndMealkitCartAmountDetails,
    cateringAndMealkitCartAmountDetailsError,
    doorDashContactLessDropDownOption

  } = useSelector(({ checkout }) => checkout);
  const { storeDetails, storeListData } = useSelector(({ store }) => store);
  const { availableCoupons } = useSelector(({ coupon }) => coupon);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { productCartList, cateringAndMealKitProductCartList } = useSelector(
    ({ cartWishlist }) => cartWishlist
  );

  const { last_new_order_id = "" } =
    (ebtPaidPaymentResponse && ebtPaidPaymentResponse.data) || {};

  const lastOrderedId = last_new_order_id || lastNewOrderId || "";
  const {
    mealkit_asap_time = "0",
    payment_gateway,
    checkout_catering_pricing_notes,
    checkout_mealkit_pricing_notes,
    third_party_delivery_option
  } = storeDetails || {};

  const contactType =
    clientId == 13
      ? [{ title: "Call me", value: "call_me" }]
      : [
        { title: "Call me", value: "call_me" },
        { title: "Text me", value: "text_me" },
      ];

  const doorDashContactLessDropOff = [
    { title: "Leave at door", value: true },
    { title: "Hand it to me", value: false }
  ];

  let cartId =
    productCartList && productCartList[0] && productCartList[0].cart_id;

  const cateringAndMealKitCartId =
    cateringAndMealKitProductCartList &&
    cateringAndMealKitProductCartList[0] &&
    cateringAndMealKitProductCartList[0].meal_cart_id;

  const { is_mealkit = "0" } = cateringAndMealKitProductCartList[0] || {};

  if (checkoutType === "catering-meal") {
    cartId = cateringAndMealKitCartId;
  }

  const {
    authorized_notes,
    ebt_notes,
    minimum_order_amount_message,
    remaining_amount,
    checkout_pricing_notes,
    age_restricted_notes,
    curbside_instruction_notes,
    is_curbside_pickup,
    data: { items_list = [], price_list = [] } = {},
    external_delivery_id,
    status,
    message
  } = cartAmountDetails || {};

  const cartItemPriceList =
    price_list &&
    price_list
      .filter((list) => list.is_visible === 1 && !list.separater)
      .sort((a, b) => a.sort_order - b.sort_order);

  const authorizedAmountObj =
    price_list &&
    price_list.find(
      (list) =>
        list.key_name ===
        (isFollowedEbtPayment
          ? "total_final_payable_amount"
          : "authorized_amount")
    );
  const selectedStore =
    storeListData &&
    storeListData.find(
      (store) =>
        store.ClientStoreId === getLocalStorageValue("selected-store-id")
    )

  // for catering and mealkit 
  const catering_and_mealkit_price_list = cateringAndMealkitCartAmountDetails && cateringAndMealkitCartAmountDetails?.data && cateringAndMealkitCartAmountDetails?.data?.price_list;

  const cateringAndMealkitCartItemPriceList =
    catering_and_mealkit_price_list &&
    catering_and_mealkit_price_list
      .filter((list) => list.is_visible === 1 && !list.separater)
      .sort((a, b) => a.sort_order - b.sort_order);

  /* eslint-disable react-hooks/rules-of-hooks */
  useEffect(() => {
    if (cartAmountDetailsLoading) {
      setTpdAllowed(false);
    }
  }, [cartAmountDetailsLoading]);


  /* eslint-disable react-hooks/rules-of-hooks */
  useEffect(() => {
    setContactInput(mobile_number ? mobile_number : mobileNumber);
    dispatch(
      checkoutOrderPreferredContact(
        mobile_number ? mobile_number : mobileNumber
      )
    );
  }, [dispatch, mobile_number, mobileNumber, checkoutType]);/* eslint-disable react-hooks/rules-of-hooks */



  useEffect(() => {

    if (checkoutType == 'catering-meal') {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        meal_cart_id: cartId,
        shipping_method: checkoutStoreType,
        payment_method: checkoutPaymentMethod,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
        last_new_combo_order_id: lastOrderedId
      };
      cartId &&
        checkoutStoreType &&
        checkoutPaymentMethod &&
        checkoutType == 'catering-meal' &&
        dispatch(getCateringAndMealkitCartAmountDetails(body));
    } else {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        cart_id: cartId,
        shipping_method: checkoutStoreType,
        payment_method: checkoutPaymentMethod,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
        last_new_combo_order_id: lastOrderedId
      };

      cartId &&
        checkoutStoreType &&
        checkoutPaymentMethod &&
        !checkoutType &&
        dispatch(getCartAmountDetails(body));
    }
  }, [
    dispatch,
    memberNumber,
    userToken,
    checkoutStoreType,
    checkoutPaymentMethod,
    cartId,
    productCartList.length,
    storeId,
    storeDetails,
    clientId,
    isUserLoggedIn,
    checkoutType,
  ]); /* eslint-disable react-hooks/rules-of-hooks */

  useEffect(() => {
    const { applied_coupons = [] } = availableCoupons || {};
    setSelectedCoupons(applied_coupons);
  }, [availableCoupons]); /* eslint-disable react-hooks/rules-of-hooks */

  useEffect(() => {
    if (!isFollowedEbtPayment && !ebtPaymentPaidList.length && lastOrderedId) {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
        last_new_order_id: lastOrderedId,
      };

      dispatch(getOrderDetail(body));
    }
  }, [dispatch]); /* eslint-disable react-hooks/rules-of-hooks */

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };

    !checkoutType && dispatch(getAvailableCoupons(body));
  }, [dispatch, memberNumber, userToken, storeId]); /* eslint-disable react-hooks/rules-of-hooks */

  useEffect(() => {
    if (isSelectCoupon) {
      const body = {
        applied_coupons: selectedCoupons,
        coupons: availableCoupons && availableCoupons.coupons,
        ClientStoreId: +storeId || 1,
        RSAClientId: clientId,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };

      dispatch(getAppliedAvailableCoupons(body)).then(() => {
        const body = {
          RSAClientId: clientId,
          ClientStoreId: +storeId || 1,
          cart_id: cartId,
          shipping_method: checkoutStoreType,
          payment_method: checkoutPaymentMethod,
          AppName: isUserLoggedIn ? "shop" : "",
          member_number: isUserLoggedIn ? memberNumber : "",
          user_token: isUserLoggedIn ? userToken : "",
          Version: isUserLoggedIn ? "1" : "",
          DeviceType: isUserLoggedIn ? "web" : "",
          last_new_combo_order_id: lastOrderedId
        };

        cartId &&
          checkoutStoreType &&
          checkoutPaymentMethod &&
          dispatch(getCartAmountDetails(body));
      });
    }
  }, [dispatch, selectedCoupons.length]); /* eslint-disable react-hooks/rules-of-hooks */

  useEffect(() => {
    if (!external_delivery_id) {
      setTpdAllowed(false);
    }
    dispatch(updateExternalDeliveryId(external_delivery_id));
  }, [dispatch, external_delivery_id]); /* eslint-disable react-hooks/rules-of-hooks */

  useEffect(() => {
    if (!externalDeliveryId) {
      setTpdAllowed(false);
    }
  }, [dispatch, externalDeliveryId]); /* eslint-disable react-hooks/rules-of-hooks */

  const placeOrder = () => {
    const body = {
      authorized_amount: authorizedAmountObj && authorizedAmountObj.amount,
      order_note: checkoutInstruction || "",
      shipping_day,
      shipping_date,
      shipping_time,
      shipping_address_id:
        checkoutStoreType === "store_pickup" ? "" : customerAddress,
      cart_id: cartId,
      is_curbside_pickup: "0",
      price_list: cartItemPriceList || [],
      order_perferred_mobile_number: checkoutContact || "",
      payment_method: checkoutPaymentMethod,
      RSAClientId: clientId,
      shipping_method: checkoutStoreType,
      question_reply_contact_method: checkoutContactType,
      transaction_code: 0,
      customer_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      customer_current_date_time: formatDate(new Date(), "yy-mm-dd hh:m:s"),
      billing_address_id:
        checkoutStoreType === "store_pickup" ? "" : customerAddress,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      curbside_pickup_notes: curbsidePickupComment || "",
      last_new_combo_order_id: lastOrderedId,
      external_delivery_id
    };

    if (availableCoupons?.coupons && !selectedCoupons.length) {
      setShowCouponSelectWarning(true);
      return;
    }

    if (checkoutPaymentMethod === "cc") {
      setPaymentPopup(true);
      setPaymentRequestBody(body);
    } else {
      checkoutContact &&
        (checkoutStoreType === "store_pickup" ? true : customerAddress) &&
        checkoutStoreType &&
        checkoutPaymentMethod &&
        dispatch(getSavePlaceOrder(body)).then((res) => {
          if (res.data.status !== "error") {
            res && navigate("/order-confirmation");
          }
        });
    }
  };

  const ebtPlaceOrder = () => {
    const body = {
      authorized_amount: authorizedAmountObj && authorizedAmountObj.amount,
      order_note: checkoutInstruction || "",
      shipping_day,
      shipping_date,
      shipping_time,
      shipping_address_id:
        checkoutStoreType === "store_pickup" ? "" : customerAddress,
      cart_id: cartId,
      is_curbside_pickup: "0",
      price_list: price_list || [],
      order_perferred_mobile_number: checkoutContact || "",
      payment_method: checkoutPaymentMethod,
      RSAClientId: clientId,
      shipping_method: checkoutStoreType,
      question_reply_contact_method: checkoutContactType,
      transaction_code: 0,
      customer_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      customer_current_date_time: formatDate(new Date(), "yy-mm-dd hh:m:s"),
      billing_address_id:
        checkoutStoreType === "store_pickup" ? "" : customerAddress,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      curbside_pickup_notes: curbsidePickupComment || "",
      redirection_base_url: window.location.hostname,
      last_new_combo_order_id: lastOrderedId,
      external_delivery_id
    };

    checkoutContact &&
      (checkoutStoreType === "store_pickup" ? true : customerAddress) &&
      checkoutStoreType &&
      checkoutPaymentMethod &&
      dispatch(getSavePlaceOrder(body)).then((res) => {
        if (res.data.status !== "error") {
          setEbtPaymentResponse(res.data);
          setPaymentRequestBody(body);
          setShowEbtPaymentPopup(true);
        }
      });
  };

  /* const cateringMealKitTotalAmount = cateringAndMealKitProductCartList.reduce(
    (acc, cur) => {
      return acc + +cur.row_total;
    },
    0
  ); */

  const cateringMealKitAuthorizeObj =
    cateringAndMealkitCartItemPriceList &&
    cateringAndMealkitCartItemPriceList.find(
      (list) =>
        list.key_name === "grand_total"
    );

  const cateringMealKitTotalAmount = cateringMealKitAuthorizeObj ? cateringMealKitAuthorizeObj?.amount : 0;

  const placeCateringMealOrder = () => {
    const body = {
      billing_address_id:
        checkoutStoreType === "store_pickup" ? "" : customerAddress,
      last_catering_or_mealkit_order_id: "",
      ClientStoreId: +storeId || 1,
      DeviceType: isUserLoggedIn ? "web" : "",
      grand_total: cateringMealKitTotalAmount,
      price_list: cateringAndMealkitCartItemPriceList,
      meal_cart_id: cartId,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      order_note: checkoutInstruction || "",
      order_perferred_mobile_number: checkoutContact || "",
      order_total: cateringMealKitTotalAmount,
      payment_type: payment_gateway,
      payment_method: checkoutPaymentMethod,
      question_reply_contact_method: checkoutContactType,
      RSAClientId: clientId,
      shipping_date:
        checkoutPickupType === "asap"
          ? moment().format("YYYY-MM-DD")
          : shipping_date,
      shipping_day:
        checkoutPickupType === "asap" ? moment().format("dddd") : shipping_day,
      shipping_method: checkoutStoreType,
      shipping_time:
        checkoutPickupType === "asap"
          ? `${moment().format("hh:mm A")} - ${moment()
            .add(+mealkit_asap_time, "minutes")
            .format("hh:mm A")}`
          : shipping_time,
      shipping_timing_method: checkoutPickupType,
      sub_total: cateringMealKitTotalAmount,
      total_amount: cateringMealKitTotalAmount,
      AppName: isUserLoggedIn ? "shop" : "",
      Version: isUserLoggedIn ? "1" : "",
    };

    if (checkoutPaymentMethod === "cc") {
      setPaymentPopup(true);
      setPaymentRequestBody(body);
    } else {
      checkoutContact &&
        (checkoutStoreType === "store_pickup" ? true : customerAddress) &&
        checkoutStoreType &&
        checkoutPaymentMethod &&
        dispatch(getSavePlaceOrderCateringAndMeals(body)).then(() => {
          navigate("/order-confirmation");
        });
    }
  };

  const handleChangeDropDownOption = (value, name) => {
    setSelectDropDownedOption(name);
    dispatch(checkoutOrderPreferredContactType(value));
  };

  const handleChangeDoorDashContactLessDropDownOption = (value, name) => {
    setSelectDoorDashContactLessDropDownedOption(name);
    dispatch(checkoutOrderDoorDashPreferredContactLess(value));
    dispatch(updateExternalDeliveryId(""));
  };

  const handleContactInputChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setContactInput(value);
      dispatch(checkoutOrderPreferredContact(value));
    }
  };

  const handleDropOffInstructions = (e) => {
    const { value } = e.target;
    setDropOffInstructions(value);
  };

  const handleTipAmount = (e) => {
    const { value } = e.target;
    setTipAmount(value);
  };

  const handleConfirmDelivery = (e) => {
    if (e.target.checked) {
      setTpdAllowed(true);
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        cart_id: cartId,
        shipping_method: checkoutStoreType,
        payment_method: checkoutPaymentMethod,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
        last_new_combo_order_id: lastOrderedId,
        shipping_date,
        shipping_time,
        shipping_address_id: checkoutStoreType === "store_pickup" ? "" : customerAddress,
        order_note: checkoutInstruction || "",
        dropoff_instructions: dropOffInstructions,
        tip_amount: parseFloat(tipAmount),
        third_party_delivery_option_allowed: 1,
        order_perferred_mobile_number: checkoutContact || "",
        contactless_dropoff: doorDashContactLessDropDownOption || false
      };
      cartId &&
        checkoutStoreType &&
        checkoutPaymentMethod &&
        !checkoutType &&
        dispatch(getCartAmountDetails(body));
    }
    else {
      setTpdAllowed(false);
    }
  };

  const handleCouponsChange = (e) => {
    const { value } = e.target;
    setSelectCoupon(true);
    if (selectedCoupons.includes(value)) {
      const updatedSelectedCoupons = selectedCoupons.filter(
        (coupon) => coupon !== value
      );
      setSelectedCoupons([...updatedSelectedCoupons]);
    } else {
      setSelectedCoupons([...selectedCoupons, value]);
    }
  };

  const handleCurbsidePickupComment = (e) => {
    const { value } = e.target;
    setCurbsidePickupComment(value);
  };

  const handleCancelEbtPayment = (data) => {
    const { sop_id } = data;

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      last_new_order_id: lastOrderedId,
      sop_id,
      payment_type: "ebt",
    };

    dispatch(removeEbtPayment(body));
  };
  const yourCartURL = checkoutType
    ? `/your-cart/${checkoutType}`
    : "/your-cart";

  const isShowEditCart = checkoutType
    ? cateringAndMealKitProductCartList &&
    cateringAndMealKitProductCartList.length
    : productCartList && productCartList.length;

  const isEbtProductAvailable = productCartList.some(
    (product) => product.ebt_item === "1"
  );

  return (
    <div className="checkout-information-wrapper">
      {cartAmountDetails && (
        <div className="checkout-information">
          <div className="box-heading">
            <h1>Store: {selectedStore && selectedStore.ClientStoreName} </h1>
            {!!isShowEditCart && <Link to={yourCartURL}> Edit cart</Link>}
          </div>

          {/*{items_list &&
            items_list.map((list) => {
              return (
                <div className="all-text-alignment" key={list.item_id}>
                  <div>
                    <p>{list.name}</p>
                  </div>
                  <div>
                    <span>${list.price}</span>
                  </div>
                </div>
              );
            })}
             */}
          {/* grocery */}

          {cartItemPriceList &&
            !checkoutType &&
            cartItemPriceList.map((list) => {
              if (list.key_name === "discount_total") {
                return (
                  <div
                    className={`discount-coupon-aligment ${list.amount === "0.00" &&
                      (!availableCoupons ||
                        availableCoupons?.coupons?.length === 0)
                      ? "hidden"
                      : ""
                      }`}
                    key={list.sort_order}
                  >
                    {list.amount !== "0.00" && (
                      <div className="all-text-alignment">
                        <div>
                          <p>{list.label}</p>
                        </div>
                        <div>
                          <h2>${list.amount}</h2>
                        </div>
                      </div>
                    )}
                    {/* ticket no:1726 , Date:16 feb 2023, removed dropdown in show coupons - Navina K */}
                    {availableCoupons && !!availableCoupons.coupons.length && (
                      <div className="coupon-dropdown-wrapper">
                        <div className="coupon-dropdown">
                          <span
                            style={{ fontWeight: "bold" }}
                          // onClick={() =>
                          //   setShowCouponsDropdown(!isShowCouponsDropdown)
                          // }
                          >
                            Add available coupons &nbsp; &nbsp;{" "}
                            {/* {isShowCouponsDropdown ? (
                              <i className="fa-solid fa-angle-up"></i>
                            ) : (
                              <i className="fa-solid fa-angle-down"></i>
                            )} */}
                          </span>
                        </div>

                        {isShowCouponsDropdown && (
                          <div
                            className={
                              isShowCouponsDropdown
                                ? "coupon-dropdown-list coupon-dropdown-show"
                                : "coupon-dropdown-list coupon-dropdown-hidden"
                            }
                          >
                            <div className="coupon-dropdown-helper-text">
                              *check to apply
                            </div>
                            {availableCoupons &&
                              availableCoupons.coupons.map((coupon) => {
                                const { CouponId, Title } = coupon;
                                return (
                                  <div
                                    className="coupon-dropdown-item"
                                    key={CouponId}
                                  >
                                    <input
                                      type="checkbox"
                                      id={CouponId}
                                      onChange={handleCouponsChange}
                                      value={CouponId}
                                      checked={selectedCoupons.includes(
                                        CouponId.toString()
                                      )}
                                      disabled={selectedCoupons.includes(
                                        CouponId.toString()
                                      )}
                                    />
                                    <label htmlFor={CouponId}>
                                      <span> &nbsp;{Title}</span>
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              }
              if (list.key_name === "item_total") {
                return (
                  <div className="all-text-alignment" key={list.sort_order}>
                    <div>
                      <p
                        style={
                          list.key_name === "item_total"
                            ? { paddingLeft: "5.5rem" }
                            : {}
                        }
                      >
                        {list.label}
                        {/* ( {items_list.length} item ) */}
                      </p>
                    </div>
                    <div>
                      <p>${list.amount}</p>
                    </div>
                  </div>
                );
              }
              if (
                (list.key_name === "order_fee_amount" ||
                  list.key_name === "cost_plus_fees_amount" ||
                  list.key_name === "discount_total") &&
                list.amount === "0.00"
              ) {
                return "";
              }
              return (
                <div
                  style={
                    list.separater === "line"
                      ? { borderBottom: "4px solid green" }
                      : {}
                  }
                >
                  <div
                    className="all-text-alignment"
                    key={list.sort_order}
                    style={list.separater === "line" ? { display: "none" } : {}}
                  >
                    <div>
                      <p
                        style={
                          {
                            fontWeight: list.style === "bold" ? "bold" : "",
                            fontStyle: list.style === "italic" ? "italic" : "",
                            paddingLeft:
                              list.key_name === "ebt_payment_completed"
                                ? "15rem"
                                : list.key_name ===
                                  "balance_to_pay_with_credit_card"
                                  ? "12rem"
                                  : list.key_name === "total_ebt_amount" ||
                                    list.key_name === "non_ebt_total_amount"
                                    ? ""
                                    : list.key_name === "grand_total"
                                      ? ""
                                      : "5.5rem",
                            // textAlign:
                            //   list.key_name === "ebt_payment_completed" ||
                            //   list.key_name ===
                            //     "balance_to_pay_with_credit_card"
                            //     ? "right"
                            //     : "",
                          }
                          // list.style === "bold"
                          //   ? { fontWeight: "bold" }
                          //   : list.style === "italic"
                          //   ? { fontStyle: "italic", paddingLeft: "5.5rem" }
                          //   : list.key_name === "grand_total"
                          //   ? {}
                          //   : { paddingLeft: "5.5rem" }
                        }
                      >
                        {list.key_name === "grand_total" ||
                          list.key_name ===
                          "ebt_items_amount_due_to_be_paid_with_cc" ? (
                          <strong>{list.label}</strong>
                        ) : list.key_name === "ebt_payment_completed" ? (
                          <>
                            {list.label}
                            <b
                              style={{
                                color:
                                  list.key_name === "ebt_payment_completed"
                                    ? "red"
                                    : "",
                                marginLeft: "5px",
                              }}
                            >
                              (${list.amount})
                            </b>
                          </>
                        ) : list.key_name ===
                          "balance_to_pay_with_credit_card" ? (
                          <>
                            {list.label} <b>&nbsp; ${list.amount}</b>
                          </>
                        ) : (
                          list.label
                        )}
                      </p>
                    </div>

                    <div>
                      {list.style === "bold" ? (
                        <strong
                          style={
                            list.style === "bold"
                              ? { fontWeight: "bold", fontSize: "1.3rem" }
                              : list.style === "italic"
                                ? { fontStyle: "italic", fontSize: "1.3rem" }
                                : { fontSize: "1.3rem" }
                          }
                        >
                          {list.key_name === "ebt_payment_completed" ||
                            list.key_name === "balance_to_pay_with_credit_card"
                            ? " "
                            : `$${list.amount}`}{" "}
                        </strong>
                      ) : (
                        <p
                          style={
                            list.style === "bold" ||
                              list.key_name === "grand_total"
                              ? { fontWeight: "bold", fontSize: "1.3rem" }
                              : list.style === "italic"
                                ? { fontStyle: "italic" }
                                : {}
                          }
                        >
                          {list.key_name === "ebt_payment_completed" ||
                            list.key_name === "balance_to_pay_with_credit_card"
                            ? " "
                            : `$${list.amount}`}{" "}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

          {/* Catering and mealkit */}
          {cateringAndMealKitProductCartList &&
            !!cateringAndMealKitProductCartList.length &&
            checkoutType === "catering-meal" &&
            cateringAndMealKitProductCartList.map((list) => {
              return (
                <div className="all-text-alignment" key={list.sort_order}>
                  <div>
                    <p>{list.name}</p>
                  </div>
                  <div>
                    <h2>${list.row_total}</h2>
                  </div>
                </div>
              );
            })}

          {cateringAndMealkitCartItemPriceList &&
            checkoutType === 'catering-meal' &&
            cateringAndMealkitCartItemPriceList.map((list) => {
              if (list.key_name === "discount_total") {
                return (
                  <div
                    className={`discount-coupon-aligment ${list.amount === "0.00" &&
                      (!availableCoupons ||
                        availableCoupons?.coupons?.length === 0)
                      ? "hidden"
                      : ""
                      }`}
                    key={list.sort_order}
                  >
                    {list.amount !== "0.00" && (
                      <div className="all-text-alignment">
                        <div>
                          <p>{list.label}</p>
                        </div>
                        <div>
                          <h2>${list.amount}</h2>
                        </div>
                      </div>
                    )}
                    {/* ticket no:1726 , Date:16 feb 2023, removed dropdown in show coupons - Navina K */}
                    {availableCoupons && !!availableCoupons.coupons.length && (
                      <div className="coupon-dropdown-wrapper">
                        <div className="coupon-dropdown">
                          <span
                            style={{ fontWeight: "bold" }}
                          // onClick={() =>
                          //   setShowCouponsDropdown(!isShowCouponsDropdown)
                          // }
                          >
                            Add available coupons &nbsp; &nbsp;{" "}
                            {/* {isShowCouponsDropdown ? (
                              <i className="fa-solid fa-angle-up"></i>
                            ) : (
                              <i className="fa-solid fa-angle-down"></i>
                            )} */}
                          </span>
                        </div>

                        {isShowCouponsDropdown && (
                          <div
                            className={
                              isShowCouponsDropdown
                                ? "coupon-dropdown-list coupon-dropdown-show"
                                : "coupon-dropdown-list coupon-dropdown-hidden"
                            }
                          >
                            <div className="coupon-dropdown-helper-text">
                              *check to apply
                            </div>
                            {availableCoupons &&
                              availableCoupons.coupons.map((coupon) => {
                                const { CouponId, Title } = coupon;
                                return (
                                  <div
                                    className="coupon-dropdown-item"
                                    key={CouponId}
                                  >
                                    <input
                                      type="checkbox"
                                      id={CouponId}
                                      onChange={handleCouponsChange}
                                      value={CouponId}
                                      checked={selectedCoupons.includes(
                                        CouponId.toString()
                                      )}
                                      disabled={selectedCoupons.includes(
                                        CouponId.toString()
                                      )}
                                    />
                                    <label htmlFor={CouponId}>
                                      <span> &nbsp;{Title}</span>
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              }
              if (list.key_name === "item_total") {
                return (
                  <div className="all-text-alignment" key={list.sort_order} style={{ borderTop: "4px solid #000" }}>
                    <div>
                      <p
                        style={
                          list.key_name === "item_total"
                            ? { paddingLeft: "0rem" }
                            : {}
                        }
                      >
                        {list.label}
                      </p>
                    </div>
                    <div>
                      <p>${list.amount}</p>
                    </div>
                  </div>
                );
              }
              if (
                (list.key_name === "order_fee_amount" ||
                  list.key_name === "cost_plus_fees_amount" ||
                  list.key_name === "discount_total") &&
                list.amount === "0.00"
              ) {
                return "";
              }
              return (
                <div
                  style={
                    list.separater === "line"
                      ? { borderBottom: "4px solid green" }
                      : {}
                  }
                >
                  <div
                    className="all-text-alignment"
                    key={list.sort_order}
                    style={list.separater === "line" ? { display: "none" } : {}}
                  >
                    <div>
                      <p
                        style={
                          {
                            fontWeight: list.style === "bold" ? "bold" : "",
                            fontStyle: list.style === "italic" ? "italic" : "",
                            paddingLeft:
                              list.key_name === "ebt_payment_completed"
                                ? "15rem"
                                : list.key_name ===
                                  "balance_to_pay_with_credit_card"
                                  ? "12rem"
                                  : list.key_name === "total_ebt_amount" ||
                                    list.key_name === "non_ebt_total_amount"
                                    ? ""
                                    : list.key_name === "grand_total"
                                      ? ""
                                      : "0rem",
                          }
                        }
                      >
                        {list.key_name === "grand_total" ||
                          list.key_name ===
                          "ebt_items_amount_due_to_be_paid_with_cc" ? (
                          <strong>{list.label}</strong>
                        ) : list.key_name === "ebt_payment_completed" ? (
                          <>
                            {list.label}
                            <b
                              style={{
                                color:
                                  list.key_name === "ebt_payment_completed"
                                    ? "red"
                                    : "",
                                marginLeft: "5px",
                              }}
                            >
                              (${list.amount})
                            </b>
                          </>
                        ) : list.key_name ===
                          "balance_to_pay_with_credit_card" ? (
                          <>
                            {list.label} <b>&nbsp; ${list.amount}</b>
                          </>
                        ) : (
                          list.label
                        )}
                      </p>
                    </div>

                    <div>
                      {list.style === "bold" ? (
                        <strong
                          style={
                            list.style === "bold"
                              ? { fontWeight: "bold", fontSize: "1.3rem" }
                              : list.style === "italic"
                                ? { fontStyle: "italic", fontSize: "1.3rem" }
                                : { fontSize: "1.3rem" }
                          }
                        >
                          {list.key_name === "ebt_payment_completed" ||
                            list.key_name === "balance_to_pay_with_credit_card"
                            ? " "
                            : `$${list.amount}`}{" "}
                        </strong>
                      ) : (
                        <p
                          style={
                            list.style === "bold" ||
                              list.key_name === "grand_total"
                              ? { fontWeight: "bold", fontSize: "1.3rem" }
                              : list.style === "italic"
                                ? { fontStyle: "italic" }
                                : {}
                          }
                        >
                          {list.key_name === "ebt_payment_completed" ||
                            list.key_name === "balance_to_pay_with_credit_card"
                            ? " "
                            : `$${list.amount}`}{" "}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {/* {cateringAndMealKitProductCartList &&
            !!cateringAndMealKitProductCartList.length &&
            checkoutType === "catering-meal" && (
              <div className="all-text-alignment">
                <div>
                  <p>
                    <strong>
                      Total ( {cateringAndMealKitProductCartList.length} item )
                    </strong>
                  </p>
                </div>
                <div>
                  <h2>${cateringMealKitTotalAmount.toFixed(2)}</h2>
                </div>
              </div>
            )} */}

          <div className="enter-mobile-number">
            <div className="enter-mobile-number-box">
              <h3>Enter your Number: </h3>
              <p>
                If required store will contact you about substitutions or
                another questions about the order. Choose your preferred contact
                method.
              </p>
              <div className="number-grid">
                <div className="number-grid-items">
                  <div className="form-control">
                    <Dropdown
                      optionList={contactType}
                      lable=""
                      value={selectDropDownedOption}
                      placeholder="Select"
                      defaultValue="call_me"
                      extraText="at"
                      onChange={handleChangeDropDownOption}
                    />
                  </div>
                </div>
                <div className="number-grid-items"></div>
                <div className="number-grid-items">
                  <div className="form-control">
                    <input
                      type="text"
                      onChange={handleContactInputChange}
                      value={contactInput}
                      maxLength="10"
                      required={true}
                    />
                  </div>
                </div>
              </div>
              {!contactInput && (
                <span className="contact-helper-text">
                  *Please add contact number
                </span>
              )}
              {contactInput.length > 0 && contactInput.length < 10 && (
                <span className="contact-helper-text">
                  *Please add valid contact number
                </span>
              )}
            </div>
          </div>

          {/* grocery */}
          {checkout_pricing_notes && !checkoutType && (
            <div className="note-text-style">
              <ReactHtmlParser htmlString={checkout_pricing_notes || ""} />
            </div>
          )}
          {/* catering */}
          {checkout_catering_pricing_notes &&
            checkoutType === "catering-meal" &&
            is_mealkit === "2" && (
              <div className="note-text-style" style={{ paddingTop: 25 }}>
                <ReactHtmlParser
                  htmlString={checkout_catering_pricing_notes || ""}
                />
              </div>
            )}
          {/* mealkit */}
          {checkout_mealkit_pricing_notes &&
            checkoutType === "catering-meal" &&
            is_mealkit === "1" && (
              <div className="note-text-style" style={{ paddingTop: 25 }}>
                <ReactHtmlParser
                  htmlString={checkout_mealkit_pricing_notes || ""}
                />
              </div>
            )}
          {authorized_notes && !checkoutType && (
            <div className="note-text-style" style={{ paddingTop: 25 }}>
              <ReactHtmlParser htmlString={authorized_notes || ""} />
            </div>
          )}
          {ebt_notes && !checkoutType && (
            <div className="note-text-style" style={{ paddingTop: 25 }}>
              <ReactHtmlParser htmlString={ebt_notes || ""} />
            </div>
          )}

          {age_restricted_notes && !checkoutType && (
            <div
              className="note-text-style age-restricted"
              style={{ paddingTop: 25 }}
            >
              <ReactHtmlParser htmlString={age_restricted_notes || ""} />
            </div>
          )}

          {is_curbside_pickup === "1" && !checkoutType && (
            <div className="note-text-style" style={{ paddingTop: 25 }}>
              <div className="curbside-pickup-checkbox">
                <input
                  type="checkbox"
                  id={"is_curbside_pickup"}
                  onChange={(e) => setCurbsidePickup(e.target.checked)}
                />
                <label htmlFor={"is_curbside_pickup"}>
                  <span style={{ marginLeft: "1rem" }}>
                    {" "}
                    &nbsp;<strong>{"Request curbside pickup"}</strong>
                  </span>
                </label>
              </div>
              {isCurbsidePickup && !checkoutType && (
                <>
                  <div className="curbside-pickup-comment">
                    <div className="form-control">
                      <textarea
                        placeholder="Type here"
                        onChange={handleCurbsidePickupComment}
                        value={curbsidePickupComment}
                      ></textarea>
                    </div>
                  </div>
                  <ReactHtmlParser
                    htmlString={curbside_instruction_notes || ""}
                  />
                </>
              )}
            </div>
          )}
          {third_party_delivery_option === "door_dash" &&
            checkoutStoreType === "delivery" &&
            (
              <div className="note-text-style" style={{ paddingTop: 25 }}>
                <div className="delivery-box">
                  <h3>confirm delivery availability</h3>
                  <p>note: Enter any instructions, tip and click confirm checkbox.</p>
                </div>
                <div className="delivery-checkbox">
                  <textarea rows={4} placeholder="Instructuions for driver..." value={dropOffInstructions} onChange={handleDropOffInstructions} ></textarea>
                </div>
                <div className="number-grid-items">
                  <span>Select Delivery Option:</span>
                  <div className="form-control">
                    <Dropdown
                      optionList={doorDashContactLessDropOff}
                      lable=""
                      value={selectDoorDashContactLessDropDownedOption}
                      placeholder="Select"
                      defaultValue={false}
                      extraText=""
                      onChange={handleChangeDoorDashContactLessDropDownOption}
                      disabled={externalDeliveryId && externalDeliveryId.length > 0}
                    />
                  </div>
                </div>
                <div className="tip-box">
                  <div className="tip-grid">
                    <div className="tip-grid-items">
                      <span>enter tip:</span>
                      <div className="form-control">
                        <input type="text" maxlength="10"
                          disabled={externalDeliveryId && externalDeliveryId.length > 0} value={tipAmount} onChange={handleTipAmount} />
                        {tipAmount.length > 0 && !/^\d+(\.\d{1,2})?$/.test(tipAmount) && (
                          <span className="contact-helper-text">
                            *Please add valid Tip Amount
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="checkbox-items">
                      <input type="checkbox" id="delivery-check"
                        checked={externalDeliveryId && externalDeliveryId.length > 0}
                        disabled={externalDeliveryId && externalDeliveryId.length > 0}
                        onChange={handleConfirmDelivery}
                        value={tpdAllowed} />
                      <label className="delivery-title" for="delivery-check"><span>confirm delivery availability</span></label>
                    </div>
                    {!cartAmountDetailsLoading && status == "error" && (
                      <span className="contact-helper-text error-sms">{message}</span>
                    )}
                    {!cartAmountDetailsLoading && externalDeliveryId && externalDeliveryId.length > 0 && (
                      <div className="delivery-label success-sms">
                        <span>Confirmed, confirmation valid for 5 min.. </span>
                      </div>
                    )}
                    {cartAmountDetailsLoading && (
                      <span>Loading...</span>
                    )}
                  </div>
                </div>
              </div>
            )
          }
          <div className="note-text-style" style={{ paddingTop: 25 }}>
            <label htmlFor={"store_confirmation"} style={{ marginBottom: "10px" , display: "block"}}>
              <span>
                {" "}
                <strong className="store-confirmation-text">{"Please confirm Store selection "}<span>*</span></strong>
              </span>
            </label>
            <div className="curbside-pickup-checkbox">
              <div style={{ display: 'flex', alignItems: 'left' }}>
                <input
                  type="checkbox"
                  id="store_confirmation"
                  onChange={(e) => setStoreConfirmation(e.target.checked)}
                />
                <label htmlFor="store_confirmation">
                  <span>
                    <strong>&nbsp;{" "} &nbsp;{selectedStore && selectedStore.ClientStoreName}</strong>
                  </span>
                </label>
              </div>
              <label style={{ color: "red" , marginTop: "10px"}}>
                <span>
                  <strong>
                    ATTENTION CUSTOMER: <br />
                    Once order is placed no store modification can be made and as a result cancellation and reordering may be required.
                  </strong>
                </span>
              </label>
            </div>
          </div>
          <div className="place-order-text">
            <PaymentMethod />
            {availableCoupons?.coupons &&
              !selectedCoupons.length &&
              isShowCouponSelectWarning ? (
              <h2 className="available-coupon-message">
                *Please select the coupons then click Place Order.
              </h2>
            ) : (
              ""
            )}
            {+remaining_amount !== 0 &&
              !checkoutType &&
              checkoutPaymentMethod !== "ebt" &&
              ebtPaymentPaidList &&
              !ebtPaymentPaidList.length && (
                <ReactHtmlParser
                  htmlString={minimum_order_amount_message || ""}
                />
              )}
            {savePlaceOrderError && (
              <div className="age-restricted" style={{ paddingTop: 25 }}>
                {savePlaceOrderError}
              </div>
            )}
            {/* ebt place order */}
            {ebtPaymentPaidList && !!ebtPaymentPaidList.length ? (
              <div className="ebt-paid-list">
                {ebtPaymentPaidList.map((paid, i) => {
                  return (
                    <div className="ebt-paid-list-item" key={i}>
                      <div className="card-name">
                        <span>{paid.card_number_string}</span>
                        <span>{paid.amount}</span>
                      </div>
                      <div className="card-balance">
                        {" "}
                        {paid.ebt_balance_text_string}
                      </div>
                      {cancelPaidEbtEntryLoading ? (
                        <div className="cancel-payment">
                          {" "}
                          <span>Please wait..</span>
                        </div>
                      ) : (
                        <div
                          className="cancel-payment"
                          onClick={() => handleCancelEbtPayment(paid)}
                        >
                          {" "}
                          <span>Cancel Payment</span>
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className="balance-to-pay">
                  Balance to pay : $
                  {authorizedAmountObj && authorizedAmountObj.amount}
                </div>
              </div>
            ) : (
              ""
            )}

            {!checkoutType &&
              isEbtProductAvailable &&
              checkoutPaymentMethod === "ebt" && (
                <button
                  className={
                    !checkoutContact ||
                      checkoutContact.length < 10 ||
                      savePlaceOrderLoading || !storeConfirmation
                      ? "disable"
                      : ""
                  }
                  onClick={ebtPlaceOrder}
                >
                  {savePlaceOrderLoading ? "Processing..." : "Place Order"}
                </button>
              )}
            {!checkoutType &&
              checkoutPaymentMethod !== "ebt" &&
              ebtPaymentPaidList &&
              !ebtPaymentPaidList.length && (
                <button
                  className={
                    !!+remaining_amount ||
                      !checkoutContact ||
                      savePlaceOrderLoading ||
                      !storeConfirmation ||
                      checkoutContact.length < 10 ||
                      (checkoutStoreType == "delivery" && customerAddress === "") ||
                      (checkoutStoreType == "delivery" && third_party_delivery_option === "door_dash" && (externalDeliveryId === "" || externalDeliveryId === undefined))

                      ? "disable"
                      : ""
                  }
                  onClick={placeOrder}
                >
                  {savePlaceOrderLoading ? "Processing..." : "Place Order"}
                </button>
              )}

            {!checkoutType &&
              checkoutPaymentMethod !== "ebt" &&
              ebtPaymentPaidList &&
              !!ebtPaymentPaidList.length && (
                <button
                  className={
                    !checkoutContact ||
                    !storeConfirmation ||
                      checkoutContact.length < 10 ||
                      savePlaceOrderLoading ? "disable" : ""}
                  onClick={placeOrder}
                >
                  {savePlaceOrderLoading ? "Processing..." : "Place Order"}
                </button>
              )}

            {checkoutType === "catering-meal" && (
              <button
                className={
                  !checkoutContact ||
                    checkoutContact.length < 10 ||
                    !storeConfirmation ||
                    (checkoutStoreType == "delivery" && storeDetails.catering_delivery_cc === "0" && storeDetails.catering_delivery_cod === "0") ||
                    (checkoutStoreType == "store_pickup" && storeDetails.catering_pickup_cc === "0" && storeDetails.catering_pickup_cod === "0") ||
                    savePlaceOrderLoading
                    ? "disable"
                    : ""
                }
                onClick={placeCateringMealOrder}
              >
                {savePlaceOrderLoading ? "Processing..." : "Place Order"}
              </button>
            )}
          </div>
        </div>
      )}
      {isShowPaymentPopup && (
        <PaymentModal
          isShowPaymentPopup={isShowPaymentPopup}
          onClose={() => setPaymentPopup(false)}
          paymentRequestBody={paymentRequestBody}
          checkoutType={checkoutType}
        />
      )}
      {isShowEbtPaymentPopup && (
        <EBTPaymentGateway
          isShowPaymentPopup={isShowEbtPaymentPopup}
          onClose={() => setShowEbtPaymentPopup(false)}
          paymentRequestBody={paymentRequestBody}
          checkoutType={checkoutType}
          ebtPaymentResponse={ebtPaymentResponse}
        />
      )}
    </div>
  );
};
export default CheckoutInformation;

import React, { useEffect, useState, useMemo, useCallback } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import Slider from "react-slick/lib/slider";
import AdsSpaceSection from "./AdsSpaceSection";
import BestSeller from "./BestSeller";
import { getFeatureproduct, getBannerAdsData } from "redux/actions";
import ProductCard from "components/ProductCard";
import ProductCardTheme2 from "components/ProductCardTheme2";
import { getLocalStorageValue } from 'config/helper';

import "./feature-product.scss";
import loader from "assets/images/loader.gif";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";

const FeatureProduct = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const { theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );
  const checkViewportWidth = useCallback(() => {
    setIsMobile(window.innerWidth < 769);
  }, []);

  useEffect(() => {
    checkViewportWidth();
    window.addEventListener('resize', checkViewportWidth);
    return () => {
      window.removeEventListener('resize', checkViewportWidth);
    };
  }, [checkViewportWidth]);
  
  const [filteredBanners, setFilteredBanners] = useState([]);

  const breadcrumbList = [
    { lable: "Featured & Best Sellers", link: "", searchTag: "" },
  ];

  const featureproductData = useSelector(
    ({ featureProduct }) => featureProduct.featureproductData.lists
  );
  const departmentproductData = useSelector(
    ({ featureProduct }) =>
      featureProduct.featureproductData.department_product_list
  );
  const adsDetails = useSelector(
    ({ featureProduct }) =>
      featureProduct.featureproductData.ads_section
  );
  const bannerAdsData = useSelector(
    ({ bannerAds }) => bannerAds.bannerAdsData.ads
  );

  const bannerAdsDataLoading = useSelector(
    ({ bannerAds }) => bannerAds.bannerAdsDataLoading
  );

  const featureproductDataLoading = useSelector(
    ({ featureProduct }) =>
      featureProduct.featureproductDataLoading
  );
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: Math.min(featureproductData?.products_list.length, 6),
    slidesToShow: 5,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getFeatureproduct(body));
    dispatch(getBannerAdsData({
      ...body,
      category_ids: "",
      search: "",
      funtional_text: "",
      placement: "run-of-site-banner-x",
      ros: "Featured"
    }));
  }, [dispatch, clientId, storeId, isUserLoggedIn, memberNumber, userToken]);

  useEffect(() => {
    setFilteredBanners(bannerAdsData?.filter(ad => ad.slotId === "Horizontal Banners")[0]?.banners ?? []);
  }, [bannerAdsData]);

  return (
    <>
      {featureproductData && departmentproductData && (
        <BreadcrumbDetailed
          breadcrumbList={breadcrumbList}
          title={featureproductData.title} //Need to pass title through api res
        />
      )}
      {featureproductDataLoading && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
          <img style={{ width: '100px', height: '50px' }} src={loader} />
          <span style={{ marginLeft: "10px" }}>Loading, please wait....</span>
        </div>
      )}
      <div className="container">
        <AdsSpaceSection adsDetails={adsDetails && adsDetails} />
        {!featureproductDataLoading && !bannerAdsDataLoading && filteredBanners[0] && (
          <AdsSpaceSectionMainTheme2 isMobile={isMobile} adsDetails={filteredBanners[0]} />
        )}
      </div>
      {featureproductData && departmentproductData && (
        <section className="bestseller-slider bestseller-slider-no-bottom-space features-product-card">
          <div className="container">
            <div className="sellerpage-title">
              <p>{featureproductData.title}</p>
              <span>Products you have enjoyed</span>
            </div>

            <Slider {...settings}>
              {theme_type === "1" ? (
                featureproductData.products_list.map((product, index) => {
                  return <ProductCard product={product} key={index} />;
                })
              ) : (
                featureproductData.products_list.map((product, index) => {
                  return <ProductCardTheme2 product={product} key={index} />;
                })
              )}
            </Slider>

          </div>
        </section>
      )}
      {departmentproductData && departmentproductData && (
        <BestSeller departmentproductData={departmentproductData} />
      )}
    </>
  );
};
export default FeatureProduct;

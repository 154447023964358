import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./twobannersection.scss";

const TwoBannerSection = () => {
  const homePageBannerSection = useSelector(
    ({ homepage }) => homepage.bannerAndTestimonialsData.home_page_sections_list
  );

  const [twoBannerSectionSlug, seTwoBannerSectionSlug] = useState(
    "home-page-ad-two-for-banners-section"
  );

  const twoBannerList =
    homePageBannerSection?.find(
      (section) => section.section_slug === twoBannerSectionSlug
    )?.child_section_list || [];

  useEffect(() => {
    homePageBannerSection?.length &&
      seTwoBannerSectionSlug(twoBannerSectionSlug);
  }, [homePageBannerSection]);

  return (
    <>
      {twoBannerList && twoBannerList.length > 0 && (
        <div className="twobanner-banner-theme2">
          <div className="container">
            <div className="twobanner-img-wrap">
              {twoBannerList
                ? twoBannerList.map((bannerTwo) => {
                    return (
                      bannerTwo?.image && (
                        <div className="twobanner-banner-item">
                          <div className="twobanner-img">
                            {bannerTwo?.target_url_for_image_clickable ? (
                              <a
                                href={bannerTwo.target_url_for_image_clickable}
                                style={{
                                  pointerEvents:
                                    bannerTwo.target_url_for_image_clickable !==
                                    ""
                                      ? ""
                                      : "none",
                                }}
                              >
                                <img
                                  src={bannerTwo.image}
                                  alt="twobanner banner_image"
                                  className="img-fluid"
                                />
                              </a>
                            ) : (
                              <img
                                src={bannerTwo.image}
                                alt="twobanner banner_image"
                                className="img-fluid"
                              />
                            )}
                          </div>
                        </div>
                      )
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TwoBannerSection;

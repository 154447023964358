import React, { useState, useEffect } from "react";
import "./contactpage.scss";
import { useSelector, useDispatch } from "react-redux";
import { getLocalStorageValue } from "config/helper";

import { conatctFormDetails } from "../../redux/actions/contactForm";
function ContactPage() {
  const dispatch = useDispatch();
  const { storeListData, currentStoreID } = useSelector(({ store }) => store);
  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const memberNumber = getLocalStorageValue("member-number");
  const userToken = getLocalStorageValue("user-token");

  const selectedStore =
    storeListData &&
    storeListData.find(
      (store) =>
        store.ClientStoreId === getLocalStorageValue("selected-store-id")
    );

  // after api calling getting the api response

  const { contactFormData, contactFormDataLoading, contactFormError } =
    useSelector((contactFormData) => contactFormData?.contactFormReducer);

  // Form validations on [21-06-2024] by kiran konda
  // useState for form handling
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    street_address: "",
    selected_store_location: "",
    state: "",
    store: "",
    message: ""
  });

  // request body for API
  const reqbody = {
    RSAClientId: clientId,
    ClientStoreId: storeId,
    AppName: isUserLoggedIn ? "shop" : "",
    member_number: isUserLoggedIn ? memberNumber : "",
    user_token: isUserLoggedIn ? userToken : "",
    Version: isUserLoggedIn ? "1" : "",
    DeviceType: isUserLoggedIn ? "web" : "",

    name: formData.name,
    email: formData.email,
    street_address: formData.street_address,
    selected_store_location: formData.selected_store_location,
    message: formData.message,
    company: formData.company,
    phone: formData.phone,
    city: formData.city,
    state: formData.state
  };
  // useState from capturing input activity
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    street_address: false,
    selected_store_location: false,
    message: false
  });

  // useState for Errro Messages
  const [errorMessage, setErrorMesaage] = useState({
    name: "",
    email: "",
    phone: "",
    street_address: "",
    selected_store_location: "",
    message: ""
  });
  // email regex
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // handle change function
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormData({
        ...formData,
        [name]: numericValue
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    if (touched[name] && !value) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "This is a required field."
      });
    } else if (name === "email" && !emailRegex.test(formData[name])) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "Please Enter Valid Email ."
      });
    } else {
      setErrorMesaage({
        ...errorMessage,
        [name]: ""
      });
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = formData.email;
    const isValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      emailValue
    );

    if (emailValue === "") {
      setErrorMesaage({
        ...errorMessage,
        email: "This is a required field."
      });
    } else {
      if (!isValidEmail) {
        setErrorMesaage({
          ...errorMessage,
          email: "Please Enter Valid Email ."
        });
      } else {
        setErrorMesaage({
          ...errorMessage,
          email: ""
        });
      }
    }
  };
  // blurr function for capturing error messages in input

  const onBlurChange = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
    if (!formData[name]) {
      setErrorMesaage({ ...errorMessage, [name]: "This is a required field." });
    } else if (name === "email" && !emailRegex.test(formData[name])) {
      setErrorMesaage({
        ...errorMessage,
        [name]: "Please Enter Valid Email ."
      });
    }
  };

  // submit function

  const handleFormSubmit = (e) => {
    const requiredForm = {
      name: formData.name,
      email: formData.email,
      street_address: formData.street_address,
      selected_store_location: formData.selected_store_location,
      message: formData.message
    };

    e.preventDefault();
    const newErrorMessages = {};
    Object.keys(requiredForm).forEach((key) => {
      if (!requiredForm[key]) {
        newErrorMessages[key] = "This is a required field.";
      }
      if (key === "email" && !emailRegex.test(requiredForm["email"])) {
        newErrorMessages[key] = "Please Enter Valid Email .";
      }
    });
    if (formData.phone !== "") {
      if (formData.phone.length < 10) {
        newErrorMessages["phone"] = "Please Enter Valid Phonenumber";
      }
    }
    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMesaage(newErrorMessages);
    } else if (!emailRegex.test(formData.email)) {
      setErrorMesaage({
        ...errorMessage,
        email: "This is a required field."
      });
    } else {
      dispatch(conatctFormDetails(reqbody));
    }
  };
  // Function to allow only numbers

  // reser form
  useEffect(() => {
    if (contactFormData?.status === "success") {
      setFormData({
        name: "",
        company: "",
        email: "",
        phone: "",
        street_address: "",
        city: "",
        state: "",
        selected_store_location: "",
        message: ""
      });
    }
  }, [contactFormData?.status]);

  return (
    <div className="contact-page">
      <div className="mission-container">
        <h2>CONTACT US</h2>
        <p className="hrLine">
          <hr></hr>
        </p>

        <div className="quality-block-holder" style={{ width: "100%" }}>
          <div className="img-text-holder">
            <img
              decoding="async"
              alt="Superior Grocers Phone Number"
              src="https://superiorgrocers.com/wp-content/uploads/2019/03/cell-black.png"
            />
            {selectedStore && (
              <h5 className="keep-span"> {selectedStore?.StorePhoneNumber}</h5>
            )}
          </div>
          <div className="img-text-holder">
            <img
              decoding="async"
              alt="Superior Grocers Address"
              src="https://superiorgrocers.com/wp-content/uploads/2019/03/icon.png"
            />
            {selectedStore && (
              <h5 className="keep-span">
                {selectedStore?.AddressLine1}
                <br />
                {selectedStore?.AddressLine2}
              </h5>
            )}
          </div>
        </div>

        <div className="nf-form-layout">
          <div className="nf-form-fields-required">
            Fields marked with an{" "}
            <span className="ninja-forms-req-symbol">*</span> are required
          </div>
          <form onSubmit={handleFormSubmit}>
            <div>
              <div className="nf-form-content ">
                <div className="nf-row">
                  <div className="nf-cell" style={{ width: "50%" }}>
                    <div
                      id="nf-field-78-container"
                      className="nf-field-container textbox-container  label-below ">
                      <div className="nf-field">
                        <div
                          id="nf-field-78-wrap"
                          className="field-wrap textbox-wrap nf-fail nf-error"
                          data-field-id="78">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-78"
                              id="nf-label-field-78"
                              className="">
                              Name
                              <span className="ninja-forms-req-symbol">*</span>
                            </label>
                          </div>
                          <div className="nf-field-element">
                            <input
                              type="text"
                              className="ninja-forms-field nf-element"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleFormChange}
                              onBlur={onBlurChange}
                            />
                            {errorMessage.name && (
                              <p className="err-msg">{errorMessage.name}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nf-cell" style={{ width: "50%" }}>
                    <div
                      id="nf-field-79-container"
                      className="nf-field-container textbox-container  label-below ">
                      <div className="nf-field">
                        <div
                          id="nf-field-79-wrap"
                          className="field-wrap textbox-wrap"
                          data-field-id="79">
                          <div className="nf-field-element">
                            <div className="nf-field-label">
                              <label
                                for="nf-field-79"
                                id="nf-label-field-79"
                                className="">
                                Company
                              </label>
                            </div>
                            <input
                              type="text"
                              className="ninja-forms-field nf-element"
                              id="company"
                              name="company"
                              value={formData.company}
                              onChange={handleFormChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nf-row">
                  <div className="nf-cell" style={{ width: "50%" }}>
                    <div
                      id="nf-field-81-container"
                      className="nf-field-container textbox-container  label-below ">
                      <div className="nf-field">
                        <div
                          id="nf-field-81-wrap"
                          className="field-wrap textbox-wrap"
                          data-field-id="81">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-81"
                              id="nf-label-field-81"
                              className="">
                              Email
                            </label>
                            <span className="ninja-forms-req-symbol">*</span>
                          </div>
                          <div className="nf-field-element">
                            <input
                              type="text"
                              className="ninja-forms-field nf-element"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={(e) => {
                                handleFormChange(e);
                              }}
                              onBlur={onBlurChange}
                              onKeyUp={handleEmailChange}
                              onKeyDown={handleEmailChange}
                            />
                            {errorMessage.email && (
                              <p className="err-msg">{errorMessage.email}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nf-cell" style={{ width: "50%" }}>
                    <div
                      id="nf-field-82-container"
                      className="nf-field-container textbox-container  label-below ">
                      <div className="nf-field">
                        <div
                          id="nf-field-82-wrap"
                          className="field-wrap textbox-wrap"
                          data-field-id="82">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-81"
                              id="nf-label-field-81"
                              className="">
                              Phone
                            </label>
                          </div>
                          <div className="nf-field-element">
                            <input
                              type="text"
                              className="ninja-forms-field nf-element"
                              id="phone"
                              name="phone"
                              value={formData.phone}
                              onChange={handleFormChange}
                              maxlength="10"
                            />
                            {errorMessage.phone && (
                              <p className="err-msg">{errorMessage.phone}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nf-row">
                  <div className="nf-cell" style={{ width: "100%" }}>
                    <div
                      id="nf-field-78-container"
                      className="nf-field-container textbox-container  label-below ">
                      <div className="nf-field">
                        <div
                          id="nf-field-78-wrap"
                          className="field-wrap textbox-wrap nf-fail nf-error"
                          data-field-id="78">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-78"
                              id="nf-label-field-78"
                              className="">
                              Street Address
                              <span className="ninja-forms-req-symbol">*</span>
                            </label>
                          </div>
                          <div className="nf-field-element">
                            <input
                              type="text"
                              className="ninja-forms-field nf-element"
                              id="street_address"
                              name="street_address"
                              value={formData.street_address}
                              onChange={handleFormChange}
                              onBlur={onBlurChange}
                            />
                            {errorMessage.street_address && (
                              <p className="err-msg">
                                {errorMessage.street_address}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nf-row">
                  <div className="nf-cell" style={{ width: "50%" }}>
                    <div
                      id="nf-field-81-container"
                      className="nf-field-container textbox-container  label-below ">
                      <div className="nf-field">
                        <div
                          id="nf-field-81-wrap"
                          className="field-wrap textbox-wrap"
                          data-field-id="81">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-81"
                              id="nf-label-field-81"
                              className="">
                              City
                            </label>
                          </div>
                          <div className="nf-field-element">
                            <input
                              type="text"
                              className="ninja-forms-field nf-element"
                              id="city"
                              name="city"
                              value={formData.city}
                              onChange={handleFormChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nf-cell" style={{ width: "50%" }}>
                    <div
                      id="nf-field-82-container"
                      className="nf-field-container textbox-container  label-below ">
                      <div className="nf-field">
                        <div
                          id="nf-field-82-wrap"
                          className="field-wrap textbox-wrap"
                          data-field-id="82">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-81"
                              id="nf-label-field-81"
                              className="">
                              State
                            </label>
                          </div>
                          <div className="nf-field-element">
                            <input
                              type="text"
                              className="ninja-forms-field nf-element"
                              id="state"
                              name="state"
                              value={formData.state}
                              onChange={handleFormChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nf-row-store">
                  <div className="nf-cell" style={{ width: "100%" }}>
                    <div
                      id="nf-field-85-container"
                      className="nf-field-container listselect-container  label-below  list-container">
                      <div className="nf-field">
                        <div
                          id="nf-field-85-wrap"
                          className="field-wrap listselect-wrap list-wrap list-select-wrap"
                          data-field-id="85">
                          <div className="nf-element">
                            <label
                              for="nf-field-85"
                              id="nf-label-field-85"
                              className="nf-field-label">
                              Store Location{" "}
                              <span className="ninja-forms-req-symbol">*</span>
                            </label>
                          </div>
                          <div className="nf-field-element">
                            <select
                              id="selected_store_location"
                              name="selected_store_location"
                              value={formData.selected_store_location}
                              onChange={handleFormChange}
                              onBlur={onBlurChange}
                              className="ninja-forms-field nf-element">
                              <option>Choose Store</option>
                              {storeListData.map((option) => (
                                <option value={option.ClientStoreName}>
                                  {option.ClientStoreName}
                                </option>
                              ))}
                            </select>
                            {errorMessage.selected_store_location && (
                              <p className="err-msg">
                                {errorMessage.selected_store_location}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nf-row-message">
                  <div className="nf-cell">
                    <div
                      id="nf-field-57-container"
                      className="nf-field-container textarea-container  label-above desc-none ">
                      <div className="nf-before-field"></div>
                      <div className="nf-field">
                        <div
                          id="nf-field-57-wrap"
                          className="field-wrap textarea-wrap"
                          data-field-id="57">
                          <div className="nf-field-label">
                            <label
                              for="nf-field-57"
                              id="nf-label-field"
                              className="">
                              Message
                              <span className="ninja-forms-req-symbol">*</span>
                            </label>
                          </div>
                          <div className="nf-element-textarea">
                            <textarea
                              id="message"
                              name="message"
                              value={formData.message}
                              onChange={handleFormChange}
                              onBlur={onBlurChange}
                              className="ninja-forms-field nf-element-textarea"></textarea>
                            {errorMessage.message && (
                              <p className="err-msg">{errorMessage.message}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "10px" }}
                        className="nf-after-field">
                        <div>
                          {contactFormData?.message && (
                            <p
                              className="success-msg"
                              style={{ color: "green" }}>
                              {contactFormData?.message}
                            </p>
                          )}
                          {contactFormError && (
                            <p style={{ color: "red" }}>{contactFormError}</p>
                          )}
                        </div>

                        <div
                          id="nf-error-57"
                          className="nf-error-wrap nf-error"
                          role="alert"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nf-row-submit">
                  <div className="nf-cell" style={{ width: "100%" }}>
                    <div
                      id="nf-field-86-container"
                      className="nf-field-container submit-container  label-below  textbox-container">
                      <div className="nf-field">
                        <div
                          id="nf-field-86-wrap"
                          className="field-wrap submit-wrap textbox-wrap"
                          data-field-id="86">
                          <div className="nf-field-label"></div>
                          <div className="nf-field-element">
                            <input
                              style={{
                                cursor: contactFormDataLoading
                                  ? "not-allowed"
                                  : "pointer"
                              }}
                              id="submit"
                              className="ninja-forms-field nf-element-button "
                              type="submit"
                              disabled={contactFormDataLoading}
                              value={
                                contactFormDataLoading
                                  ? "Submitting..."
                                  : "Submit"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;

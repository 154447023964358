import React from "react";
import CouponImage from "../../assets/images/coupon-banner.jpg";
import "./coupon-image-card.scss";

const CouponImageCard = () => {
  return (
    <>
      <div className="coupon-banner-theme2">
        <div className="container">
          <div className="coupon-img-wrap">
            {/* <div className="coupon-img">
              <img src={CouponImage} alt="Coupon_image" className="img-fluid" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponImageCard;

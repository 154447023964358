import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomePageCouponsData } from "redux/actions/homepage";
import DealsOfWeekCoupon from "./DealsOfWeekCoupon";
import WhatsOnSaleCoupon from "./WhatsOnSaleCoupon";
import FeaturedCoupon from "./FeaturedCoupon";
import DescoverSection from "../DescoverSection/DescoverSection";
import OfferBanner from "../OfferBanner/OfferBanner";
import MultiBanner from "../MultiBanner/MultiBanner";
import DepartmentSliderTheme2 from "../DepartmentSliderTheme2";
import "./offer-section-theme2.scss";
import { getLocalStorageValue } from "config/helper";
import TwoBannerSection from "../TwoBannerSection/TwoBannerSection";

const EcomDisabledOfferSectionThme2 = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");

  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  // Get All Coupons Section
  const homePageCouponsData = useSelector(
    ({ homepage }) => homepage.offersWithProductData
  );

  // Get Featured Coupons list
  const [featuredCouponsOfferId, setFeaturedCouponsOfferId] =
    useState("featured-offers");
  const featuredCouponList =
    homePageCouponsData.find(
      (offer) => offer.section_slug === featuredCouponsOfferId
    )?.data_list || [];

  const featuredCouponTitle =
    homePageCouponsData.find(
      (offer) => offer.section_slug === featuredCouponsOfferId
    )?.title || "";

  // Get whats on sale Coupons list
  const [whatsOnSaleCouponsOfferId, setWhatsOnSaleCouponsOfferId] =
    useState("whats-on-sale-1");

  const whatsOnSaleCouponList =
    homePageCouponsData.find(
      (offer) => offer.section_slug === whatsOnSaleCouponsOfferId
    )?.data_list || [];

  const whatsOnsaleTitle =
    homePageCouponsData.find(
      (offer) => offer.section_slug === whatsOnSaleCouponsOfferId
    )?.title || "";

  // Get deal of the week Coupons list
  const [dealOfTheWeekCouponsOfferId, setDealOfTheWeekCouponsOfferId] =
    useState("deal-of-the-week-1");

  const dealOfTheWeekCouponTitle =
    homePageCouponsData.find(
      (offer) => offer.section_slug === dealOfTheWeekCouponsOfferId
    )?.title || "";

  const dealOfTheWeekCouponList =
    homePageCouponsData.find(
      (offer) => offer.section_slug === dealOfTheWeekCouponsOfferId
    )?.data_list || [];

  useEffect(() => {
    if (!!homePageCouponsData.length) {
      setFeaturedCouponsOfferId(featuredCouponsOfferId);
      setWhatsOnSaleCouponsOfferId(whatsOnSaleCouponsOfferId);
      setDealOfTheWeekCouponsOfferId(dealOfTheWeekCouponsOfferId);
    }
  }, [homePageCouponsData]);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getHomePageCouponsData(body));
  }, [storeId]); // eslint-disable-line
  return (
    <div>
      {whatsOnSaleCouponList && whatsOnSaleCouponList.length > 0 && (
        <WhatsOnSaleCoupon
          whatsOnSaleCouponList={whatsOnSaleCouponList}
          whatsOnsaleTitle={whatsOnsaleTitle}
        />
      )}
      <DescoverSection />

      {featuredCouponList && featuredCouponList.length > 0 && (
        <FeaturedCoupon
          featuredCouponList={featuredCouponList}
          featuredCouponTitle={featuredCouponTitle}
        />
      )}

      <OfferBanner />

      {dealOfTheWeekCouponList && dealOfTheWeekCouponList.length > 0 && (
        <DealsOfWeekCoupon
          dealOfTheWeekCouponList={dealOfTheWeekCouponList}
          dealOfTheWeekCouponTitle={dealOfTheWeekCouponTitle}
        />
      )}
      <TwoBannerSection />
      <MultiBanner />

      <DepartmentSliderTheme2 />
    </div>
  );
};
export default EcomDisabledOfferSectionThme2;

import React from "react";
import "./free-reward.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const FreeReward = ({ lmRewardData }) => {
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const BuyQtyAmount = lmRewardData?.buy_qty_amount;
  const PurchasedQty = lmRewardData?.purchased_qty;

  const Loopcount = (buy, parchaes) => {
    var count = [];

    for (var i = 1; i <= parchaes; i++) {
      count.push({ checked: true });
    }
    for (var j = 1; j <= buy - parchaes; j++) {
      count.push({ checked: false });
    }
    return count;
  };

  return (
    <>
      <div className="free-reward-main-wrap">
        <div className="free-reward-box">
          <div className="free-reward-img">
            <img src={lmRewardData?.image_url} alt="product_image" />
          </div>
          <div className="free-reward-content-wrapper">
            <div className="free-reward-subtitle">
              {lmRewardData?.RewardTitle}
            </div>
            <div className="free-reward-title-btn">
              <div className="free-reward-title">{lmRewardData?.title}</div>
            </div>
            <div className="free-reward-range-bar">
              {PurchasedQty >= BuyQtyAmount ? (
                <div>
                  <h1 style={{ color: "red" }}>
                    Congratulations! You earned a reward and it is on the way.
                  </h1>
                  <p>
                    Please allow 15-30 min before you see the reward in your
                    list. It applies to your next purchase.
                  </p>
                </div>
              ) : (
                <div className="free-reward-point">
                  {Loopcount(BuyQtyAmount, PurchasedQty)?.map((val, index) => (
                    <span className="checkbox-wrap">
                      <div className="relative-checkbox blure">
                        <input type="checkbox" checked={val?.checked} />
                        <label></label>
                      </div>
                    </span>
                  ))}

                  <span className="free-reward-count">
                    {lmRewardData?.buy_qty_amount} left
                  </span>
                </div>
              )}

              <div className="reward-btn-wrapper">
                <div className="reward-btn">
                  <Link
                    to={isUserLoggedIn ? "/rewardsdetails" : "/login"}
                    state={{
                      lmRewardId: lmRewardData?.lm_reward_id,
                      isPointBased: lmRewardData?.is_points_based,
                      lmRewardData: lmRewardData,
                    }}
                  >
                    view details
                  </Link>
                </div>
                {isUserLoggedIn && lmRewardData?.is_points_based && (
                  <div className="reward-btn redeem-btn">
                    <Link
                      to="/rewardsdetails"
                      state={{
                        lmRewardId: lmRewardData?.lm_reward_id,
                        Redeem: true,
                        isPointBased: lmRewardData?.is_points_based,
                        lmRewardData: lmRewardData,
                      }}
                    >
                      Redeem
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeReward;

import React, { useState, useEffect } from "react";
import ReactHtmlParser from "components/Common/ReactHtmlParser";
import "./product-info-theme2.scss";
const ProductInfoTheme2 = ({ productDetails }) => {

  const { nutrition_description, nutrition_image, description } =
    productDetails;
  const [selectedAttribute, setSelectedAttribute] = useState("description-tab");
  useEffect(() => {
    setSelectedAttribute(
      description
        ? "description-tab"
        : nutrition_description
          ? "nutrition-tab"
          : ""
    );
  }, [productDetails]); // eslint-disable-line
  
  return (
    <>
      <div className="product-info-theme2">
        <div className="container">
          {(description || nutrition_description) && (
            <div className="info-box">
              <div className="info-tab">
                {description && (
                  <button
                    className={
                      selectedAttribute === "description-tab" ? "active" : ""
                    }
                    onClick={() => setSelectedAttribute("description-tab")}
                  >
                    Description
                  </button>
                )}
                {(nutrition_description || nutrition_image) && (
                  <button
                    className={
                      selectedAttribute === "nutrition-tab" ? "active" : ""
                    }
                    onClick={() => setSelectedAttribute("nutrition-tab")}
                  >
                    Nutrition
                  </button>
                )}
              </div>
              <div className="info-details">
                {selectedAttribute === "description-tab" && (
                  <ReactHtmlParser htmlString={description || ""} />
                )}
                {selectedAttribute === "nutrition-tab" && (
                  <>
                    <h1 className="Nutrition-heading">
                      Nutritional Information
                    </h1>
                    <div className="nutrition-wrapper">
                      {nutrition_image && (
                        <div className="nutrition-image">
                          <img src={nutrition_image} alt="nutri"></img>
                        </div>
                      )}
                      {nutrition_description && (
                        <div className="nutrition-information">
                          <ReactHtmlParser
                            htmlString={nutrition_description || ""}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ProductInfoTheme2;

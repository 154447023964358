import React from "react";
import { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import "./confirmation-modal.scss";

const ConfirmationModalTheme2 = (props) => {
  const refDelivery = useRef();
  const { isShow, onClose, onClear } = props;

  useOnClickOutside(refDelivery, () => onClose());

  return (
    <>
      {isShow && (
        <>
          <div className="modal-backdrop">
            <div className="modal store-selector-modal" ref={refDelivery}>
              <div className="clear-cart-modal-body-theme2">
                <h3>You want to remove the address. Confirm?</h3>
                <div>
                  <button
                    className="button-outline"
                    type="button"
                    onClick={onClose}
                  >
                    <span>No</span>
                  </button>
                  <button
                    className="button-primary"
                    onClick={() => {
                      onClear();
                      onClose();
                    }}
                  >
                    <span>Yes</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ConfirmationModalTheme2;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Filter from "assets/icons/filter.png";
import DropIcon from "assets/icons/arrow-down.svg";
import { useLocation } from "react-router-dom";
import "./products-filters.scss";
import { useNavigate } from "react-router-dom";
import {
  emptyIftCheckList,
  updatePageLimit,
  updateSortByValue,
} from "redux/actions";

const ProductsFilters = ({
  iftOptionList,
  handleIftChange,
  sortByOption,
  sortByValue,
  listLimitOption,
  pageLimit,
  setPageLimit,
  getProductListDeptAPICall,
  departmentSlug,
  activeChildIndex,
  setActiveChildIndex,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortType, setSortType] = useState("");
  const { searchType } = useSelector(({ search }) => search);
  useEffect(() => {
    if (!sortByValue) {
      setSortType("");
    }
  }, [sortByValue]); // eslint-disable-line

  const handleChangeSortFilter = (sortBy, value) => {
    setSortType(value);
    dispatch(updateSortByValue(sortBy));
  };
  const { iftCheckedList } = useSelector(({ product }) => product);

  const [couponConditionChange, setCouponConditionChange] = useState(true);
  const paramData = useLocation();
  const couponCondition =
    paramData?.state !== null &&
    (iftCheckedList?.length === 0) & couponConditionChange;


// 02/dec/2024 - on click popup design remove & add hover design 
  // const [showPopup, setShowPopup] = useState(false);

  // const togglePopup = () => {
  //   setShowPopup(!showPopup);
  // };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (showPopup && !event.target.closest(".handleoutsideclick")) {
  //       setShowPopup(false);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [showPopup]);

  const handleChangeOption = (_, value) => {
    setPageLimit(value);
    dispatch(updatePageLimit(value));
  };

  return (
    <>
      <div className="main-filter-wrapper">
        <div className="all-action-wrapper">
          <div className="show-items">
            {/* removed button toggle */}
            <button>
              <img src={Filter} alt="short icon" />
              <span>Filter & Sorts</span>
              <img src={DropIcon} alt="drop icon" />
            </button>
          </div>
          {/* remove class condition & class */}
          <div
            className="filter-search-wrapper">
            <div className="filter-penal-all-block-main filter-penal-short-block">
              <div className="filter-penal-all-block">
                <div className="filter-penal-show-block">
                  <div className="filter-title-wrapper">
                    <span>Show only</span>
                  </div>
                  <div className="show-filter-items-wrapper">
                    <div className="all-price-show-filter">
                      {iftOptionList &&
                        iftOptionList.length > 0 &&
                        iftOptionList
                          .sort(function (a, b) {
                            return a.order_by - b.order_by;
                          })
                          .map((item) => {
                            return (
                              <>
                                {item.is_visible === "1" && (
                                  <div className="show-filter-all-price">
                                    <input
                                      type="checkbox"
                                      name="ift_action"
                                      id={item.ift}
                                      onChange={(e) => {
                                        handleIftChange(
                                          item.ift,
                                          e.target.checked
                                        );
                                        setCouponConditionChange(false);
                                      }}
                                      value={item.ift}
                                      checked={
                                        couponCondition
                                          ? ["coupon"].includes(item.ift)
                                          : iftCheckedList.includes(item.ift)
                                      }
                                    />
                                    <label htmlFor={item.ift}>
                                      <span>{item.title} </span>
                                    </label>
                                  </div>
                                )}
                              </>
                            );
                          })}
                    </div>
                  </div>
                </div>
                <div className="filter-penal-items-block">
                  <div className="filter-items-wrapper">
                    <div className="short-by-filters-item">
                      <div className="filter-title-wrapper">
                        <span>sort by</span>
                      </div>
                      <div className="filter-short-by-items">
                        {sortByOption &&
                          sortByOption.length > 0 &&
                          sortByOption.map((sortBy) => {
                            return (
                              <div className="filter-selected-items">
                                <input
                                  type="radio"
                                  name="sort_by"
                                  id={`sort_by_${sortBy.value}`}
                                  value={sortBy.value}
                                  onChange={() =>
                                    handleChangeSortFilter(
                                      sortBy.value,
                                      sortBy.value
                                    )
                                  }
                                  checked={parseInt(sortBy.value) == sortType}
                                />
                                <label htmlFor={`sort_by_${sortBy.value}`}>
                                  {sortBy.title}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="all-price-filter">
                      <div class="filter-title-wrapper">
                        <span>Show Records</span>{" "}
                        <span className="sub-title">(Per Page)</span>
                      </div>
                      {listLimitOption &&
                        listLimitOption.length > 0 &&
                        listLimitOption.map((limit) => {
                          return (
                            <div className="filter-all-price">
                              <input
                                type="radio"
                                name="page_limit"
                                id={`page_limit_${limit.value}`}
                                value={limit.value}
                                onChange={() =>
                                  handleChangeOption(pageLimit, limit.value)
                                }
                                checked={
                                  pageLimit === limit.value ? true : false
                                }
                              />
                              <label htmlFor={`page_limit_${limit.value}`}>
                                {limit.value}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-btn">
                <button
                  className="close-btn btn"
                  onClick={() => {
                    dispatch(emptyIftCheckList());
                    handleChangeSortFilter("", "");
                    handleChangeOption(pageLimit, listLimitOption[0]?.value);
                  }}
                >
                  Clear Filter
                </button>
                <button
                  className=" apply-btn btn"
                  onClick={() => {
                    getProductListDeptAPICall();
                    // remove toggle popup
                    // togglePopup();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="clear-filter"
          onMouseDown={() => {
            dispatch(emptyIftCheckList());
            handleChangeSortFilter("", "");
            setActiveChildIndex("");
            handleChangeOption(pageLimit, listLimitOption[0]?.value);
          }}
          onMouseUp={() => {
            // if (["products", "coupons"].includes(searchType)) {
            //getProductListDeptAPICall();
            navigate(`/search/products/`);
            //   navigate(`/search/${searchType}`);
            // } else navigate(`/departments/${searchType}`);
          }}
        >
          Clear All
        </div>
      </div>
    </>
  );
};

export default ProductsFilters;

import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import RecipesPageSidebar from "components/RecipesPage/Sidebar";
import RecipesList from "components/RecipesPage/RecipesList";
import RecipesListPageAction from "components/RecipesPage/Actions";
import { useParams } from "react-router-dom";
import {
  getRecipesMasterData,
  getRecipesList,
  getRecipesRandomList,
} from "redux/actions";
import { Link } from "react-router-dom";
import "./recipes.scss";
import AdsSpaceSectionMain from "./AdsSpaceSectionMain";

const Recipes = () => {
  const { searchKeyWord } = useParams();
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const breadcrumbList = [
    { lable: "Department", link: "", searchTag: "" },
    {
      lable: "Recipes",
      link: "",
      searchTag: "",
    },
  ];

  useEffect(() => {
    dispatch(getRecipesMasterData());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (searchKeyWord && searchKeyWord !== "Popular") {
      dispatch(
        getRecipesList(`type=${searchKeyWord ? searchKeyWord : ""}&number=120`)
      );
    } else {
      dispatch(getRecipesRandomList(`limitLicense=true&number=120`));
    }
  }, []); // eslint-disable-line

  const { recipeList, recipeListTotalRecord } = useSelector(
    ({ recipes }) => recipes
  );

  return (
    <div className="recipes-page">
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={"Recipes"} />
      <AdsSpaceSectionMain adsDetails={{}} />
      {/* {isUserLoggedIn ? ( */}
      <div className="container height-100vh">
        <div className="recipes-page-wrapper">
          <div className="listing-page-wrapper-items">
            <RecipesPageSidebar />
          </div>
          <div className="listing-page-wrapper-items">
            {/* <AdsSpaceSection
              adsDetails={categoryadsSection}
              department={departmentDetails.name}
            /> */}

            <RecipesListPageAction />
            <RecipesList
              recipeList={recipeList}
              recipeListTotalRecord={recipeListTotalRecord}
            />
          </div>
        </div>
      </div>
      {/* ) : (
        <div className="login-message">
          <p> Please login to view the recipes.</p>
          <div className="login-link">
            <Link to="/login">Back to Sign in</Link>
          </div>
        </div>
      )
      } */}
    </div>
  );
};
export default Recipes;

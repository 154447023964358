import React from "react";
import { useSelector } from "react-redux";
import "./service-section.scss";

const ServiceSection = () => {
  const serviceSectionData = useSelector(
    ({ common }) => common.commonDetailsData.services_section
  );

  return (
    <>
    {serviceSectionData.length > 0?(
      <section className="page-container">
        <div className="facility-bar">
          <div className="grid">
            {serviceSectionData.map((el) => {
              return (

                el.icon && el.services_title && (
                  <div className="grid-items" key={el.services_id}>
                    <div className="sub-grid">
                      <div className="sub-grid-items">
                        <img src={el.icon} alt="" />
                      </div>
                      <div className="sub-grid-items">
                        <p>{el.services_title}</p>
                        <span>{el.services_sub_title}</span>
                      </div>
                    </div>
                  </div>
                )

              );
            })}
          </div>
        </div>
      </section>
    ):("")}
    </>
    
  );
};

export default ServiceSection;

import React, { useState } from "react";
import "./wine-pairing.scss";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";

const WinePairing = () => {
  const [selectedMainDish, setSelectedMainDish] = useState(null);
  const [selectedSpiceDish, setSelectedSpiceDish] = useState(null);
  const [DescriptionId, SetDescriptionId] = useState(null);

  const WineAPIData = {
    "Beef/Lamb/Veal": {
      "Roasted/Grilled/Au Jus": {
        "Cabernet Sauvignon": "Best",
        Merlot: "Better",
        "Chianti/Sangiovese": "Good",
      },
      BBQ: { Zinfandel: "Best", Merlot: "Good" },
      Garlic: {
        Zinfandel: "Best",
        "Chianti/Sangiovese": "Better",
        "Shiraz/Syrah": "Good",
      },
      Herbs: { Merlot: "Best", "Shiraz/Syrah": "Better", Zinfandel: "Good" },
      Horseradish: {
        "Cabernet Sauvignon": "Best",
        Merlot: "Better",
        "White Zinfandel": "Good",
      },
      Mushroom: {
        "Shiraz/Syrah": "Best",
        "Cabernet Sauvignon": "Better",
        Zinfandel: "Good",
      },
      Mustard: {
        "Cabernet Sauvignon": "Best",
        Zinfandel: "Better",
        "Shiraz/Syrah": "Good",
      },
      Onion: { Zinfandel: "Best", Merlot: "Good" },
      Peppercorn: {
        Zinfandel: "Best",
        "Cabernet Sauvignon": "Better",
        Merlot: "Good",
      },
      Tomato: {
        Zinfandel: "Best",
        "Cabernet Sauvignon": "Better",
        Merlot: "Good",
      },
      "Wine Sauce": {
        "Cabernet Sauvignon": "Best",
        Merlot: "Better",
        Zinfandel: "Good",
      },
    },
    "Pasta/Vegetarian": {
      "Basil / Pesto": {
        Merlot: "Best",
        Chardonnay: "Better",
        "Shiraz/Syrah": "Good",
      },
      "Clam Sauce": {
        Chardonnay: "Best",
        "Pinot Grigio": "Better",
        "Fume/Sauvignon Blanc": "Good",
      },
      "Cream Sauce": { "Pinot Noir": "Best", Chardonnay: "Better" },
      Garlic: {
        Merlot: "Best",
        "Pinot Noir": "Better",
        "White Zinfandel": "Good",
      },
      "Hot & Spicy": {
        "White Zinfandel": "Best",
        "Fume/Sauvignon Blanc": "Better",
        Chardonnay: "Good",
      },
      "Italian Dressing": {
        "Fume/Sauvignon Blanc": "Best",
        "Pinot Grigio": "Better",
        Chardonnay: "Good",
      },
      Parmesan: { Merlot: "Best", Chardonnay: "Better", Riesling: "Good" },
      "Stir Fry / Ginger": {
        Chardonnay: "Best",
        Riesling: "Better",
        "White Zinfandel": "Good",
      },
      "Tomato / Marinara": {
        Zinfandel: "Best",
        "Chianti/Sangiovese": "Better",
        Merlot: "Good",
      },
      "Tomato / Oregano": {
        "Chianti/Sangiovese": "Best",
        Merlot: "Better",
        Chardonnay: "Good",
      },
    },
    "Chicken/Turkey/Pork": {
      "Baked / Grilled": {
        "White Zinfandel": "Best",
        "Fume/Sauvignon Blanc": "Better",
        Merlot: "Better",
        Riesling: "Good",
      },
      BBQ: {
        "White Zinfandel": "Best",
        "Shiraz/Syrah": "Better",
        Merlot: "Better",
        Zinfandel: "Good",
      },
      "Cream Sauce": {
        Chardonnay: "Best",
        "Pinot Grigio": "Better",
        "Chianti/Sangiovese": "Good",
      },
      Garlic: {
        Chardonnay: "Best",
        Merlot: "Better",
        "White Zinfandel": "Better",
        Riesling: "Good",
      },
      Herbs: {
        Chardonnay: "Best",
        "Pinot Noir": "Better",
        Merlot: "Better",
        "Fume/Sauvignon Blanc": "Good",
      },
      "Hot & Spicy": {
        "White Zinfandel": "Best",
        Riesling: "Better",
        Zinfandel: "Good",
      },
      Lemon: {
        "Fume/Sauvignon Blanc": "Best",
        "Pinot Grigio": "Better",
        Chardonnay: "Good",
      },
      "Marsala Wine": {
        "Pinot Noir": "Best",
        Merlot: "Better",
        Chardonnay: "Good",
      },
      Mustard: {
        "Fume/Sauvignon Blanc": "Best",
        Chardonnay: "Better",
        Merlot: "Better",
        "Pinot Noir": "Good",
      },
      Onion: {
        Chardonnay: "Best",
        "White Zinfandel": "Better",
        Riesling: "Good",
      },
      Parmesan: {
        Chardonnay: "Best",
        Merlot: "Better",
        "Pinot Grigio": "Better",
        "Pinot Noir": "Good",
      },
      Pepper: {
        Chardonnay: "Best",
        Riesling: "Better",
        "Fume/Sauvignon Blanc": "Good",
      },
      Tomato: {
        "Chianti/Sangiovese": "Best",
        "Pinot Noir": "Better",
        Merlot: "Good",
      },
    },
    "Fish/Seafood": {
      Butter: {
        Chardonnay: "Best",
        "Fume/Sauvignon Blanc": "Better",
        "Pinot Grigio": "Good",
      },
      "Cocktail Sauce": { "White Zinfandel": "Best" },
      Garlic: {
        Chardonnay: "Best",
        "Fume/Sauvignon Blanc": "Better",
        "Pinot Grigio": "Good",
      },
      "Grilled Darker Fish": {
        "Pinot Noir": "Best",
        Chardonnay: "Better",
        Merlot: "Good",
      },
      "Grilled Lighter Fish": {
        Chardonnay: "Best",
        "Fume/Sauvignon Blanc": "Better",
        "Pinot Grigio": "Good",
      },
      Herbs: {
        "Fume/Sauvignon Blanc": "Best",
        Chardonnay: "Better",
        "Pinot Grigio": "Good",
      },
      "Hot & Spicy": {
        "Pinot Grigio": "Best",
        "White Zinfandel": "Better",
        "Fume/Sauvignon Blanc": "Good",
      },
      Lemon: {
        "Fume/Sauvignon Blanc": "Best",
        "Pinot Grigio": "Better",
        Chardonnay: "Good",
      },
      Pepper: { Chardonnay: "Best", "Pinot Noir": "Better", Riesling: "Good" },
    },
  };

  const DescriptionData = {
    "Cabernet Sauvignon":
      "When it comes to pairing Cabernet Sauvignon with food, keep in mind that it is high in tannins, making it a bold wine, perfect for pairing with meats and rich foods, such as burgers, pizzas, steaks, and cream sauces. As Cabernet ages, though, it mellows, making it more versatile for pairing with a wider variety of foods. Some flavors associated with Cabernet Sauvignon are green bell pepper, eucalyptus, and mint. DNA evidence has determined that Cabernet Sauvignon is the offspring of Cabernet Franc and Sauvignon Blanc.",

    Merlot:
      "Whether you like your wine fruity and smooth with little tannins, fruity with some tannins, or powerful with high tannins, Merlot offers something for every type of wine lover, and the range of notes is simply delightful-black and red cherries, blackberry, plum, bell pepper, leather, olives, tobacco, eucalyptus, mint, rosemary, sage, caramel, coffee bean, molasses, and more. And food pairings are just as diverse. Pair lighter Merlot wines with salads, salmon, and scallops. Pair bold Merlot wines with grilled meats.",

    "Chianti/Sangiovese":
      "The acidity and tannin of Sangiovese wine is high, and the flavors range from fig to red plum to strawberry, and oregano to roasted pepper to thyme. Pair a Sangiovese-based Chianti with pizza or tomato-based pasta dishes, pair a varietal Sangiovese with meat and chicken dishes, and pair oaked Sangiovese with grilled foods.",

    Zinfandel:
      "High in acidity and sometimes described as spicy, Zinfandel offers varied flavors, depending on the region where the grapes are grown. The black-skinned Zinfandel grape produces a bold red wine, but the grapes are also used to produce White Zinfandel, a semi-sweet rose. Zinfandel produced in Napa Valley is full-bodied and very aromatic with notes of blackberry and black pepper. Zinfandel produced in Sonoma offers softer wines, while Zinfandel produced in Paso Robles is medium-bodied with floral characteristics.",

    "Shiraz/Syrah":
      "Chock-full of antioxidants and dark fruit flavors, Syrah is a full-bodied wine, perfect for pairing with bold foods, such as barbecue, burgers, and steaks. The Syrah grape variety is grown around the world, including Argentina, Australia, Chile, France, South Africa, Switzerland, and the United States. Depending on where the grapes are grown, Syrah produces a wide variety of flavors, ranging from black olive to blueberry. Syrah is also known as Shiraz.",

    "White Zinfandel":
      "If you're just discovering wine, White Zinfandel - really a pale pink color - is a great beginner's wine. White Zinfandel is low in alcohol, is sweet, and is considered a simple, everyday wine. Produced using the red Zinfandel grape, White Zinfandel gets its pale pink color from the grape skins, which are removed right after the grapes are crushed. This blush wine pairs well a variety of foods and tames foods that are spicy in flavor.",

    Chardonnay:
      "Oaked or unoaked, that is the question. These are the two styles of Chardonnay. Oaked Chardonnays are full-bodied wines that offer wonderful flavors ranging from butter to caramel and oak to vanilla. Unoaked Chardonnays are zesty wines that offer flavors ranging from green apple to lemon to pineapple. Pair with chicken, pork, cod, halibut, lobster, lemon zest, parsley, shallots, thyme, asparagus, mushrooms, peas, and squash.",

    "Pinot Grigio":
      "Pinot Grigio will impress your palate! The wine characteristics of Pinot Grigio vary depending on the region where the grapes are grown, as well as the wine making process of the region. Ranging in flavors from lime to green apple to lemon to nectarine, with hints of almond, clove, honey or spice, Pinot Grigio is both versatile and smooth. Pair this fresh and light white wine with fish and pasta, such as cod, risotto, shrimp, or spaghetti.",

    "Fume/Sauvignon Blanc":
      "Produced from the green Sauvignon Blanc grape, this “wild white” wine is crisp, dry, and fresh and ranges in flavor from grassy to tropical, depending on the climate where the grapes are grown. Although the Sauvignon Blanc grape originates from the Bordeaux regions and Loire Valley in France, it is also cultivated in California, Canada, Chile, New Zealand, and Washington, as well as other places in the world. This light- to medium-bodied wine pairs well with green vegetables, fish, salads, and shellfish.",

    "Pinot Noir":
      "While Pinot Noir grapes are generally associated with the Burgundy region of France, they're grown in regions throughout the world, including Australia, Austria, Canada, France, Germany, Italy, and the United States, just to name a few. This versatile, light-colored wine pairs with many types of dishes, including salmon, rich meats, ravioli, pizza, and more. Depending on the vintage and where the grapes are grown, the aromas and flavors of Pinot Noir can range from earthy to floral, raspberry to smoky, and black cherry to vanilla.",

    Riesling:
      "If you're a fan of aromatic wines, you'll love Riesling. The Riesling grape is used to produce dry, semi-sweet, and sparkling white Riesling wines. Grapes are grown in Alsace, Australia and New Zealand, Austria, Canada, Germany, United States and other regions of the world. Riesling's flavors range from lime to lemon to pineapple to apricot, Riesling is high in acidity and is best served refrigerator cold, around 43 degrees F. Riesling pairs well with highly spiced Asian and Indian dishes.",
  };

  const transformedData = (data) => {
    let result = [];

    Object.keys(data).forEach((firstLevelKey) => {
      result[firstLevelKey] = [];

      Object.keys(data[firstLevelKey]).forEach((secondLevelKey) => {
        const thirdLevelPairs = data[firstLevelKey][secondLevelKey];

        Object.keys(thirdLevelPairs).forEach((wine) => {
          const pair = wine;
          if (
            !result[firstLevelKey].some(
              (item) =>
                item.wine === wine && item.rating === thirdLevelPairs[wine]
            )
          ) {
            result.push(pair);
          }
        });
      });
    });

    return result;
  };

  const [Level1_WineData, setLevel1_WineData] = useState(
    Object.keys(WineAPIData)
  );

  const [Level2_WineData, setLevel2_WineData] = useState({});
  const Level3_WineData = [...new Set(transformedData(WineAPIData))];

  const [Level3_RecommendedData, setLevel3_RecommendedData] = useState([]);

  const handleMainDishSelect = (dishId) => {
    setSelectedMainDish(dishId);
    setSelectedSpiceDish(null);
    setLevel2_WineData(WineAPIData[dishId]);
    setLevel3_RecommendedData([]);
    SetDescriptionId(null);
  };

  const handleSpiceDishSelect = (dish) => {
    setSelectedSpiceDish(dish);
    setLevel3_RecommendedData(Level2_WineData[dish]);
    SetDescriptionId(null);
  };

  const breadcrumbList = [
    { lable: "wine pairing guide", link: "", searchTag: "" },
  ];

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title="wine pairing guide"
      />

      <div className="winepairing-section">
        <div className="container">
          <div className="paring-sec">
            <div className="main-winepairing">
              <h2 className="wine-title">wine pairing guide</h2>
              <div className="pairing-items">
                <div className="main-dish dish-item-box">
                  <h2>1. CHOOSE YOUR MAIN DISH</h2>
                  <ul className="selection-list">
                    {Level1_WineData?.map((dishId, index) => (
                      <li
                        key={index}
                        className={
                          selectedMainDish === dishId ? "selected" : ""
                        }
                        onClick={() => handleMainDishSelect(dishId)}
                      >
                        {dishId}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="spice-dish dish-item-box">
                  <h2>2. CHOOSE YOUR SPICE</h2>
                  {Level2_WineData && (
                    <ul className="selection-list">
                      {Object.keys(Level2_WineData)?.map((dish, index) => (
                        <li
                          key={index}
                          className={
                            selectedSpiceDish === dish ? "selected" : ""
                          }
                          onClick={() => handleSpiceDishSelect(dish)}
                        >
                          {dish}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="recommended-dish dish-item-box">
                  <h2>3. RECOMMENDED Wine</h2>

                  <ul className="selection-list">
                    {Level3_WineData?.map((dish, index) => (
                      <li
                        key={index}
                        className={`recommended ${
                          Level3_RecommendedData[dish] !== undefined
                            ? Level3_RecommendedData[dish].toLowerCase()
                            : ""
                        }`}
                        onClick={() => {
                          SetDescriptionId(dish);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {dish}
                        {Level3_RecommendedData &&
                          Object.keys(Level3_RecommendedData).includes(
                            dish
                          ) && (
                            <span
                              className={Level3_RecommendedData[
                                dish
                              ].toLowerCase()}
                            >
                              {Level3_RecommendedData[dish]}
                            </span>
                          )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {DescriptionId && (
                <div className="recommended-dish-desc">
                  <h1>{DescriptionId}</h1>
                  <p>{DescriptionData[DescriptionId]}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WinePairing;

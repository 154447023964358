import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import AccountWrapperTheme2 from "./AccountWrapperTheme2";

const MyAccount = () => {
  const breadcrumbList = [{ lable: "My Account", link: "" }];
  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={`My Account`} />
      <AccountWrapperTheme2 />
    </>
  );
};
export default MyAccount;

import React, { useEffect } from "react";
import Slider from "react-slick";
import { useSelector, useDispatch } from "react-redux";
import { getBannerAndTestimonialsData } from "redux/actions/homepage";
import { getLocalStorageValue } from 'config/helper';
import "./spotlight-banner.scss";
import { Link } from "react-router-dom";

const SpotLightBanner = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getBannerAndTestimonialsData(body));
  }, []); // eslint-disable-line

  const { banner_section: bannerData, banner_setting } = useSelector(
    ({ homepage }) => homepage.bannerAndTestimonialsData || {}
  );

  const { slider_speed = 0, autoslide = "0" } = banner_setting || {}

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: autoslide === "1",
    autoplaySpeed: +slider_speed,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {bannerData && bannerData.length !== 0 ? (
        <>
        <div className="spotlight-banner-section">
          <Slider {...settings}>
            {bannerData
              ? bannerData.map((el) => {
                return (
                  <div key={el.slider_id}>
                    <div
                      className="spotlight-banner"
                      style={{
                        backgroundImage: `url(${el.bg_image})`,
                      }}
                    >
                      <div className="container">
                        <div className="spotlight-banner-grid">
                          {el?.slider_title1 || el?.slider_title2 || el?.slider_title3 || el?.button_title ?(
                            <div className="spotlight-banner-grid-items">
                              {el?.slider_title1 && <h1>{el.slider_title1}</h1>}
                              {el?.slider_title2 && <h2>{el.slider_title2}</h2>}
                              {el?.slider_title3 && <p>{el.slider_title3}</p>}
                              {el?.button_title ? (
                                el.is_link === "0" ?
                                el.is_open_self === "0"?
                                <button>
                                <Link to={el.button_url} >
                                      {el.button_title}
                                    </Link>
                                  </button>
                                :
                                  <button>
                                    <Link to={el.button_url} target={"_blank"} rel="noreferrer">
                                      {el.button_title}
                                    </Link>
                                  </button>
                                  :
                                  el.is_open_self === "0"?
                                  <button>
                                    <a href={el.button_url}  >
                                      {el.button_title}
                                    </a>
                                  </button>
                                  :
                                  <button>
                                    <a href={el.button_url} target={"_blank"} rel="noreferrer" >
                                      {el.button_title}
                                    </a>
                                  </button>
                              ) : (
                                ""
                              )}
                            </div>
                          ):("")}
                          <div className="spotlight-banner-grid-items">
                            {el.file_type === "image" ? (
                              el?.clickable_image_url?(
                                  el.is_link_clickable_image_url === "0" ?
                                  <Link to={el.clickable_image_url} >
                                      <img src={el.front_image} alt="" />
                                  </Link>
                                  :
                                  <a href={el.clickable_image_url}  >
                                    <img src={el.front_image} alt="" />
                                  </a>
                              ):(
                                <img src={el.front_image} alt="" />
                              )
                              
                            ) : el.file_type === "video" &&
                              el.video_type === "youtube" ? (
                              <iframe
                                width="720"
                                height="450"
                                src={`https://www.youtube.com/embed/${el.video_url.split("v=")[1]
                                  }`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                              />
                            ) : (
                              <video width="720" height="450" controls>
                                <source src={el.video_url} type="video/mp4" />
                              </video>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
              : ""}
          </Slider>
        </div>
        <br></br>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SpotLightBanner;

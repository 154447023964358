import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./image-card.scss";

const ImageCard = ({ image, clientStoreName, clientStoreId }) => {

  const { theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  return (
    <div className={`${theme_type === "1"?"image-card-wrapper":"image-card-wrapper-theme2"}`} >
      <Link to={`/weekly-ads/${clientStoreId}/${clientStoreName}`}>
        <img src={image.url} alt=""></img>
      </Link>
    </div>
  );
};

export default ImageCard;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./wishlist.scss";
import SingleWishlistTheme2 from "./SingleWishlistTheme2";
import { getCustomerWishlist } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

const MywishlistTheme2 = () => {
  const dispatch = useDispatch();

  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const customerWishLists = useSelector(
    ({ customer }) => customer.wishlistData
  );

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      PageNo: 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getCustomerWishlist(body));
  }, [dispatch, clientId, memberNumber, userToken, storeId, isUserLoggedIn]);


  return (
    <div className="wishlist-section-theme2">
      <div className="wishlist-page-title">
        <h1>Wishlist</h1>

        {/* <button onClick={handleAddNewAddress}>Add</button> */}
      </div>

      {customerWishLists && customerWishLists.length > 0 ? (
        <div className="wishlist-table-wrapper">
          <div className="scrollable">
            <table className="responsive">
              <tbody>
                <tr>
                  <th align="left">Image</th>
                  <th align="left">Product Name</th>
                  <th align="left">Action</th>
                </tr>

                {customerWishLists.map((wishlist, index) => {
                  return (
                    <SingleWishlistTheme2 wishlist={wishlist} key={index} />
                  );

                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        " No Wishlists yet!"
      )}
    </div>
  );
};
export default MywishlistTheme2;

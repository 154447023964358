// actions created for b2b form [24-06-2024] by kiran konda
import {
  B2B_FORM,
  B2B_FORM_SUCCESS,
  B2B_FORM_FAILURE
} from "redux/constants/actionTypes";

import axios from "config/axios";

export const postb2bFormDetails = (payload) => {
  return {
    type: B2B_FORM,
    payload: payload
  };
};
export const postb2bFormDetailsSuccess = (payload) => {
  return {
    type: B2B_FORM_SUCCESS,
    payload: payload
  };
};
export const postb2bFormDetailsFailure = (payload) => {
  return {
    type: B2B_FORM_FAILURE,
    payload: payload
  };
};

export const b2bDetails = (body) => async (dispatch) => {
  dispatch(postb2bFormDetails());
  axios
    .post("/AogB2BForm", body)
    .then((res) => {
      dispatch(postb2bFormDetailsSuccess(res?.data));
    })
    .catch((error) => {
      dispatch(postb2bFormDetailsFailure({ error: error?.data?.message }));
    });
};

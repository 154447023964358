import {
  FETCH_REWARD_DATA,
  FETCH_REWARD_DATA_SUCCESS,
  FETCH_REWARD_DATA_FAILURE,
  FETCH_REWARD_V2_DATA,
  FETCH_REWARD_V2_DATA_SUCCESS,
  FETCH_REWARD_V2_DATA_FAILURE,
  FETCH_UPC_TIER_PRODUCTS_DATA,
  FETCH_UPC_TIER_PRODUCTS_DATA_SUCCESS,
  FETCH_UPC_TIER_PRODUCTS_DATA_FAILURE,
  FETCH_QUALIFIED_REWARD_TIERS_V2_DATA,
  FETCH_QUALIFIED_REWARD_TIERS_V2_DATA_SUCCESS,
  FETCH_QUALIFIED_REWARD_TIERS_V2_DATA_FAILURE,
  FETCH_POINTS_BASED_REWARD_DATA,
  FETCH_POINTS_BASED_REWARD_DATA_SUCCESS,
  FETCH_POINTS_BASED_REWARD_DATA_FAILURE,
  FETCH_REDEEM_LIST,
  FETCH_REDEEM_LIST_SUCCESS,
  FETCH_REDEEM_LIST_FAILURE,
  SET_REDEEM_OPTION,
  SET_REDEEM_OPTION_SUCCESS,
  SET_REDEEM_OPTION_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";
import InformationSection from "routes/about-us/InformationSection";

// fetch about data
export const fetchRewardDetails = (payload) => {
  return {
    type: FETCH_REWARD_DATA,
    payload: payload,
  };
};
export const fetchRewardDetailsSuccess = (payload) => {
  return {
    type: FETCH_REWARD_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchRewardDetailsFailure = (payload) => {
  return {
    type: FETCH_REWARD_DATA_FAILURE,
    payload: payload,
  };
};

export const getRewardDetails = (body) => async (dispatch) => {
  dispatch(fetchRewardDetails());

  axios
    .post("/AogGetRewards", body)
    .then((res) => {
      dispatch(fetchRewardDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchRewardDetailsFailure({ error: error.data.message }));
    });
};

export const fetchRedeemList = (payload) => {
  return {
    type: FETCH_REDEEM_LIST,
    payload: payload,
  };
};
export const fetchRedeemListSuccess = (payload) => {
  return {
    type: FETCH_REDEEM_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchRedeemListFailure = (payload) => {
  return {
    type: FETCH_REDEEM_LIST_FAILURE,
    payload: payload,
  };
};

export const getRedeemListDetails = (body) => async (dispatch) => {
  dispatch(fetchRedeemList());
  axios
    .post("/AogGetRedeemList", body)
    .then((res) => {
      dispatch(fetchRedeemListSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchRedeemListFailure({ error: error.data.message }));
    });
};

export const setRedeemOption = (payload) => {
  return {
    type: SET_REDEEM_OPTION,
    payload: payload,
  };
};
export const setRedeemOptionSuccess = (payload) => {
  return {
    type: SET_REDEEM_OPTION_SUCCESS,
    payload: payload,
  };
};
export const setRedeemOptionFailure = (payload) => {
  return {
    type: SET_REDEEM_OPTION_FAILURE,
    payload: payload,
  };
};

export const setSelectedRedeemOption = (body) => async (dispatch) => {
  dispatch(setRedeemOption());
  axios
    .post("/AogGetRedeemApply", body)
    .then((res) => {
      dispatch(setRedeemOptionSuccess(res.data));
    })
    .catch((error) => {
      dispatch(setRedeemOptionFailure({ error: error.data.message }));
    });
};

// fetching RewardV2 Details

export const fetchGetRewardV2Details = (payload) => {
  return {
    type: FETCH_REWARD_V2_DATA,
    payload: payload,
  };
};
export const fetchGetRewardV2DetailsSuccess = (payload) => {
  return {
    type: FETCH_REWARD_V2_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchGetRewardV2DetailsFailure = (payload) => {
  return {
    type: FETCH_REWARD_V2_DATA_FAILURE,
    payload: payload,
  };
};

export const getRewardV2Details = (body) => async (dispatch) => {
  dispatch(fetchGetRewardV2Details());

  axios
    .post("/AogGetRewardsV2", body)
    .then((res) => {
      dispatch(fetchGetRewardV2DetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchGetRewardV2DetailsFailure({ error: error.data.message }));
    });
};

// PointBasedrewardDetails information

export const fetchGetPointsBasedRewardDetails = (payload) => {
  return {
    type: FETCH_POINTS_BASED_REWARD_DATA,
    payload: payload,
  };
};
export const fetchGetPointsBasedRewardDetailsSuccess = (payload) => {
  return {
    type: FETCH_POINTS_BASED_REWARD_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchGetPointsBasedRewardDetailsFailure = (payload) => {
  return {
    type: FETCH_POINTS_BASED_REWARD_DATA_FAILURE,
    payload: payload,
  };
};

export const getPointsBasedRewardDetails = (body) => async (dispatch) => {
  dispatch(fetchGetPointsBasedRewardDetails());

  axios
    .post("/AogGetPointsBasedRewardDetails", body)
    .then((res) => {
      dispatch(fetchGetPointsBasedRewardDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchGetPointsBasedRewardDetailsFailure({ error: error.data.message })
      );
    });
};

// GetUPCTierProducts Information

export const fetchGetUPCTierProductsDetails = (payload) => {
  return {
    type: FETCH_UPC_TIER_PRODUCTS_DATA,
    payload: payload,
  };
};
export const fetchGetUPCTierProductsDetailsSuccess = (payload) => {
  return {
    type: FETCH_UPC_TIER_PRODUCTS_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchGetUPCTierProductsDetailsFailure = (payload) => {
  return {
    type: FETCH_UPC_TIER_PRODUCTS_DATA_FAILURE,
    payload: payload,
  };
};

export const getGetUPCTierProductsDetails = (body) => async (dispatch) => {
  dispatch(fetchGetUPCTierProductsDetails());

  axios
    .post("/AogGetUPCTierProducts", body)
    .then((res) => {
      dispatch(fetchGetUPCTierProductsDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchGetUPCTierProductsDetailsFailure({ error: error.data.message })
      );
    });
};

// AogGetQualifiedRewardTiersV2

export const fetchGetQualifiedRewardTiersV2Details = (payload) => {
  return {
    type: FETCH_QUALIFIED_REWARD_TIERS_V2_DATA,
    payload: payload,
  };
};
export const fetchGetQualifiedRewardTiersV2DetailsSuccess = (payload) => {
  return {
    type: FETCH_QUALIFIED_REWARD_TIERS_V2_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchGetQualifiedRewardTiersV2DetailsFailure = (payload) => {
  return {
    type: FETCH_QUALIFIED_REWARD_TIERS_V2_DATA_FAILURE,
    payload: payload,
  };
};

export const getGetQualifiedRewardTiersV2Details =
  (body) => async (dispatch) => {
    dispatch(fetchGetQualifiedRewardTiersV2Details());

    axios
      .post("/AogGetQualifiedRewardTiersV2", body)
      .then((res) => {
        dispatch(fetchGetQualifiedRewardTiersV2DetailsSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(
          fetchGetQualifiedRewardTiersV2DetailsFailure({
            error: error.data.message,
          })
        );
      });
  };

import React from "react";
import { useSelector } from "react-redux";
import favLogo from "assets/logo/fav_v3.png";
import favLogo104 from "assets/images/Favorites_104.png";
import { getLocalStorageValue } from "config/helper";
const WishList = () => {
  const clientId = getLocalStorageValue("RSAclient-id");
  const cartWishlistDetails = useSelector(({ cartWishlist }) => cartWishlist);
  const allow_ecommerce = useSelector(({ common }) => common.commonDetailsData.ecom_section.allow_ecommerce);
  const favoriteListImageLogo = useSelector(({ common }) => common.commonDetailsData.header_section.favorite_list_image);
  return (
    <>
      {cartWishlistDetails && allow_ecommerce == "1" && (
        <div className="wish-list info-wrapper" >
          <div className="add-to-list-icon">
            {/* <img

                  src={clientId === "104" ? favLogo104 : favoriteListImageLogo}
                  alt="Favotites"
                  style={{ height: "24px", width: "30px" }}
                /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="36px" viewBox="0 0 24 24"><path fill="currentColor" d="M2 19.004h2.004V17H2zM7 19h15v-2H7zm-5-5.996h2.004V11H2zM7 13h15v-2H7zM2 7.004h2.004V5H2zM7 7h15V5H7z" /></svg>
            {cartWishlistDetails.wishlistItemsCount !== "0" ? (
              <div>
                <span>{cartWishlistDetails.wishlistItemsCount} </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            <p>Lists</p>
          </div>
        </div>
      )}
    </>
  );
};

export default WishList;

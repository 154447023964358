import React from "react";
import ProductListSliderTheme2 from "routes/home/ProductListSliderTheme2";
import "./related-product-theme2.scss";

const RelatedProductTheme2 = ({ relatedProductList }) => {
  return (
    <div>
      <div className="related-product-section-theme2">
        <div className="container">
          <h1>Related products</h1>
        </div>

        <ProductListSliderTheme2 products={relatedProductList} />
      </div>
    </div>
  );
};
export default RelatedProductTheme2;

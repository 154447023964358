import React, { useState } from 'react';
import weeklyadData from "./Data/WeeklyadData";
import bgImage from "../../assets/images/weekly-ad/bg-image.png";
import "./weeky-ad.scss";
import SideProAd from "./SideProAd";
import SaleProAd from "./SaleProAd";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";

function WeekyAdBannar() {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };
    const handleAddToList = () => {
        setShowModal(true);
    };
    const closeModal = () => {
        setShowModal(false);
    };
    const [isMobileMenuActive, setMobileMenuActive] = useState(false);
    const toggleMenu = () => {
        setMobileMenuActive(!isMobileMenuActive);
    };
    const closeMenu = () => {
        setMobileMenuActive(false);
    };
    const breadcrumbList = [{ lable: "Weekly Ad Bannar", link: "", searchTag: "" }];
    return (
        <>
            <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Weekly Ad Bannar" />
            <div className="container">
                <div className="filter" onClick={toggleMenu}>
                    <span> <i className="fas fa-bars"></i>weekly-ad product</span>
                </div>
                <div className="weekly-ad-items">
                    <div className="product-items">
                        <div className="bg" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}>
                            <div className="weeky-ad-section-wrapper" >
                                <div className="weekly-items-main">
                                    {weeklyadData.map(item => (
                                        <button onClick={() => handleProductClick(item)} key={item.id}>
                                            <div className="items">
                                                <div className="ad-image">
                                                    <img src={item.proimage} alt="product image" className="img-fluid" />
                                                </div>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                                <SideProAd onProductClick={handleProductClick} />
                            </div>
                            <SaleProAd onProductClick={handleProductClick} />
                        </div>
                    </div>
                    <div className={`detail-menu-overlay ${isMobileMenuActive ? 'active' : ''}`} onClick={toggleMenu}></div>
                    <div className={`detail-items ${isMobileMenuActive ? 'active' : ''}`}>
                        <div className="close-menu" onClick={closeMenu}><i className="fas fa-times"></i></div>
                        {/* <div className=""> */}
                        {selectedProduct ? (
                            <div className="selected-product">
                                <div className="ad-image">
                                    <img src={selectedProduct.proimage} alt="product image" className="img-fluid" />
                                </div>
                                <div className="desc">
                                    <div className="title">{selectedProduct.title}</div>
                                    <div className="price">{selectedProduct.price}</div>
                                </div>
                                <div className="add-list-btn-wrap">
                                    <button className="add-list" onClick={handleAddToList}>Add to list</button>
                                </div>
                            </div>
                        ) : (
                            <div>Select a product to see details</div>
                        )}
                    </div>
                </div>
                {showModal && (
                    <div className="alert-container">
                        <div className="alert-header">
                            <button type="button" className="close" onClick={closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 className="alert-title h6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 2048 2048"><path fill="currentColor" d="M1837 557L768 1627l-557-558l90-90l467 466l979-978z" /></svg>Product successfully added to your List..
                            </h4>
                            <div className="alert-body">
                                {/* Display product name or any other information here */}
                                {selectedProduct && (
                                    <div>
                                        <p>{selectedProduct.title} has been added to your List.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default WeekyAdBannar;


import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./multibanner.scss";

const MultiBanner = () => {
  const homePageBannerSection = useSelector(
    ({ homepage }) => homepage.bannerAndTestimonialsData.home_page_sections_list
  );

  const [threeBannerSectionSlug, seThreeBannerSectionSlug] = useState(
    "home-page-ad-three-for-banners-section"
  );

  const threeBannerList =
    homePageBannerSection?.find(
      (section) => section.section_slug === threeBannerSectionSlug
    )?.child_section_list || [];

  useEffect(() => {
    homePageBannerSection?.length &&
      seThreeBannerSectionSlug(threeBannerSectionSlug);
  }, [homePageBannerSection]);

  return (
    <>
      {threeBannerList && threeBannerList.length > 0 && (
        <div className="multi-banner">
          <div className="container">
            <div className="multi-img-wrap">
              {threeBannerList
                ? threeBannerList.map((threeBanner) => {
                    return (
                      threeBanner?.image && (
                        <div className="multi-banner-item">
                          <div className="multi-img">
                            {threeBanner?.target_url_for_image_clickable ? (
                              <a
                                href={
                                  threeBanner.target_url_for_image_clickable
                                }
                                style={{
                                  pointerEvents:
                                    threeBanner.target_url_for_image_clickable !==
                                    ""
                                      ? ""
                                      : "none",
                                }}
                              >
                                <img
                                  src={threeBanner.image}
                                  alt="multi banner_image"
                                  className="img-fluid"
                                />
                              </a>
                            ) : (
                              <img
                                src={threeBanner.image}
                                alt="multi banner_image"
                                className="img-fluid"
                              />
                            )}
                          </div>
                        </div>
                      )
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MultiBanner;

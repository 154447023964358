import React from "react";
import ReactHtmlParser from "components/Common/ReactHtmlParser";
import "./add-space-section.scss";

const AdsSpaceSectionTheme2 = ({ adsDetails, department }) => {
  return (
    <>
      {adsDetails && adsDetails.image && adsDetails.description ? (
        <div>
          <div className="ads-space-section">
            <div className="add-grid">
              <div className="add-grid-description">
                <h2>{department}</h2>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AdsSpaceSectionTheme2;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";

import "styles/main.scss";
import Maintenance from "components/Maintanence";
import { getLocalStorageValue } from "config/helper";
const maintanenceError = getLocalStorageValue("maintanenceError");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {maintanenceError == "502" ? <Maintenance /> : <Layout />}
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

import React, { useState, useEffect } from "react";
// import ProductImage from "../../assets/images/category-1.png";
// import ProductImage2 from "../../assets/images/category-2.png";
// import ProductImage3 from "../../assets/images/category-3.png";
// import ProductImage4 from "../../assets/images/category-4.png";
import "./focus-department.scss";
import { useSelector } from "react-redux";
import loader from "assets/images/loader.gif";

const FocusDepartment = () => {
  const ecom_section = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section || {}
  );
  const homePageBannerSection = useSelector(
    ({ homepage }) => homepage.bannerAndTestimonialsData.home_page_sections_list
  );

  const { bannerAndTestimonialsDataLoading } = useSelector(
    ({ homepage }) => homepage
  );
  const [fourBannerSectionSlug, setFourBannerSectionSlug] = useState(
    "home-page-ad-four-for-banners-section"
  );

  const fourBannerList =
    homePageBannerSection?.find(
      (section) => section.section_slug === fourBannerSectionSlug
    )?.child_section_list || [];

  useEffect(() => {
    homePageBannerSection?.length &&
      setFourBannerSectionSlug(fourBannerSectionSlug);
  }, [homePageBannerSection]);

  return (
    <>
      {bannerAndTestimonialsDataLoading ? (
        <img
          style={{ margin: "auto", width: "100px", height: "50px" }}
          src={loader}
          alt=""
        />
      ) : (
        fourBannerList &&
        fourBannerList.length > 0 && (
          <div className="focus-department">
            <div className="container">
              <div className="focus-department-items">
                {fourBannerList?.map((fourBanner) => (
                  <div className="focus-department-inner">
                    <a
                      href={
                        ecom_section?.allow_ecommerce === "1"
                          ? fourBanner?.target_url_for_image_clickable
                          : fourBanner?.nonecom_target_url_for_image_clickable
                      }
                      className={`focus-dep-img ${
                        ecom_section?.allow_ecommerce === "1"
                          ? fourBanner?.target_url_for_image_clickable == ""
                          : fourBanner?.nonecom_target_url_for_image_clickable ==
                            ""
                          ? "disabled-anchor"
                          : ""
                      }`}
                    >
                      <img src={fourBanner?.image} alt="product_image" />
                    </a>
                    {/* <div className="focus-department-details">
                    <div className="item-title">vegetable and fruits</div>
                  </div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};
export default FocusDepartment;
